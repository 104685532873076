import { graphql } from "../gql";

export const OrgGroupsQuery = graphql(`
  query OrgGroups($org_id: uuid!) {
    user_group(where: { org_id: { _eq: $org_id } }) {
      name
      user_group_members {
        account_id
      }
    }
  }
`);

export const UserGroupByAccountQuery = graphql(`
  query UserGroupByAccountQuery($account_id: uuid!) {
    user_group_member(where: { account_id: { _eq: $account_id } }) {
      user_group_id
      user_group {
        org_id
      }
    }
  }
`);

export const UserGroupCampaignQuery = graphql(`
  query UserGroupCampaignById($org_id: uuid, $account_id: uuid) {
    # Fetch campaigns the user is allowed to view based on user group.
    campaign(
      where: {
        user_group_campaigns: {
          user_group: {
            org_id: { _eq: $org_id }
            user_group_members: { account_id: { _eq: $account_id } }
          }
        }
      }
      order_by: { created_at: desc }
    ) {
      id
      title
      user_id
      created_at
      archived_at
      status
      image
      description
      start_date
      end_date
      organization_id
      searches {
        created_at
        video_duration
        channel: metadata(path: "$.channel")
        user_name: metadata(path: "$.user_name")
        language: metadata(path: "$.language")
      }
    }
  }
`);
