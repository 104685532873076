import {
  Tr,
  Td,
  Text,
  Badge,
  Tooltip,
  HStack,
  IconButton,
  Code,
  CircularProgress,
  Box,
  Tag,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { mentionDisplaySettings, RivrMention } from "../../../../models/mention";
import { formatDateForDisplay, secondsToHMSDuration } from "../../../../utils/time";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../../../models/navigation";
import { NewEfferent } from "@icon-park/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  mention: RivrMention;
  miniPlayer: boolean;
  isSelected: boolean;
  setSelectedMention: React.Dispatch<React.SetStateAction<RivrMention | null>>;
}

export const DiscoverMentionItem = ({
  mention,
  miniPlayer,
  isSelected,
  setSelectedMention,
}: Props) => {
  const navigate = useNavigate();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const { getBooleanFlag } = useKindeAuth();
  const mentionsSentimentEnabled = getBooleanFlag("mentions-sentiment", false);

  const [searchParams] = useSearchParams();

  const viewLink = `${RivrLocations.Results}/${mention.searchId}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedTimestamp, String(Math.floor(mention.timestamp))],
      [ResultsURLParams.SelectedPanel, mention.type === "Speech" ? "speech" : "chat"],
      [ResultsURLParams.SelectedMoment, ""],
    ]
  )}`;

  const handleNavigate = () => {
    const cleanText = mention.text.replace(/<\/?b>/g, "");

    if (miniPlayer) {
      setSelectedMention(mention);
      handleAmplitudeTrack("Previewed Mention", {
        SearchId: mention.searchId,
        MentionType: mention.type,
        MentionText: cleanText,
        MentionChannel: mention.channel,
      });
    } else {
      navigate(viewLink);
      handleAmplitudeTrack("Navigated to Mention in Results", {
        SearchId: mention.searchId,
        MentionType: mention.type,
        MentionText: cleanText,
        MentionChannel: mention.channel,
      });
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    e.preventDefault();
    handleNavigate();
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const featureTag = (label: string, index: number) => {
    const rankings: Record<string, number> = mention.sentiment
      ? {
          "ASR positive":
            mention.type === "Speech" && mention.sentiment >= 0 ? mention.sentiment : 0,
          "ASR negative":
            mention.type === "Speech" && mention.sentiment < 0 ? -mention.sentiment : 0,

          "Chat positive":
            mention.type === "Chat" && mention.sentiment >= 0 ? mention.sentiment : 0,
          "Chat negative":
            mention.type === "Chat" && mention.sentiment < 0 ? -mention.sentiment : 0,
        }
      : {};

    return (
      <Tooltip key={index} label={(mentionDisplaySettings as any)[label].title}>
        <Tag
          colorScheme={(mentionDisplaySettings as any)[label].colorScheme}
          variant={"subtle"}
          size={"xl"}
          py={0.5}
          borderRadius={"sm"}
          minW={"24px"}
          justifyContent={"center"}
          opacity={
            rankings[label] >= (mentionDisplaySettings as any)[label].featureThreshold ? 1 : 0.2
          }
          className={
            (mentionDisplaySettings as any)[label].hyperThreshold &&
            rankings[label] >= (mentionDisplaySettings as any)[label].hyperThreshold
              ? "hyper-hype"
              : ""
          }
        >
          {(mentionDisplaySettings as any)[label].icon}
        </Tag>
      </Tooltip>
    );
  };

  const features =
    // Maps to mentionDisplaySettings in models/mention.tsx
    mention.type === "Speech"
      ? ["ASR positive", "ASR negative"]
      : ["Chat positive", "Chat negative"];

  return (
    <Tr
      _hover={{ bg: "whiteAlpha.100", cursor: "pointer" }}
      sx={{
        "&:hover .actions": {
          opacity: 1,
        },
      }}
      display={"table-row"}
      onClick={handleNavigate}
      bg={isSelected ? "whiteAlpha.100" : undefined}
    >
      <Td verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CircularProgress
            className={"circular-progress"}
            min={0}
            max={1}
            value={mention.rank}
            color={"green.200"}
            trackColor={"blackAlpha.500"}
            thickness={16}
            size={6}
          />
        </Box>
      </Td>
      {(isAdmin || mentionsSentimentEnabled) && (
        <Td px={2}>
          <Box
            as={Link}
            to={viewLink}
            onClick={(e: any) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <HStack w={"100%"} spacing={0.5}>
              {features.map((feature, index) => featureTag(feature, index))}
            </HStack>
          </Box>
        </Td>
      )}
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{mention.channel}</Text>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Badge
            colorScheme={mention.type === "Speech" ? "orange" : "purple"}
            letterSpacing={"wider"}
            textTransform={"capitalize"}
            fontWeight={"normal"}
            textAlign={"center"}
            w={"3.5rem"}
          >
            {mention.type}
          </Badge>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Code
            minW={96}
            px={2}
            py={1}
            borderRadius={"md"}
            sx={{
              textWrap: "wrap",
              lineHeight: "1.2rem",
              w: "100%",
              bg: "blackAlpha.700",
              pos: "relative",
              "& b": {
                fontWeight: "bold",
                textColor: "whiteAlpha.800",
                backgroundColor: "rgba(113, 128, 150, 0.6)",
                padding: "0 2px",
                borderRadius: "sm",
              },
            }}
            className={"mention-line"}
          >
            <Text as="span" dangerouslySetInnerHTML={{ __html: mention.text }} />
          </Code>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{formatDateForDisplay(new Date(mention.date))}</Text>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{secondsToHMSDuration(mention.timestamp)}</Text>
        </Box>
      </Td>
      <Td maxW={64} px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={mention.vod}>
            {mention.vod}
          </Text>
        </Box>
      </Td>
      <Td maxW={"fit-content"} isNumeric px={2} verticalAlign={"middle"}>
        <HStack justify={"flex-end"} spacing={1} className="actions" opacity={0}>
          <Tooltip label={"View"}>
            <Box
              as={Link}
              to={viewLink}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <IconButton
                size={"xs"}
                variant={"ghost"}
                aria-label={"View Mention"}
                icon={<NewEfferent size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!miniPlayer) navigate(viewLink);
                }}
              />
            </Box>
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
};
