import {
  Button,
  Input,
  useToast,
  VStack,
  Box,
  HStack,
  Text,
  Flex,
  Image,
  InputGroup,
  InputRightElement,
  Tooltip,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { DeleteFive, UploadPicture } from "@icon-park/react";
import { useMemo, useState } from "react";
import { hexDump, hexToBase64 } from "../../../utils/image";
import ImgNotFound from "../../../assets/image-not-found.png";
import { OrganizationMembership } from "../../../models/rivrOrganization";
import { CopyIcon } from "@chakra-ui/icons";

type OrgEditProps = {
  selectedMembership: OrganizationMembership;
  name: string;
  setName: (name: string) => void;
  image: string;
  setImage: (image: string) => void;
  website: string;
  setWebsite: (website: string) => void;
  email: string;
  setEmail: (email: string) => void;
};

const OrgEdit = ({
  selectedMembership,
  name,
  setName,
  image,
  setImage,
  website,
  setWebsite,
  email,
  setEmail,
}: OrgEditProps) => {
  const toast = useToast();

  const supportId = selectedMembership.organization.id;
  const mailtoLink = `mailto:support@rivr.stream?subject=Requesting%20Organization%20Removal&body=${supportId} (please do not remove this ID from this request email)`;

  const [tooltipLabel, setTooltipLabel] = useState("Copy");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(supportId);
    setTooltipLabel("Copied!");
    setTimeout(() => setTooltipLabel("Copy"), 800);
  };

  const handleImageSelect = async (files: FileList | null) => {
    if (!files || files.length !== 1) return; // Return if file selection was cancelled or multiple files were somehow passed

    const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/webp"];
    const MAX_FILE_SIZE = 2097152;

    if (ALLOWED_FILE_TYPES.includes(files[0].type) === false) {
      toast({
        title: "Unsupported file type",
        description: "Image must be in jpg, png, or webp formats.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const imageFile = files[0];
    if (imageFile.size > MAX_FILE_SIZE) {
      // Check if image is too large
      toast({
        title: "File size too large",
        description: "Image must less than 2MB in size.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Convert image to hex string for database storage
    await hexDump(imageFile).then((img) => {
      setImage(`\\x${img}`);
    });
  };

  const imagePreview = useMemo(() => {
    return image !== null ? `data:image;base64, ${hexToBase64(image)}` : ImgNotFound;
  }, [image]);

  return (
    <VStack w={"100%"} spacing={12}>
      <VStack alignItems={"flex-start"} w={"100%"} spacing={6}>
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={6}
          wrap={"wrap"}
        >
          <VStack alignItems={"flex-start"} spacing={1}>
            <Text fontSize={"lg"}>Image</Text>
            <Text color={"gray.300"} fontSize={"sm"}>
              Upload an image up to 2MB in JPG, PNG, or WEBP formats.
            </Text>
          </VStack>
          <VStack alignItems={"flex-end"} w={"100%"} maxW={"520px"} ml={"0 !important"}>
            <Box
              h={"200px"}
              w={"200px"}
              bg={"gray.600"}
              borderRadius={"md"}
              borderStyle={"dashed"}
              borderWidth={3}
              overflow={"hidden"}
              mb={1}
            >
              <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
                {image ? (
                  <Image
                    boxSize={"100%"}
                    src={imagePreview}
                    fit={"contain"}
                    id={"org-image-preview"}
                  />
                ) : (
                  <VStack spacing={2}>
                    <UploadPicture theme={"filled"} size={32} />
                    <Text textAlign={"center"} fontSize={"xs"}>
                      JPG, PNG or WEBP
                      <br />
                      Up to 2MB
                    </Text>
                  </VStack>
                )}
              </Flex>
            </Box>
            <Input
              ml={"0 !important"}
              maxW={"520px"}
              type={"file"}
              mt={2}
              pl={1}
              pt={1}
              accept={".jpg, .jpeg, .png, .webp"}
              onChange={(e) => handleImageSelect(e.target.files)}
              cursor={"pointer"}
              hidden={selectedMembership.role !== "owner"}
            />
          </VStack>
        </HStack>
      </VStack>

      <VStack alignItems={"flex-start"} w={"100%"} spacing={6}>
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={6}
          wrap={"wrap"}
        >
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="lg">Name</Text>
            <Text color="gray.300" fontSize="sm">
              The name of this organization.
            </Text>
          </VStack>
          <Input
            className={"amp-unmask"}
            ml="0 !important"
            maxW="520px"
            id={"org-name"}
            value={name}
            isDisabled={selectedMembership.role !== "owner"}
            onChange={(e) => setName(e.target.value)}
            isInvalid={name === ""}
          />
        </HStack>
      </VStack>
      <VStack alignItems="flex-start" w="100%" spacing={6}>
        <HStack w="100%" justifyContent="space-between" alignItems="flex-start" gap={6} wrap="wrap">
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="lg">Role</Text>
            <Text color="gray.300" fontSize="sm">
              Your role in this organization.
            </Text>
          </VStack>
          <Input
            className={"amp-unmask"}
            ml="0 !important"
            maxW="520px"
            id={"org-role"}
            value={
              selectedMembership.role.charAt(0).toUpperCase() + selectedMembership.role.slice(1)
            }
            isDisabled
          />
        </HStack>
      </VStack>
      <VStack alignItems="flex-start" w="100%" spacing={6}>
        <HStack w="100%" justifyContent="space-between" alignItems="flex-start" gap={6} wrap="wrap">
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="lg">Website</Text>
            <Text color="gray.300" fontSize="sm">
              The website for this organization.
            </Text>
          </VStack>
          <Input
            className={"amp-unmask"}
            ml="0 !important"
            maxW="520px"
            id={"org-website"}
            value={website || ""}
            isDisabled={selectedMembership.role !== "owner"}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </HStack>
      </VStack>
      <VStack alignItems="flex-start" w="100%" spacing={6}>
        <HStack w="100%" justifyContent="space-between" alignItems="flex-start" gap={6} wrap="wrap">
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="lg">Email</Text>
            <Text color="gray.300" fontSize="sm">
              The preferred contact email for this organization.
            </Text>
          </VStack>
          <Input
            className={"amp-unmask"}
            ml="0 !important"
            maxW="520px"
            id={"org-email"}
            value={email || ""}
            type={"email"}
            isDisabled={selectedMembership.role !== "owner"}
            onChange={(e) => setEmail(e.target.value)}
          />
        </HStack>
      </VStack>
      <VStack alignItems="flex-start" w="100%" spacing={6}>
        <HStack w="100%" justifyContent="space-between" alignItems="flex-start" gap={6} wrap="wrap">
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="lg">Support ID</Text>
            <Text color="gray.300" fontSize="sm">
              If requested, please provide this ID to support agents.
            </Text>
          </VStack>
          <InputGroup ml="0 !important" maxW="520px">
            <Input
              className={"amp-unmask"}
              isDisabled
              id={"org-support-id"}
              value={supportId}
              opacity={"1 !important"}
            />
            <InputRightElement>
              <Tooltip label={tooltipLabel} closeDelay={700}>
                <IconButton
                  aria-label={"Copy to clipboard"}
                  icon={<CopyIcon />}
                  onClick={copyToClipboard}
                  size={"sm"}
                  variant={"ghost"}
                />
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        </HStack>
      </VStack>

      {selectedMembership.role === "owner" && (
        <>
          <Divider />

          <VStack alignItems="flex-start" w="100%" spacing={6}>
            <HStack
              w="100%"
              justifyContent="space-between"
              alignItems="flex-start"
              gap={6}
              wrap="wrap"
            >
              <VStack alignItems="flex-start" spacing={1}>
                <Text fontSize="lg">Delete Organization</Text>
                <Text color="gray.300" fontSize="sm">
                  Requesting the deletion of an organization can take up to 48 hours to complete.
                  Once completed you and your organization members will no longer have access to
                  Rivr.
                </Text>
              </VStack>
              <Button
                colorScheme={"red"}
                variant={"outline"}
                as={"a"}
                href={mailtoLink}
                target={"_blank"}
                ml="0 !important"
                leftIcon={<DeleteFive />}
              >
                Delete organization
              </Button>
            </HStack>
          </VStack>
        </>
      )}
    </VStack>
  );
};

export default OrgEdit;
