import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  Box,
  HStack,
  Text,
  Flex,
  Textarea,
  Image,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { Check, UploadPicture } from "@icon-park/react";
import { useMemo, useRef, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ApolloError, useMutation } from "@apollo/client";
import { InsertCampaignMutation, UpdateCampaignMutation } from "../../../api/campaign";
import { hexDump, hexToBase64 } from "../../../utils/image";
import { offsetDate } from "../../../utils/time";
import { RivrCampaign } from "../../../models/rivrCampaign";
import ImgNotFound from "../../../assets/image-not-found.png";
import * as amplitude from "@amplitude/analytics-browser";
import { logApolloErrorsHandler } from "../../../utils/graphql-error";

type Props = {
  campaign: RivrCampaign | null;
  orgId: string;
  fetchCampaigns: any;
  closeModal: any;
  openCampaignManagerAlert: any;
};

const CampaignManager = (props: Props) => {
  const { campaign, orgId, fetchCampaigns, closeModal, openCampaignManagerAlert } = props;

  const toast = useToast();
  const { user, getBooleanFlag } = useKindeAuth();
  const demoUser = getBooleanFlag("demo-user", false) as boolean;

  const {
    isOpen: isCampaignEditConfirmOpen,
    onOpen: openCampaignEditConfirm,
    onClose: closeCampaignEditConfirm,
  } = useDisclosure();
  const cancelCloseCampaignConfirmRef = useRef(null);

  const [image, setImage] = useState<string | null>(campaign === null ? null : campaign.image);
  const [title, setTitle] = useState<string>(campaign === null ? "" : campaign.title);
  const [description, setDescription] = useState<string>(
    campaign === null || campaign?.description === null ? "" : campaign.description
  );
  const [start, setStart] = useState<Date | null>(campaign === null ? null : campaign.startDate);
  const [end, setEnd] = useState<Date | null>(campaign === null ? null : campaign.endDate);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
  const [showInvalidFields, setShowInvalidFields] = useState<boolean>(false);

  const [campaignInsertAPI] = useMutation(InsertCampaignMutation, {
    onCompleted() {
      fetchCampaigns();
      setIsSubmitButtonLoading(false);
      toast({
        title: "Campaign created",
        description: `${title} was successfully created.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      setIsSubmitButtonLoading(false);
      toast({
        title: "Campaign not created",
        description: "There was an error encountered while creating the campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const [campaignUpdateAPI] = useMutation(UpdateCampaignMutation, {
    onCompleted() {
      fetchCampaigns();
      setIsSubmitButtonLoading(false);
      toast({
        title: "Campaign updated",
        description: `${title} was successfully updated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      setIsSubmitButtonLoading(false);
      toast({
        title: "Error updating campaign",
        description: "There was an error encountered while updating the campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleStartDateSelect = (date: string) => {
    setStart(offsetDate(date));
  };

  const handleEndDateSelect = (date: string) => {
    setEnd(offsetDate(date));
  };

  const checkCampaignForm = (): boolean => {
    let result = true;
    if (title === "") {
      toast({
        title: "Required field missing",
        description: "Campaign must have a title.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      result = false;
    }
    if (start === null) {
      toast({
        title: "Required field missing",
        description: "Campaign must have a start date.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      result = false;
    }
    if (end === null) {
      toast({
        title: "Required field missing",
        description: "Campaign must have an end date.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      result = false;
    }
    if (start && end && start > end) {
      toast({
        title: "Invalid end date",
        description: "The campaign's end date must occur after the start date.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      result = false;
    }
    return result;
  };

  const handleCampaignCreation = () => {
    if (!checkCampaignForm()) {
      setShowInvalidFields(true);
      return;
    }

    setIsSubmitButtonLoading(true);
    if (user !== undefined && user.id !== null) {
      const campaignObj = {
        user_id: user.id,
        title: title,
        status: "active",
        image: image,
        description: description,
        start_date: start,
        end_date: end,
        organization_id: orgId,
      };
      campaignInsertAPI({
        variables: campaignObj,
      });
    }
    closeModal();
    handleAmplitudeTrack("New Campaign Created", {
      Title: title,
      Org: orgId,
    });
  };

  const handleCampaignEdit = () => {
    if (!checkCampaignForm()) {
      setShowInvalidFields(true);
      return;
    }

    setIsSubmitButtonLoading(true);
    const campaignUpdates: { [key: string]: any } = {};
    if (title !== campaign?.title) {
      campaignUpdates["title"] = title;
    }
    if (description !== campaign?.description) {
      campaignUpdates["description"] = description;
    }
    if (image !== campaign?.image) {
      campaignUpdates["image"] = image;
    }
    if (start !== campaign?.startDate) {
      campaignUpdates["start_date"] = start;
    }
    if (end !== campaign?.endDate) {
      campaignUpdates["end_date"] = end;
    }
    if (Object.keys(campaignUpdates).length !== 0) {
      campaignUpdateAPI({ variables: { id: campaign?.id, updates: campaignUpdates } });
    }
    closeModal();
  };

  const handleImageSelect = async (files: any) => {
    if (files.length !== 1) return; // Return if file selection was cancelled or multiple files were somehow passed

    const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/webp"];
    const MAX_FILE_SIZE = 2097152;

    if (ALLOWED_FILE_TYPES.includes(files[0].type) === false) {
      toast({
        title: "Unsupported file type",
        description: "Image must be in jpg, png, or webp formats.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const imageFile = files[0];
    if (imageFile.size > MAX_FILE_SIZE) {
      // Check if image is too large
      toast({
        title: "File size too large",
        description: "Image must less than 2MB in size.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Convert image to hex string for database storage
    await hexDump(imageFile).then((img) => {
      setImage(`\\x${img}`);
    });
  };

  const imagePreview = useMemo(() => {
    return image !== null ? `data:image;base64, ${hexToBase64(image)}` : ImgNotFound;
  }, [image]);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isCampaignEditConfirmOpen}
        leastDestructiveRef={cancelCloseCampaignConfirmRef}
        onClose={closeCampaignEditConfirm}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Confirm campaign changes</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to make changes to the campaign?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                ref={cancelCloseCampaignConfirmRef}
                onClick={closeCampaignEditConfirm}
              >
                Cancel
              </Button>
              <Button
                isLoading={isSubmitButtonLoading}
                colorScheme="green"
                onClick={() => {
                  handleCampaignEdit();
                  closeCampaignEditConfirm();
                  handleAmplitudeTrack("Campaign Info Edited");
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box w={"100%"} className={"campaign-manage"}>
        <HStack mx={"auto"} spacing={8} alignItems={"center"} justify={"center"}>
          <VStack
            className={"campaign-create-info"}
            w={"100%"}
            p={6}
            borderRadius={"md"}
            spacing={4}
            align={"flex-start"}
          >
            <Box w={"50%"} pt={"50%"} pos={"relative"} alignSelf={"center"}>
              <Flex
                pos={"absolute"}
                top={"0"}
                left={"0"}
                right={"0"}
                bottom={"0"}
                bg={"gray.600"}
                borderRadius={"md"}
                borderStyle={"dashed"}
                borderWidth={3}
                cursor={"pointer"}
                transition={"all 0.2s"}
                _hover={!image ? { bg: "gray.900" } : {}}
              >
                <VStack
                  opacity={!image ? 0.6 : 1.0}
                  w={"100%"}
                  h={"100%"}
                  justify={"center"}
                  align={"center"}
                >
                  <Image
                    boxSize={"100%"}
                    src={imagePreview}
                    fit={"contain"}
                    id={"image-preview"}
                    hidden={!image}
                  />
                  {!image && (
                    <>
                      <UploadPicture theme="filled" size={32} />
                      <Text textAlign={"center"} fontSize={"xs"}>
                        JPG, PNG or WEBP
                        <br />
                        Up to 2MB
                      </Text>
                    </>
                  )}
                  <Input
                    type={"file"}
                    height={"100%"}
                    width={"100%"}
                    position={"absolute"}
                    top={"0"}
                    left={"0"}
                    opacity={"0"}
                    accept={".jpg, .jpeg, .png, .webp"}
                    cursor={"pointer"}
                    onChange={(e) => handleImageSelect(e.target.files)}
                    pointerEvents={demoUser ? "none" : undefined}
                  />
                </VStack>
              </Flex>
            </Box>
            <FormControl isRequired flexGrow={1}>
              <FormLabel>Title</FormLabel>
              <Input
                className={"amp-unmask"}
                variant={"filled"}
                size={"sm"}
                borderRadius={"md"}
                placeholder="Name your campaign"
                isRequired
                isInvalid={showInvalidFields && title === ""}
                value={title}
                maxLength={50}
                onChange={(e) => setTitle(e.target.value)}
                isDisabled={demoUser}
              />
            </FormControl>
            <FormControl flexGrow={1}>
              <FormLabel>Description</FormLabel>
              <Textarea
                className={"amp-unmask"}
                variant={"filled"}
                size={"sm"}
                borderRadius={"md"}
                placeholder="Give your campaign a short description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxH={"200px"}
                maxLength={1000} // Make sure to change this value in rivr-spa-api as well for the database's campaign table
                isDisabled={demoUser}
              />
            </FormControl>
            <HStack w={"100%"} align={"center"}>
              <FormControl isRequired flexGrow={1}>
                <FormLabel>Start date</FormLabel>
                <Input
                  className={"amp-unmask"}
                  variant={"filled"}
                  size={"sm"}
                  borderRadius={"md"}
                  isRequired
                  isInvalid={showInvalidFields && start === null}
                  type={"date"}
                  value={start === null ? "" : start.toISOString().split("T")[0]}
                  onChange={(e) => handleStartDateSelect(e.target.value)}
                  isDisabled={demoUser}
                />
              </FormControl>
              <FormControl isRequired flexGrow={1}>
                <FormLabel>End date</FormLabel>
                <Input
                  className={"amp-unmask"}
                  variant={"filled"}
                  size={"sm"}
                  borderRadius={"md"}
                  isRequired
                  isInvalid={showInvalidFields && (end === null || (start ? start > end : false))}
                  type={"date"}
                  value={end === null ? "" : end.toISOString().split("T")[0]}
                  onChange={(e) => handleEndDateSelect(e.target.value)}
                  isDisabled={demoUser}
                />
              </FormControl>
            </HStack>
            <HStack w={"100%"} justifyContent={"flex-end"} pt={4}>
              <Button variant={"ghost"} onClick={openCampaignManagerAlert}>
                Cancel
              </Button>
              <Button
                isLoading={isSubmitButtonLoading}
                colorScheme={"green"}
                onClick={campaign === null ? handleCampaignCreation : openCampaignEditConfirm}
                leftIcon={<Check />}
                isDisabled={demoUser}
              >
                Save campaign
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </Box>
    </>
  );
};

export default CampaignManager;
