import { createContext } from "react";
import { OrganizationMembership } from "../models/rivrOrganization";
import { AccountByKindeIdQuery as AccountType } from "../gql/graphql";

type AccountContextData = {
  account: AccountType["account"][0];
  memberships: OrganizationMembership[];
  groups: Map<string, string>;
  completeTutorial: (tutorial: string) => void;
  accountUpdateAPI: (options: any) => Promise<any>;
  refreshAccount: () => void;
  refreshOrg: () => void;
  accountLoading: boolean;
};

export const AccountContext = createContext<AccountContextData>(
  null as unknown as AccountContextData
);
