import { createContext } from "react";
import { Action } from "../models/action";
import { Moment } from "../models/moment";
import { VerticalClip } from "../components/VerticalFormatModal/VerticalFormatModal";

type DownloadsContextData = {
  downloadDone: number;
  downloadTotal: number;
  downloadProgress: number;
  downloaderRequest: (
    moments: Moment[],
    searchId: string,
    actions?: Action[],
    forVerticalFormat?: boolean
  ) => Promise<void>;
  verticalFormatClips: VerticalClip[];
  setVerticalFormatClips: (clips: VerticalClip[]) => void;
  isMomentDownloading: (momentId: string) => boolean;
};

export const DownloadsContext = createContext<DownloadsContextData>(null as any);
