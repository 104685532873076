import { graphql } from "../gql";

// Queries
export const AuthURLQuery = graphql(`
  query AuthURL($keyCodeRef: String!, $userId: String!) {
    getAuthURL(data: { key_code_ref: $keyCodeRef, user_id: $userId }) {
      url
      session_id
    }
  }
`);

export const AccessTokenQuery = graphql(`
  query AccessToken($sessionId: String!) {
    getAccessToken(data: { session_id: $sessionId }) {
      access_token
      access_token_expiry
    }
  }
`);

export const RevokeConnectedAppTokenQuery = graphql(`
  query RevokeConnectedAppToken($sessionId: String!) {
    revokeConnectedAppToken(data: { session_id: $sessionId }) {
      result
    }
  }
`);
