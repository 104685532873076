import { Heading, Text, Link, List, ListItem, VStack } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <VStack maxW={"1600px"} px={6} py={12} align={"start"} gap={8}>
      <Heading fontSize={"2xl"}>Updated: November 22nd, 2024</Heading>
      <VStack align={"start"}>
        <Heading fontSize={"2xl"}>Privacy Policy</Heading>
        <Text>
          At Rivr, accessible from{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href={"/"}
          >
            https://app.rivr.stream
          </Link>
          , we prioritize the privacy and security of our users. This Privacy Policy outlines the
          types of information we collect, how we use and protect it, and your rights regarding your
          personal data. This policy applies to all users of our service, including customers,
          subscribers, and employees. By using our services, you agree to the terms outlined in this
          Privacy Policy. This Privacy Policy applies only to our online activities and is valid for
          visitors to our website with regards to the information that they shared and/or collect in
          Rivr. This policy is not applicable to any information collected offline or via channels
          other than this website. Consent By using our website, you hereby consent to our Privacy
          Policy and agree to its terms.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Updates to the Privacy Policy</Heading>
        <Text>
          We may update our Privacy Policy from time to time to reflect changes in our practices or
          for other operational, legal, or regulatory reasons. We will notify you of any significant
          changes by posting the new Privacy Policy on our website and updating the "Effective Date"
          at the top of this policy. We encourage you to review this Privacy Policy periodically to
          stay informed about how we are protecting your information.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Information we collect</Heading>
        <Text>
          The personal information that you are asked to provide, and the reasons why you are asked
          to provide it, will be made clear to you at the point we ask you to provide your personal
          information. If you contact us directly, we may receive additional information about you
          such as your name, email address, phone number, the contents of the message and/or
          attachments you may send us, and any other information you may choose to provide. When you
          register for an Account, we may ask for your contact information, including items such as
          name, company name, address, email address, and telephone number.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <VStack align={"start"} gap={4}>
          <VStack align={"start"}>
            <Heading fontSize={"xl"}>Additional Opt-in Options</Heading>
            <Text>
              At Rivr, we prioritize your privacy and strive to ensure that any additional features
              you choose to opt into are handled with the utmost care and respect for your personal
              information. Below are the additional opt-in features we offer, each designed with
              strong privacy safeguards:
            </Text>
          </VStack>
          <VStack align={"start"}>
            <Heading fontSize={"lg"}>Spotlights</Heading>
            <Text>
              When you opt into Spotlights, you grant Rivr the non-exclusive, royalty-free rights to
              use, reproduce, distribute, display, and perform your content across Rivr's social
              media channels and other marketing platforms and materials. We ensure that your
              personal information remains confidential and is not shared in relation to Spotlights.
              Only your content is showcased to help you reach a wider audience, without exposing
              any personal identifiers linked to your account outside of social media username(s).
            </Text>
          </VStack>
          <VStack align={"start"}>
            <Heading fontSize={"lg"}>Promotions</Heading>
            <Text>
              By opting into Promotions, you allow Rivr to present your content and publicly
              available social media profiles to relevant third-party brands interested in
              collaboration. We strictly prohibit the disclosure of your personal information, such
              as email addresses or phone numbers, without your explicit consent. Only your public
              content and profiles are shared, ensuring that your private data remains secure and
              protected.
            </Text>
          </VStack>
          <VStack align={"start"}>
            <Heading fontSize={"lg"}>Feedback</Heading>
            <Text>
              Opting into Feedback means you agree to be contacted by Rivr to participate in
              surveys, interviews, and other feedback collection methods. All information gathered
              through your participation is kept strictly confidential and is not shared with any
              third parties. Your feedback is used solely to enhance and improve Rivr's features and
              services, ensuring your privacy is maintained throughout the process.
            </Text>
          </VStack>
          <VStack align={"start"}>
            <Heading fontSize={"lg"}>Machine Learning</Heading>
            <Text>
              When you opt into Machine Learning, you permit Rivr to utilize your content and
              interaction data to enhance our machine learning algorithms. All data used for this
              purpose is thoroughly sanitized to remove any personally identifiable information
              (PII), ensuring your privacy is maintained. Additionally, Rivr guarantees that no
              aspects of a creator's likeness are used in our machine learning processes. This
              allows us to improve content discovery and recommendation features on the Rivr
              platform without compromising your personal data or image.
            </Text>
          </VStack>
          <VStack align={"start"}>
            <Heading fontSize={"lg"}>Consent for Additional Opt-In Options</Heading>
            <Text>
              By selecting any of the additional opt-in options—Spotlights, Promotions, Feedback, or
              Machine Learning—you explicitly consent to the specific data usage and privacy
              protections as described above. You have the ability to withdraw your consent for any
              of these opt-in options at any time by contacting us or by adjusting your preferences
              within your account settings. Your continued use of these features after any changes
              signifies your acceptance of the updated terms.
            </Text>
          </VStack>
        </VStack>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>How we use your information</Heading>
        <Text>We use the information we collect in various ways, including to:</Text>
        <List>
          <ListItem>- Provide, operate, and maintain our website</ListItem>
          <ListItem>- Improve, personalize, and expand our website</ListItem>
          <ListItem>- Understand and analyze how you use our website</ListItem>
          <ListItem>- Develop new products, services, features, and functionality</ListItem>
          <ListItem>
            - Communicate with you, either directly or through one of our partners, including for
            customer service, to provide you with updates and other information relating to the
            website, and for marketing and promotional purposes
          </ListItem>
          <ListItem>- Send you emails</ListItem>
          <ListItem>- Find and prevent fraud</ListItem>
        </List>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Log Files</Heading>
        <Text>
          Rivr follows a standard procedure of using log files. These files log visitors when they
          visit websites. All hosting companies do this and a part of hosting services' analytics.
          The information collected by log files include internet protocol (IP) addresses, browser
          type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number and screen location of clicks or taps. These are not linked to any
          information that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on the website, and
          gathering demographic information.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Cookies and Web Beacons</Heading>
        <Text>
          Like many other websites, Rivr uses 'cookies'. These cookies are used to store information
          including visitors' preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users' experience by customizing our web
          page content based on visitors' browser type and/or other information.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Web Technologies We Use</Heading>
        <List>
          <ListItem>
            - Google Analytics: We use Google Analytics to collect standard internet log information
            and details of visitor behavior patterns. This tool allows us to better understand how
            visitors interact with our website, such as which pages are visited the most, how long
            visitors spend on each page, and which pages have high bounce rates. This helps us to
            improve the user experience and provide more relevant content.
          </ListItem>
          <ListItem>
            - Amplitude: We also use Amplitude to collect data on how visitors use our website and
            interact with our products. Amplitude allows us to analyze user behavior and identify
            trends in product usage. This helps us to improve our products and provide a better
            experience for our users.
          </ListItem>
        </List>
        <Text>
          It's important to note that these technologies use cookies to collect information about
          website usage. The information collected by these cookies is anonymous and does not
          personally identify individual visitors. The data collected by these tools may be
          transferred to and stored on servers located outside of your country or the country where
          you accessed our website. By using our website, you consent to the processing of data by
          these third-party services in accordance with their respective privacy policies.
        </Text>
        <Text>
          Google Analytics:{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="https://policies.google.com/privacy"
            isExternal
          >
            https://policies.google.com/privacy
          </Link>
        </Text>
        <Text>
          Amplitude:{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="https://amplitude.com/privacy"
            isExternal
          >
            https://amplitude.com/privacy
          </Link>
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Third Party Privacy Policies</Heading>
        <Text>
          Rivr's Privacy Policy does not apply to other advertisers or websites. Thus, we are
          advising you to consult the respective Privacy Policies of these third-party ad servers
          for more detailed information. It may include their practices and instructions about how
          to opt-out of certain options. You can choose to disable cookies through your individual
          browser options. To know more detailed information about cookie management with specific
          web browsers, it can be found at the browsers' respective websites.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>CCPA Privacy Rights (Do Not Sell My Personal Information)</Heading>
        <Text>Under the CCPA, among other rights, California consumers have the right to:</Text>
        <List>
          <ListItem>
            - Request that a business that collects a consumer's personal data disclose the
            categories and specific pieces of personal data that a business has collected about
            consumers.
          </ListItem>
          <ListItem>
            - Request that a business delete any personal data about the consumer that a business
            has collected.
          </ListItem>
          <ListItem>
            - Request that a business that sells a consumer's personal data, not sell the consumer's
            personal data.
          </ListItem>
          <ListItem>- If you make a request, we have one month to respond to you.</ListItem>
          <ListItem>
            - If you would like to exercise any of these rights, please{" "}
            <Link
              color={"purple.200"}
              _hover={{ color: "purple.300", textDecor: "underline" }}
              href="mailto:privacy@rivr.stream"
            >
              contact us
            </Link>
            .
          </ListItem>
        </List>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>GDPR Data Protection Rights</Heading>
        <Text>
          We would like to make sure you are fully aware of all of your data protection rights.
          Every user is entitled to the following:
        </Text>
        <List>
          <ListItem>
            - The right to access – You have the right to request copies of your personal data. We
            may charge you a small fee for this service.
          </ListItem>
          <ListItem>
            - The right to rectification – You have the right to request that we correct any
            information you believe is inaccurate. You also have the right to request that we
            complete the information you believe is incomplete.
          </ListItem>
          <ListItem>
            - The right to erasure – You have the right to request that we erase your personal data,
            under certain conditions.
          </ListItem>
          <ListItem>
            - The right to restrict processing – You have the right to request that we restrict the
            processing of your personal data, under certain conditions.
          </ListItem>
          <ListItem>
            - The right to object to processing – You have the right to object to our processing of
            your personal data, under certain conditions.
          </ListItem>
          <ListItem>
            - The right to data portability – You have the right to request that we transfer the
            data that we have collected to another organization, or directly to you, under certain
            conditions.
          </ListItem>
          <ListItem>- If you make a request, we have one month to respond to you.</ListItem>
          <ListItem>
            - If you would like to exercise any of these rights, please{" "}
            <Link
              color={"purple.200"}
              _hover={{ color: "purple.300", textDecor: "underline" }}
              href="mailto:gdpr@rivr.stream"
            >
              contact us
            </Link>
            .
          </ListItem>
        </List>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Children's Privacy</Heading>
        <Text>
          Protecting the privacy of children is a priority for us. Rivr does not knowingly collect
          any Personal Identifiable Information (PII) from children under the age of 13. If we
          become aware that a child under 13 has provided us with personal data, we will take
          immediate steps to delete such information from our records. Parental Consent If you
          believe that your child has inadvertently provided personal data to us, please contact us
          immediately at{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="mailto:privacy@rivr.stream"
          >
            privacy@rivr.stream
          </Link>
          , and we will promptly remove the information.
        </Text>
        <Text>
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="mailto:gdpr@rivr.stream"
          >
            GDPR Contact
          </Link>
        </Text>
        <Text>
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="mailto:privacy@rivr.stream"
          >
            Privacy Contact
          </Link>
        </Text>
      </VStack>
    </VStack>
  );
};

export default Privacy;
