import { useState, useEffect, useRef, useContext } from "react";
import {
  Flex,
  IconButton,
  Tooltip,
  VStack,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MomentPanel } from "../components/MomentPanel/MomentPanel";
import { SearchByIDQuery, StopSearchMutation } from "../api/search";
import { UpsertMomentMutation, UpdateMomentMutation, DeleteMomentsMutation } from "../api/moment";
import { MediaPanel } from "../components/MediaPanel/MediaPanel";
import {
  Moment,
  RecommendedMoment,
  SerializableMoment,
  momentFromJSON,
  momentFromRecommendedMoment,
  recommendedMomentFromEvent,
} from "../models/moment";
import "./results.css";
import {
  RecommendedMomentsBySearchIDSubscription,
  ToggleMomentMutation,
} from "../api/recommended-moment";
import { SelectedMoment } from "../models/selectedMoment";
import { useTrigger } from "../hooks/useTrigger";
import { ChatPanel } from "../components/ChatPanel/ChatPanel";
import { SignalsBySearchIDSubscription } from "../api/signal";
import {
  signals,
  signalMap,
  annotations as signalAnnotations,
  signalReplacements,
} from "../models/signals";
import { secondsToDate, durationToSeconds } from "../utils/time";
import { getPeakInInterval, simplify } from "../utils/signal";
import { dedupeMoments, recalculateAnnotationsAndLevel } from "../utils/moment";
import { ResultsURLParams, RivrLocations } from "../models/navigation";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import { AccountContext } from "../context/AccountContext";

type Signal = {
  from_seconds: number;
  x_values: string;
  y_values: string;
  title: string;
};

const Results = () => {
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const minRecommendedMoments = 10;
  const defaultMomentDuration = 60;

  const { memberships } = useContext(AccountContext);
  const isOrgMember = memberships.length > 0;

  const { videoID } = useParams() as { videoID: string };

  // const [errorSearchMutation] = useMutation(ErrorSearchMutation);
  const [isChatError, setIsChatError] = useState(false);
  const [isSpeechError, setIsSpeechError] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [searchParams] = useSearchParams();
  const momentId = searchParams.get(ResultsURLParams.SelectedMoment);
  const mentionTimestamp = searchParams.get(ResultsURLParams.SelectedTimestamp);
  const requiredFeatures = searchParams.getAll(ResultsURLParams.ResultsFeatures) || [];

  const toast = useToast();
  const [videoInfo, setVideoInfo] = useState<any>(null);
  const [moments, setMoments] = useState<Moment[]>([]);
  const activeMoments = moments.filter((moment) => !moment.deleted);
  const [recommendedMoments, setRecommendedMoments] = useState<RecommendedMoment[]>([]);
  const [recommendedAmount, setRecommendedAmount] = useState(0);
  const [signalData, setSignalData] = useState<Map<string, { x: Date[]; y: number[] }>>(new Map());
  const [isLoopingLoading, setIsLoopingLoading] = useState(false);
  const [focusedItem, setFocusedItem] = useState<SelectedMoment | null>(null);
  const [timelineZoom, setTimelineZoom] = useState<{ start: number; end: number } | null>(null);
  const lastAcceptedMoment = useRef<string | null>(null);

  const annotatedRecommendedMoments = recalculateAnnotationsAndLevel(
    recommendedMoments,
    requiredFeatures,
    signalData
  );
  const filteredRecommendedMoments = annotatedRecommendedMoments
    .filter((moment) => !moment.accepted && !moment.modified)
    .sort(
      (a: RecommendedMoment, b: RecommendedMoment) =>
        b.level - a.level || a.start_time - b.start_time
    )
    .slice(
      0,
      minRecommendedMoments +
        recommendedAmount * (recommendedMoments.length - minRecommendedMoments)
    );
  const modifiedRecommendedMoments = annotatedRecommendedMoments
    .filter((moment) => !moment.accepted && moment.modified)
    .sort(
      (a: RecommendedMoment, b: RecommendedMoment) =>
        b.level - a.level || a.start_time - b.start_time
    );

  const selectedMomentIndex =
    focusedItem === null
      ? -1
      : focusedItem.kind === "user"
      ? activeMoments.findIndex((item) => item.id === focusedItem.id)
      : recommendedMoments.findIndex((item) => item.id === focusedItem.id);
  const selectedMoment =
    selectedMomentIndex === -1
      ? null
      : focusedItem?.kind === "user"
      ? activeMoments[selectedMomentIndex]
      : recommendedMoments[selectedMomentIndex];
  const showSelectedMoment =
    focusedItem?.kind === "recommended" &&
    selectedMoment &&
    !filteredRecommendedMoments.map((m) => m.id).includes(selectedMoment.id) &&
    !modifiedRecommendedMoments.map((m) => m.id).includes(selectedMoment.id);

  const localIDs = useRef<string[]>([]);
  const [upsertMomentMutation] = useMutation(UpsertMomentMutation, {
    onCompleted(data) {
      if (data.insert_moment_one) {
        const insertedId = data.insert_moment_one.id;
        localIDs.current = localIDs.current.filter((id) => id !== insertedId);
      }
    },
  });
  const [updateMomentMutation] = useMutation(UpdateMomentMutation);
  const [deleteMomentsMutation] = useMutation(DeleteMomentsMutation);
  const [toggleMomentMutation] = useMutation(ToggleMomentMutation);
  const navigate = useNavigate();
  const [momentTabIndex, setMomentTabIndex] = useState(0);
  const [pausePlayer, setPausePlayer] = useState({ pause: false });
  const [playTime, setPlayTime] = useState(0);
  const [seekTime, setSeekTime] = useState<{ seconds: number } | null>(null);
  const [looping, setLooping] = useState(false);
  const [isChatReady, setIsChatReady] = useState(false);

  const [xIntervals, setXIntervals] = useState<number[]>(() => signals.map(() => 0));
  const [lastPoints, setLastPoints] = useState<(number | null)[]>(() => signals.map(() => null));
  const [waitingData, setWaitingData] = useState<Signal[][]>(() => signals.map(() => []));
  const [maxValues, setMaxValues] = useState<Record<string, number>>({});

  const signalSub = useSubscription(SignalsBySearchIDSubscription, {
    variables: { id: Number(videoID) },
  });

  const onChatError = () => {
    setIsChatError(true);
    handleAmplitudeTrack("Video Error", { Error: "Chat Failed", SearchId: videoID });
  };

  const onSpeechError = () => {
    setIsSpeechError(true);
    handleAmplitudeTrack("Video Error", { Error: "Speech Failed", SearchId: videoID });
  };

  useEffect(() => {
    if (isChatError || isSpeechError) {
      // errorSearchMutation({ variables: { id: videoID } });

      if (!isAdmin) {
        setIsErrorDialogOpen(true);
      }
    }
  }, [isChatError, isSpeechError, isAdmin]);

  useTrigger(() => {
    if (signalSub.data && signalSub.data.signals_stream && signalSub.data.signals_stream.length) {
      const rawSignals = new Map<string, Signal[]>();
      for (const item of signalSub.data.signals_stream as Signal[]) {
        const rawSignal = rawSignals.get(item.title);
        if (rawSignal) rawSignal.push(item);
        else rawSignals.set(item.title, [item]);
      }

      const parsedSignals = new Map(signalData);
      const newXIntervals = [...xIntervals];
      const newLastPoints = [...lastPoints];
      const newWaitingData = [...waitingData];
      const newMaxValues = { ...maxValues };
      let newData = false;

      rawSignals.forEach((value, key) => {
        const signal = signalMap.get(key);
        if (!signal) return;

        let xInterval = xIntervals[signal.index];
        let lastPoint = lastPoints[signal.index];
        const unprocessed = [...waitingData[signal.index], ...value];
        unprocessed.sort((a, b) => a.from_seconds - b.from_seconds);

        const newTraceX: number[] = [];
        const newTraceY: number[] = [];
        let i = 0;
        for (; i < unprocessed.length; ++i) {
          const item = unprocessed[i];
          const xValues = JSON.parse(item.x_values);
          if (xValues.length === 0) continue;

          if (xInterval === 0 && xValues.length > 1) {
            xInterval = xValues[1] - xValues[0];
            newXIntervals[signal.index] = xInterval;
          }

          const roundingError = 0.05;
          let start = 0;
          if (lastPoint !== null) {
            if (xValues[0] - xInterval - lastPoint > roundingError) break;
            if (xValues[xValues.length - 1] - lastPoint < roundingError) continue;
            while (start < xValues.length - 1 && xValues[start] - lastPoint < roundingError)
              ++start;
          }

          newTraceX.push(...xValues.slice(start));
          newTraceY.push(...JSON.parse(item.y_values).slice(start));
          lastPoint = xValues[xValues.length - 1];
        }
        newWaitingData[signal.index] = unprocessed.slice(i);

        if (newTraceX.length) {
          newLastPoints[signal.index] = lastPoint;
          const existingSignals = parsedSignals.get(key) || { x: [], y: [] };
          let scaledY = newTraceY;
          if (signal.rescale) {
            const maxValue = maxValues[key] || 0;
            let newMax = _.max(newTraceY) as number;
            newMax = _.max([maxValue, newMax]) as number;
            if (newMax > 0) {
              scaledY = newTraceY.map((y) => y / newMax);
              if (newMax > maxValue && maxValue > 0) {
                existingSignals.y = existingSignals.y.map((y) => (y * maxValue) / newMax);
              }
            }
            newMaxValues[key] = newMax;
          }
          const deadzonedY = scaledY.map((val) => (Math.abs(val) < signal.deadzone ? 0 : val));
          const [simplifiedX, simplifiedY] = simplify(newTraceX, deadzonedY);
          const flippedY = signal.negative ? simplifiedY.map((val) => val * -1) : simplifiedY;
          const timeX = simplifiedX.map((val) => secondsToDate(val));
          parsedSignals.set(key, {
            x: [...existingSignals.x, ...timeX],
            y: [...existingSignals.y, ...flippedY],
          });
          newData = true;
          if (
            key === "Chat intensity" &&
            videoInfo &&
            durationToSeconds(videoInfo.video_duration) - simplifiedX[simplifiedX.length - 1] <= 120
          )
            onChatDone();
        }
      });

      setXIntervals(newXIntervals);
      setWaitingData(newWaitingData);
      if (newData) {
        setSignalData(parsedSignals);
        setLastPoints(newLastPoints);
        setMaxValues(newMaxValues);
      }
    }
  }, [signalSub.data]);

  const recommendedSub = useSubscription(RecommendedMomentsBySearchIDSubscription, {
    variables: { id: Number(videoID) },
  });

  useTrigger(() => {
    if (recommendedSub.data && recommendedSub.data.recommended_moment_stream) {
      const recommendedEvents = recommendedSub.data.recommended_moment_stream;
      const newRecommendedMoments = recommendedEvents
        .map((event) => recommendedMomentFromEvent(event, videoID))
        .filter((maybeMoment: RecommendedMoment | null) => maybeMoment) as RecommendedMoment[];

      const allRecommendedMoments = [...recommendedMoments, ...newRecommendedMoments];
      const uniqueMoments = dedupeMoments(allRecommendedMoments);
      const localUpdatesJSON = localStorage.getItem(`recommendedMomentUpdates.${videoID}`);
      const localUpdates = localUpdatesJSON ? JSON.parse(localUpdatesJSON) : [];

      for (const { id, update } of localUpdates) {
        const index = uniqueMoments.findIndex((m) => m.id === id);
        if (index !== -1) {
          const updatedMoment = { ...uniqueMoments[index], ...update, modified: true };
          uniqueMoments[index] = updatedMoment;
        }
      }

      if (momentId && recommendedMoments.length === 0) {
        const urlMoment = uniqueMoments.find((m) => m.id === momentId);
        if (urlMoment) setFocusedItem({ kind: "recommended", id: momentId });
      }

      setRecommendedMoments(uniqueMoments);
    }
  }, [recommendedSub.data]);

  // For backwards compatibility with old signals
  const reversedSignalReplacements = new Map(Array.from(signalReplacements, ([k, v]) => [v, k]));

  const annotateMoment = (moment: Moment): Moment => {
    const annotations: Record<string, number> = {};
    for (const { name, signal, negative } of signalAnnotations) {
      const annotation = getPeakInInterval(
        signalData.get(signal) || { x: [], y: [] },
        moment.start_time,
        moment.end_time,
        negative ? "min" : "max"
      );

      if (annotation !== undefined)
        annotations[name] = +(annotation * (negative ? -1 : 1)).toFixed(3);
      else {
        // For backwards compatibility for old signals
        const replacement = reversedSignalReplacements.get(signal);
        if (replacement) {
          const replacementAnnotation = getPeakInInterval(
            signalData.get(replacement) || { x: [], y: [] },
            moment.start_time,
            moment.end_time,
            negative ? "min" : "max"
          );
          if (replacementAnnotation !== undefined)
            annotations[name] = +(replacementAnnotation * (negative ? -1 : 1)).toFixed(3);
        }
      }
    }
    return { ...moment, annotations };
  };

  const saveMoment = (moment: Moment) => {
    if (localIDs.current.includes(moment.id))
      upsertMomentMutation({ variables: { object: moment } });
    else {
      const momentUpdate: any = { ...moment };
      delete momentUpdate.search_id;
      updateMomentMutation({ variables: { id: moment.id, object: momentUpdate } });
    }
  };

  useTrigger(() => {
    if (focusedItem?.kind === "user" && momentTabIndex !== 0) setMomentTabIndex(0);
    if (focusedItem?.kind === "recommended" && momentTabIndex !== 1) setMomentTabIndex(1);
  }, [focusedItem]);

  useTrigger(() => {
    if (focusedItem?.kind === "recommended" && momentTabIndex !== 1) setFocusedItem(null);
  }, [momentTabIndex]);

  useQuery(SearchByIDQuery, {
    onCompleted(data) {
      if (data && data.search_by_pk) {
        const searchItem = data.search_by_pk;

        const serverMoments: Moment[] = searchItem.moments.map((moment: any) => {
          delete moment.__typename;
          return momentFromJSON(moment);
        });
        const localMomentsJSON = localStorage.getItem(`searchMoments.${videoID}`);
        const localMoments: Moment[] = localMomentsJSON
          ? JSON.parse(localMomentsJSON).map((moment: SerializableMoment) => momentFromJSON(moment))
          : [];
        const updateIDs: string[] = [];
        const updateMoments: Moment[] = [];
        localMoments.forEach((moment) => {
          const serverMoment = serverMoments.find((item) => item.id === moment.id);
          if (!serverMoment || serverMoment.updated_at < moment.updated_at) {
            updateIDs.push(moment.id);
            updateMoments.push(moment);
            if (!serverMoment) localIDs.current.push(moment.id);
          }
        });
        const mergedMoments = serverMoments
          .filter((moment) => !updateIDs.includes(moment.id))
          .concat(localMoments.filter((moment) => updateIDs.includes(moment.id)));
        setMoments(mergedMoments);
        updateMoments.forEach((moment) => saveMoment(moment));

        if (momentId) {
          const urlMoment = mergedMoments.find((m) => m.id === momentId);
          if (urlMoment) setFocusedItem({ kind: "user", id: momentId });
        } else if (mentionTimestamp) setSeekTime({ seconds: parseInt(mentionTimestamp) });

        setIsLoopingLoading(false);
        setVideoInfo(searchItem);
      } else {
        navigate(RivrLocations.AccessDenied, { replace: true });
      }
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetSearchByIDs ERROR: ", graphQLErrors, networkError);
    },
    variables: { id: Number(videoID) },
    pollInterval: videoInfo && videoInfo.status === "in-progress" ? 5000 : 0,
  });

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      return;
    },
    onError({ graphQLErrors, networkError }) {
      showToast(videoID, "An error occured, Please try again later!", "error");
      console.log("stopSearchAPI ERROR: ", graphQLErrors, networkError);
    },
  });

  const showToast = (id: string, description: any, status: any) => {
    if (!toast.isActive(id)) {
      toast({
        id: id,
        title: description,
        status: status,
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
  };

  const stopSearch = () => {
    setIsLoopingLoading(true);
    stopSearchAPI({ variables: { id: Number(videoID) } });
  };

  const addMoment = (moment: Moment) => {
    const annotatedMoment = annotateMoment(moment);
    setMoments([...moments, annotatedMoment]);
    setFocusedItem({ kind: "user", id: moment.id });
    localIDs.current.push(moment.id);
    upsertMomentMutation({ variables: { object: annotatedMoment } });
  };

  const acceptMoments = (recommended: RecommendedMoment[]) => {
    const newMoments = recommended.map((m) => annotateMoment(momentFromRecommendedMoment(m)));
    setMoments([...moments, ...newMoments]);
    const acceptedIDs = recommended.map((r) => r.id);
    setRecommendedMoments(
      recommendedMoments.map((r) => (acceptedIDs.includes(r.id) ? { ...r, accepted: true } : r))
    );
    if (focusedItem && recommended.map((r) => r.id).includes(focusedItem.id)) setFocusedItem(null);
    localIDs.current.push(...newMoments.map((moment) => moment.id));
    newMoments.forEach((moment) => upsertMomentMutation({ variables: { object: moment } }));
    lastAcceptedMoment.current = newMoments.length === 1 ? newMoments[0].id : null;
  };

  const focusLastMoment = () => {
    setMomentTabIndex(0);
    setFocusedItem(
      lastAcceptedMoment.current === null ? null : { kind: "user", id: lastAcceptedMoment.current }
    );
  };

  const deleteMoments = (ids: string[]) => {
    const now = new Date();
    const newMoments = moments.map((moment) =>
      ids.includes(moment.id) ? { ...moment, deleted: true, updated_at: now } : moment
    );
    setMoments(newMoments);
    const recommendedIds = ids.map((id) => moments.find((m) => m.id === id)?.recommended_moment_id);
    setRecommendedMoments(
      recommendedMoments.map((r) => (recommendedIds.includes(r.id) ? { ...r, accepted: false } : r))
    );

    const initialState: { local: string[]; remote: string[] } = { local: [], remote: [] };
    const { local, remote } = ids.reduce((idMap, id) => {
      idMap[localIDs.current.includes(id) ? "local" : "remote"].push(id);
      return idMap;
    }, initialState);

    local.forEach((id) => {
      const moment = newMoments.find((item) => item.id === id);
      if (moment) saveMoment(moment);
    });
    if (remote.length) deleteMomentsMutation({ variables: { ids: remote, updated_at: now } });
  };

  const updateSelectedMoment = (update: Pick<Moment, "start_time" | "end_time">) => {
    if (selectedMoment) {
      if (focusedItem?.kind === "user") updateMoment(selectedMoment as Moment, update);
      else if (focusedItem?.kind === "recommended") {
        updateRecommendedMoment(selectedMoment as RecommendedMoment, update);
      }
    }
  };

  const updateMoment = (moment: Moment, update: Partial<Moment>) => {
    const newMoment = annotateMoment({ ...moment, ...update, updated_at: new Date() });
    setMoments(moments.map((item) => (item.id === moment.id ? newMoment : item)));
    saveMoment(newMoment);
  };

  const updateRecommendedMoment = (
    moment: RecommendedMoment,
    update: Partial<RecommendedMoment>
  ) => {
    const newMoment = { ...moment, ...update, modified: true };
    const newRecommendedMoments = recommendedMoments.map((item) =>
      item.id === moment.id ? newMoment : item
    );
    setRecommendedMoments(newRecommendedMoments);
    // Save recommended moment updates locally
    const localUpdatesJSON = localStorage.getItem(`recommendedMomentUpdates.${videoID}`);
    const localUpdates = localUpdatesJSON ? JSON.parse(localUpdatesJSON) : [];
    const newUpdates = [
      ...localUpdates.filter((u: any) => u.id !== moment.id),
      { id: moment.id, update },
    ];
    localStorage.setItem(`recommendedMomentUpdates.${videoID}`, JSON.stringify(newUpdates));
  };

  const restoreRecommendedMoment = (moment: RecommendedMoment) => {
    const newMoment = {
      ...moment,
      start_time: moment.time - defaultMomentDuration / 2,
      end_time: moment.time + defaultMomentDuration / 2,
      modified: false,
    };
    const newRecommendedMoments = recommendedMoments.map((item) =>
      item.id === moment.id ? newMoment : item
    );
    setRecommendedMoments(newRecommendedMoments);
    // Clear local updates for moment
    const localUpdatesJSON = localStorage.getItem(`recommendedMomentUpdates.${videoID}`);
    const localUpdates = localUpdatesJSON ? JSON.parse(localUpdatesJSON) : [];
    const newUpdates = localUpdates.filter((u: any) => u.id !== moment.id);
    localStorage.setItem(`recommendedMomentUpdates.${videoID}`, JSON.stringify(newUpdates));
    // Select reverted moment (or rezoom if already selected)
    if (selectedMoment && selectedMoment.id === moment.id)
      setTimelineZoom({ start: newMoment.start_time, end: newMoment.end_time });
    else setFocusedItem({ kind: "recommended", id: moment.id });
  };

  useEffect(() => {
    localStorage.setItem(`searchMoments.${videoID}`, JSON.stringify(moments));
  }, [moments]);

  const toggleMoment = (id: string) => {
    const moment = recommendedMoments.find((moment) => moment.id === id);
    if (!moment) return;
    if (!moment.rejected) {
      // Discard Recommended Moment changes when a moment is rejected
      restoreRecommendedMoment({ ...moment, rejected: !moment.rejected });
      setFocusedItem(null);
    } else {
      setRecommendedMoments(
        recommendedMoments.map((m) => (m.id === id ? { ...m, rejected: !m.rejected } : m))
      );
      setFocusedItem({ kind: "recommended", id });
    }
    toggleMomentMutation({ variables: { id: id, rejected: !moment.rejected } });
  };

  const handleHypeGraphSeek = (seconds: number) => {
    setSeekTime({ seconds });
  };

  // Zoom in when a moment is selected
  useTrigger(() => {
    if (selectedMoment) {
      setSeekTime({ seconds: selectedMoment.start_time });
      setTimelineZoom({
        start: selectedMoment.start_time,
        end: selectedMoment.end_time,
      });
    }
  }, [selectedMoment && selectedMoment.id]);

  useTrigger(() => {
    if (
      looping &&
      selectedMoment &&
      (playTime < selectedMoment.start_time || playTime >= selectedMoment.end_time)
    )
      setSeekTime({ seconds: selectedMoment.start_time });
  }, [playTime]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  function onChatDone() {
    if (!isChatReady) setIsChatReady(true);
  }

  useEffect(() => {
    if (momentId) {
      const moment = moments.find((m) => m.id === momentId);
      if (moment) setFocusedItem({ kind: "user", id: momentId });
    }
  }, [momentId]);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <VStack w={"100%"}>
        <Flex
          className={"results-page"}
          w={"100%"}
          h={"calc(100vh - 4rem)"}
          p={4}
          overflowX={isCollapsed ? "hidden" : undefined}
        >
          <Flex
            className={"results-list"}
            width={"100%"}
            pr={4}
            minW={"360px"}
            maxW={"440px"}
            borderRightWidth={1}
          >
            <MomentPanel
              moments={activeMoments}
              recommendedMoments={
                showSelectedMoment
                  ? [
                      ...filteredRecommendedMoments,
                      annotatedRecommendedMoments[selectedMomentIndex],
                    ]
                  : filteredRecommendedMoments
              }
              modifiedRecommendedMoments={modifiedRecommendedMoments}
              restoreRecommendedMoment={restoreRecommendedMoment}
              toggleMoment={toggleMoment}
              totalMoments={activeMoments.length}
              focusedCallback={setFocusedItem}
              focusLastMoment={focusLastMoment}
              focusedItem={focusedItem !== null ? focusedItem.id : null}
              deleteMoments={deleteMoments}
              updateMoment={updateMoment}
              searchId={videoID}
              videoInfo={videoInfo}
              recommendedMomentsLoading={recommendedSub.loading}
              acceptMoments={acceptMoments}
              momentTabIndex={momentTabIndex}
              setMomentTabIndex={setMomentTabIndex}
              pauseMedia={() => setPausePlayer({ pause: true })}
              recommendedAmount={recommendedAmount}
              setRecommendedAmount={setRecommendedAmount}
              minRecommendedAmount={Math.min(minRecommendedMoments, recommendedMoments.length)}
              maxRecommendedAmount={recommendedMoments.length}
              signalLoading={signalSub.loading}
              isOrgMember={isOrgMember}
            />
          </Flex>
          <Flex w={"100%"} className={"media-panel"}>
            <MediaPanel
              videoInfo={videoInfo}
              stopSearch={stopSearch}
              isLoopingLoading={isLoopingLoading}
              moments={activeMoments}
              recommendedMoments={
                momentTabIndex === 1
                  ? showSelectedMoment
                    ? [
                        ...filteredRecommendedMoments,
                        annotatedRecommendedMoments[selectedMomentIndex],
                      ].filter((moment) => !moment.rejected)
                    : filteredRecommendedMoments.filter((moment) => !moment.rejected)
                  : []
              }
              modifiedRecommendedMoments={
                momentTabIndex === 1
                  ? modifiedRecommendedMoments.filter((moment) => !moment.rejected)
                  : []
              }
              videoID={videoID}
              handleFocusedChange={setFocusedItem}
              selectedMoment={selectedMoment}
              updateSelectedMoment={updateSelectedMoment}
              addMoment={addMoment}
              defaultMomentDuration={defaultMomentDuration}
              pausePlayer={pausePlayer}
              playTime={playTime}
              setPlayTime={setPlayTime}
              seekTime={seekTime}
              timelineZoom={timelineZoom}
              graphSeekCallback={handleHypeGraphSeek}
              looping={looping}
              setLooping={setLooping}
              signalLoading={signalSub.loading}
              signalError={Boolean(signalSub.error)}
              signalData={signalData}
            />
          </Flex>
          <Tooltip label={isCollapsed ? "Show panel" : "Hide panel"} placement={"left"}>
            <IconButton
              aria-label={isCollapsed ? "Show Chat/Speech Panel" : "Hide Chat/Speech Panel"}
              onClick={() => {
                handleCollapseClick();
                handleAmplitudeTrack(isCollapsed ? "Show Panel Clicked" : "Hide Panel Clicked");
              }}
              right={isCollapsed ? 0 : "-1.05rem"}
              p={0}
              m={0}
              h={40}
              alignSelf={"center"}
              minW={4}
              w={4}
              icon={
                isCollapsed ? <ChevronLeftIcon boxSize={5} /> : <ChevronRightIcon boxSize={5} />
              }
              borderRadius={"full"}
              variant={"ghost"}
              position={isCollapsed ? "absolute" : undefined}
              overflow={"hidden"}
            />
          </Tooltip>
          <Flex
            className={"chat-speech-panel"}
            w={isCollapsed ? "0px" : "100%"}
            h={isCollapsed ? "0px" : undefined}
            pl={isCollapsed ? 0 : 4}
            ml={isCollapsed ? 0 : 0}
            minW={isCollapsed ? "0px" : "360px"}
            maxW={isCollapsed ? "0px" : "440px"}
            borderLeftWidth={isCollapsed ? "0px" : 1}
            position={isCollapsed ? "absolute" : undefined}
            right={isCollapsed ? "-100%" : undefined}
            display={isCollapsed ? "none" : undefined}
          >
            <ChatPanel
              videoInfo={videoInfo}
              playTime={playTime}
              seek={(seconds) => {
                setFocusedItem(null);
                setSeekTime({ seconds });
                setTimelineZoom({
                  start: seconds - defaultMomentDuration / 2,
                  end: seconds + defaultMomentDuration / 2,
                });
              }}
              isCollapsed={isCollapsed}
              isChatReady={videoInfo && (isChatReady || videoInfo.status === "stopped")}
              isSpeechReady={
                videoInfo && videoInfo.asr_transcript && videoInfo.status === "stopped"
              }
              onChatError={onChatError}
              onSpeechError={onSpeechError}
            />
          </Flex>
        </Flex>
      </VStack>

      <AlertDialog
        isOpen={isErrorDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() =>
          navigate(
            `${
              isOrgMember ? RivrLocations.Campaigns : RivrLocations.Search
            }?${searchParams.toString()}`
          )
        }
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Error</AlertDialogHeader>
            <AlertDialogBody display={"flex"} flexDir={"column"} gap={2}>
              An error occured with this video, if the issue persists, please contact support.
              {(isChatError || isSpeechError) && (
                <Alert
                  status="error"
                  fontSize={"sm"}
                  borderRadius={"md"}
                  py={1.5}
                  px={3}
                  w={"fit-content"}
                >
                  <AlertIcon boxSize={4} />
                  Failed to fetch{" "}
                  {isChatError && isSpeechError
                    ? "chat and speech transcripts"
                    : isChatError
                    ? "chat transcript"
                    : isSpeechError
                    ? "speech transcript"
                    : ""}
                </Alert>
              )}
            </AlertDialogBody>
            <AlertDialogFooter justifyContent={"space-between"}>
              <Button
                variant={"ghost"}
                onClick={() => {
                  navigate(
                    `${
                      isOrgMember ? RivrLocations.Campaigns : RivrLocations.Search
                    }?${searchParams.toString()}`
                  );
                  handleAmplitudeTrack("Video Error", {
                    Action: "Back",
                    SearchID: videoID,
                  });
                }}
                ref={cancelRef}
              >
                Back
              </Button>
              <Button
                colorScheme={"blue"}
                href={`${RivrLocations.KnowledgeBase + "/support"}?${searchParams.toString()}`}
                onClick={() => {
                  handleAmplitudeTrack("Video Error", {
                    Action: "Support",
                    SearchID: videoID,
                  });
                }}
                ref={cancelRef}
                as={"a"}
                target={"_blank"}
              >
                Support
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
export default Results;
