import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  AlertDialogCloseButton,
  VStack,
  Box,
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import ReactQRCode from "react-qr-code";
import * as amplitude from "@amplitude/analytics-browser";

interface QRCodeDownloadProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  isExpired: boolean;
}

const QRCodeDownload: React.FC<QRCodeDownloadProps> = ({ url, isOpen, onClose, isExpired }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const hasUrl = !!url;
  if (!hasUrl) {
    amplitude.track("Error", {
      message: "Unable to generate QR code",
    });
  }

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack w={"full"} gap={6}>
              {hasUrl && !isExpired ? (
                <>
                  <Box bg={"white"} p={4} borderRadius={"md"}>
                    <ReactQRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={url || ""}
                    />
                  </Box>
                  <VStack w={"full"} gap={0}>
                    <Text textAlign="center">Scan code on another device to download.</Text>
                    <Text textAlign="center" fontSize={"xs"} color={"gray.300"}>
                      This code expires in 7 days.
                    </Text>
                  </VStack>
                </>
              ) : (
                <Alert status={"error"} borderRadius={"md"} mt={2}>
                  <AlertIcon />
                  <VStack gap={0} align={"start"}>
                    <AlertTitle>Unable to generate QR code</AlertTitle>
                    <AlertDescription>
                      {isExpired
                        ? "This code has expired. Please re-download the clip to generate a new QR code."
                        : "If the issue persists, try re-downloading the clip and then send it to your device again."}
                    </AlertDescription>
                  </VStack>
                </Alert>
              )}
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme={"green"} w={"full"} onClick={onClose}>
              Thanks!
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default QRCodeDownload;
