import { PropsWithChildren } from "react";
import { Center, VStack, Text, Heading, Button, Box } from "@chakra-ui/react";
import { ArrowLeft } from "@icon-park/react";
import { CircleAlert } from "lucide-react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Link } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { KBSubPages, RivrLocations } from "./models/navigation";
import * as amplitude from "@amplitude/analytics-browser";

export const ErrorScreen = ({ children }: PropsWithChildren) => {
  const { logout } = useKindeAuth();

  const renderError = ({ error }: FallbackProps) => (
    <Center w={"100vw"} h={"100vh"} p={6}>
      <VStack gap={6} justifyContent={"center"}>
        <Box color={"red.300"}>
          <CircleAlert color={"currentColor"} size={"4rem"} />
        </Box>
        <VStack w={"100%"}>
          <Heading as={"h1"} fontSize={"2xl"}>
            Error
          </Heading>
          <Text color={"gray.300"}>Something went wrong</Text>
          <Text bg={"red.300"} px={3} py={2} borderRadius={"md"} color={"blackAlpha.900"}>
            {error.message}
          </Text>
          <Text color={"gray.300"} fontSize={"sm"}>
            If the issue persists, please{" "}
            <Link to={`${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`}>
              <Text as={"span"} textDecor={"underline"}>
                contact support
              </Text>
            </Link>
          </Text>
        </VStack>
        <Button colorScheme={"gray"} leftIcon={<ArrowLeft />} as={"a"} href={"/"}>
          Back to Rivr
        </Button>
        <Button
          onClick={logout}
          variant={"link"}
          fontWeight={"normal"}
          fontSize={"sm"}
          color={"gray.300"}
        >
          Sign out
        </Button>
      </VStack>
    </Center>
  );

  return (
    <ErrorBoundary
      fallbackRender={renderError}
      onError={(error, info) => {
        amplitude.track("Error", {
          message: error.message,
          stack: error.stack,
          componentStack: info.componentStack,
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
