import { useMemo, useRef, useState, useContext } from "react";
import { TriangleUpIcon, TriangleDownIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Box,
  Tooltip,
  Icon,
  Select,
  FormControl,
  MenuGroup,
  FormHelperText,
  MenuDivider,
  Button,
  ButtonGroup,
  Divider,
  Wrap,
  WrapItem,
  Checkbox,
  MenuItem,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertIcon,
  Alert,
  AlertDescription,
  Badge,
} from "@chakra-ui/react";
import {
  DoubleLeft,
  DoubleRight,
  FullScreenPlay,
  Filter,
  Left,
  Right,
  Star,
  Search,
  NewEfferent,
  InboxIn,
  InboxOut,
} from "@icon-park/react";
import "../../Campaigns.css";
import { RivrChannel } from "../../../../models/rivrChannel";
import { useSearchParams } from "react-router-dom";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../../models/navigation";
import { RivrSearch } from "../../../../models/rivrSearch";
import {
  Moment,
  MomentType,
  recommendedMomentSettings,
  RivrMoment,
  scoreAnnotations,
} from "../../../../models/moment";
import { usePagination } from "../../../../hooks/usePagination";
import { durationToSeconds, offsetDate } from "../../../../utils/time";
import "../CampaignDiscover.css";
import MiniPlayer from "../../../MiniPlayer/MiniPlayer";
import { useMutation } from "@apollo/client";
import { UpdateMomentMutation } from "../../../../api/moment";
import { UpdateRecommendedMomentMutation } from "../../../../api/recommended-moment";
import DiscoverViewSelect from "../DiscoverViewSelect";
import { DiscoverMomentItem } from "./DiscoverMomentItem";
import { RivrLanguage } from "../../../../models/rivrLanguage";
import { FeatureFilters } from "../../../FeatureFilters/FeatureFilters";
import { dedupeRivrMoments } from "../../../../utils/moment";
import { pillOptions } from "../../../../models/tags";
import * as amplitude from "@amplitude/analytics-browser";
import DebouncedInput from "../../../Input/DebouncedInput";
import { Action } from "../../../../models/action";
import { CopyCheck } from "lucide-react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { DownloadsContext } from "../../../../context/DownloadsContext";

interface Props {
  searches: RivrSearch[];
  fetchSearches: any;
  languages: RivrLanguage[];
  channels: RivrChannel[];
}

const CampaignDiscoverMoments = ({ searches, fetchSearches, languages, channels }: Props) => {
  const { getBooleanFlag } = useKindeAuth();
  const allowBulkActions = getBooleanFlag("discover-moments-bulk-actions", false);
  const { downloaderRequest: handleMomentDownload, isMomentDownloading: isDownloading } =
    useContext(DownloadsContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];
  const selectedTags = searchParams.getAll(CampaignURLParams.SelectedTags) || [];
  const sortOption = searchParams.get(CampaignURLParams.MomentSortOption) || "features";
  const sortDirection = searchParams.get(CampaignURLParams.MomentSortDirection) || "desc";
  const momentTypeFilter = searchParams.get(CampaignURLParams.MomentTypeFilter) || "all";
  const momentStatusFilter = searchParams.getAll(CampaignURLParams.MomentStatusFilter) || [
    "favorited",
  ];
  const tagFilterMode = searchParams.get(CampaignURLParams.TagFilterMode) || "all";
  const dateFilterStart = searchParams.get(CampaignURLParams.DateFilterStart) || "";
  const dateFilterEnd = searchParams.get(CampaignURLParams.DateFilterEnd) || "";
  const momentFilterText = searchParams.get(CampaignURLParams.MomentFilterText) || "";

  const pageSize = searchParams.get(CampaignURLParams.MomentPageSize) || "50";
  const selectedLanguages = searchParams.getAll(CampaignURLParams.SelectedLanguages) || [];
  const requiredFeatures = searchParams.getAll(CampaignURLParams.DiscoverFeatures) || [];

  const [checkedItems, setCheckedItems] = useState<RivrMoment[]>([]);
  const [miniPlayer, setMiniPlayer] = useState(() => {
    const storedState = localStorage.getItem("miniPlayer");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [selectedMoment, setSelectedMoment] = useState<RivrMoment | null>(null);
  const [playerScale, setPlayerScale] = useState(1);
  const [showBulkDownloadConfirmation, setShowBulkDownloadConfirmation] = useState(false);
  const [showBulkViewConfirmation, setShowBulkViewConfirmation] = useState(false);
  const [showBulkArchiveConfirmation, setShowBulkArchiveConfirmation] = useState(false);
  const [showBulkUnarchiveConfirmation, setShowBulkUnarchiveConfirmation] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [momentUpdateAPI] = useMutation(UpdateMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [recommendedMomentUpdateAPI] = useMutation(UpdateRecommendedMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const handleSelectAll = () => {
    let newCheckedItems: RivrMoment[] = [];
    if (!isPageSelected()) {
      newCheckedItems = [
        ...checkedItems,
        ...elements.filter((newItem) => !checkedItems.some((item) => item.id === newItem.id)),
      ];
      handleAmplitudeTrack("Select All", { Component: "Discover Moments", Action: "Select" });
    } else
      handleAmplitudeTrack("Select All", { Component: "Discover Moments", Action: "Deselect" });

    setCheckedItems(newCheckedItems);
  };

  const handleMomentCheckboxSelect = (moment: RivrMoment) => {
    const newCheckedItems = checkedItems.some((item) => item.id === moment.id)
      ? checkedItems.filter((item) => item.id !== moment.id)
      : checkedItems.concat(moment);
    setCheckedItems(newCheckedItems);
  };

  const handleBulkDownload = () => {
    for (const moment of checkedItems) {
      if (moment.type === "User Generated")
        handleMomentDownload(
          [
            {
              id: moment.id,
              search_id: moment.searchId,
              title: moment.title,
              tags: moment.tags,
              start_time: durationToSeconds(moment.timestamp),
              end_time: durationToSeconds(moment.timestamp) + moment.length,
              deleted: false,
              created_at: new Date(moment.createdAt),
              updated_at: new Date(moment.updatedAt),
              recommended_moment_id: undefined,
              annotations: moment.annotations,
            },
          ],
          `${moment.searchId}`
        );
    }
    handleAmplitudeTrack("Bulk Action", {
      Action: "Download",
      Count: checkedItems.filter((m) => m.type === "User Generated").map((m) => m.id).length,
      MomentIds: checkedItems.filter((m) => m.type === "User Generated").map((m) => m.id),
    });
  };

  const handleBulkView = () => {
    for (const moment of checkedItems) {
      const viewLink = `${RivrLocations.Results}/${moment.searchId}?${updateURLParams(
        searchParams.toString(),
        [
          [ResultsURLParams.SelectedMoment, moment.id],
          [ResultsURLParams.SelectedTimestamp, ""],
          [ResultsURLParams.SelectedPanel, ""],
        ]
      )}`;
      window.open(viewLink, "_blank");
    }
    handleAmplitudeTrack("Bulk Action", {
      Action: "View",
      Count: checkedItems.map((m) => m.id).length,
      MomentIds: checkedItems.map((m) => m.id),
    });
  };

  const handleBulkArchive = () => {
    for (const moment of checkedItems) {
      if (moment.archivedAt === null) handleArchive(moment, new Date());
    }
    handleAmplitudeTrack("Bulk Action", {
      Action: "Archive",
      Count: checkedItems.map((m) => m.id).length,
      MomentIds: checkedItems.map((m) => m.id),
    });
  };

  const handleBulkUnarchive = () => {
    for (const moment of checkedItems) {
      if (moment.archivedAt !== null) handleArchive(moment, null);
    }
    handleAmplitudeTrack("Bulk Action", {
      Action: "Unarchive",
      Count: checkedItems.map((m) => m.id).length,
      MomentIds: checkedItems.map((m) => m.id),
    });
  };

  const handleFavorite = (moment: RivrMoment) => {
    const isFavorited = !moment.favorited;

    if (moment.type === "User Generated") {
      momentUpdateAPI({
        variables: { id: moment.id, object: { favorited: isFavorited } },
      });
    } else {
      recommendedMomentUpdateAPI({
        variables: { id: moment.id, object: { favorited: isFavorited } },
      });
    }

    moment.favorited = isFavorited;

    handleAmplitudeTrack("Moment Favorite Toggled", {
      MomentID: moment.id,
      Favorited: isFavorited,
      Type: moment.type,
    });
  };

  const handleArchive = (moment: RivrMoment, date: Date | null) => {
    moment.type === "User Generated"
      ? momentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        })
      : recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        });

    moment.archivedAt =
      date !== null
        ? date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null;
  };

  const toggleMiniPlayer = () => {
    const newState = !miniPlayer;
    setMiniPlayer(newState);
    localStorage.setItem("miniPlayer", JSON.stringify(newState));
    handleAmplitudeTrack("Mini Player Toggled", { State: newState ? "Enabled" : "Disabled" });
  };

  const tableData: RivrMoment[] = useMemo(() => {
    let result: RivrMoment[] = [];

    searches.forEach((search) => {
      result = result.concat(search.userMoments);
      result = result.concat(search.recommendedMoments);
    });

    return result;
  }, [channels, searches]);

  const handleSort = (field: string) => {
    const newParams: Array<[string, string]> = [];
    if (sortOption === field) {
      newParams.push([
        CampaignURLParams.MomentSortDirection,
        sortDirection === "asc" ? "desc" : "asc",
      ]);
      handleAmplitudeTrack("Moment Sort Updated", {
        SortMethod: field,
        SortDirection: sortDirection === "asc" ? "desc" : "asc",
      });
    } else {
      newParams.push(
        [CampaignURLParams.MomentSortOption, field],
        [CampaignURLParams.MomentSortDirection, "desc"]
      );
      handleAmplitudeTrack("Moment Sort Updated", {
        SortMethod: field,
        SortDirection: "desc",
      });
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleMomentTypeFilter = (option: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MomentTypeFilter, option]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Moment Type Filter Applied", { FilterOption: option });
  };

  const handleMomentStatusFilter = (options: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.MomentStatusFilter, options],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Moment Status Filter Applied", { FilterOptions: options });
  };

  const handleFilterText = (text: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MomentFilterText, text]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleDateFilterStart = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterStart, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Moment Date Filter Start Applied", { StartDate: date });
  };

  const handleDateFilterEnd = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterEnd, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Moment Date Filter End Applied", { EndDate: date });
  };

  const handleLanguageSelect = (selection: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [];
    if (selection.length === 0) {
      newParams.push([CampaignURLParams.SelectedLanguages, []]);
    } else if (typeof selection === "string") {
      newParams.push([CampaignURLParams.SelectedLanguages, selection]);
    } else {
      newParams.push([
        CampaignURLParams.SelectedLanguages,
        selection.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }),
      ]);
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleTagFilterModeChange = (mode: "all" | "any") => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.TagFilterMode, mode]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Tag Filter Mode Changed", { Mode: mode });
  };

  const handleTagSelection = (tag: string) => {
    let updatedSelectedTags = [...selectedTags];
    if (updatedSelectedTags.includes(tag)) {
      updatedSelectedTags = updatedSelectedTags.filter((t) => t !== tag);
    } else {
      updatedSelectedTags.push(tag);
    }

    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.SelectedTags, updatedSelectedTags],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    handleAmplitudeTrack("Tag Selection Changed", { SelectedTags: updatedSelectedTags });
  };

  const handlePageSize = (size: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MomentPageSize, size]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const sortMomentsData = useMemo(() => {
    return (a: RivrMoment, b: RivrMoment) => {
      if (!sortOption) return 0;
      let comparison = 0;

      if (sortOption === "features") {
        if (a.rank === undefined && b.rank !== undefined) {
          return 1;
        } else if (a.rank !== undefined && b.rank === undefined) {
          return -1;
        }
        comparison = a.rank - b.rank;
      } else {
        const valueA: any = a[sortOption as keyof RivrMoment];
        const valueB: any = b[sortOption as keyof RivrMoment];

        if (typeof valueA === "string" && typeof valueB === "string") {
          if (sortOption === "date") {
            comparison = new Date(valueA).valueOf() - new Date(valueB).valueOf();
          } else if (sortOption === "timestamp") {
            comparison = durationToSeconds(a["timestamp"]) - durationToSeconds(b["timestamp"]);
          } else {
            comparison = valueA.localeCompare(valueB);
          }
        } else {
          comparison = Number(valueA) - Number(valueB);
        }
      }

      comparison = sortDirection === "asc" ? comparison : -comparison;

      if (comparison === 0) {
        return a.rank !== b.rank
          ? b.rank - a.rank
          : a["date"] !== b["date"]
          ? new Date(b["date"]).valueOf() - new Date(a["date"]).valueOf()
          : a["channel"] !== b["channel"]
          ? a["channel"].localeCompare(b["channel"])
          : durationToSeconds(a["timestamp"]) - durationToSeconds(b["timestamp"]);
      }

      return comparison;
    };
  }, [searchParams]);

  const filterMomentsData = useMemo(() => {
    return (item: RivrMoment) => {
      // if (item.accepted) return false; // Filter out accepted recommendations

      if (selectedLanguages.length > 0 && selectedLanguages.includes(item.language) === false)
        return false;
      if (selectedChannels.length > 0 && !selectedChannels.includes(item.channel)) return false;
      if (!momentStatusFilter.includes("archived") && item.archivedAt !== null) return false;
      if (!momentStatusFilter.includes("favorited") && item.favorited === true) return false;
      if (momentTypeFilter !== "all" && item.type.toLowerCase() !== momentTypeFilter) return false;

      const momentDate = new Date(item.date);
      const filterStartDateUTC = offsetDate(dateFilterStart);
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (dateFilterStart && momentDate.valueOf() < filterStartDateUTC.valueOf()) return false;
      if (dateFilterEnd && momentDate.valueOf() >= filterEndDateUTC.valueOf()) return false;
      if (momentFilterText && momentFilterText.trim() !== "") {
        const searchText = momentFilterText.toLowerCase();

        const titleMatch = item.title && item.title.toLowerCase().includes(searchText);
        const tagsMatch =
          item.tags && item.tags.some((tag) => tag.toLowerCase().includes(searchText));
        const vodMatch = item.vod && item.vod.toLowerCase().includes(searchText);

        if (!titleMatch && !tagsMatch && !vodMatch) {
          return false;
        }
      }
      if (selectedTags.length > 0) {
        if (tagFilterMode === "all") {
          const normalizedSelectedTags = selectedTags.map((tag) => tag.toLowerCase());
          const normalizedMomentTags = item.tags.map((tag) => tag.toLowerCase());

          const hasAllTags = normalizedSelectedTags.every((tag) =>
            normalizedMomentTags.includes(tag)
          );
          const hasOnlySelectedTags = normalizedMomentTags.length === normalizedSelectedTags.length;

          if (!hasAllTags || !hasOnlySelectedTags) return false;
        } else if (tagFilterMode === "any") {
          const hasAnyTag = selectedTags.some((tag) =>
            item.tags.map((t) => t.toLowerCase()).includes(tag.toLowerCase())
          );
          if (!hasAnyTag) return false;
        }
      }

      return true;
    };
  }, [searchParams]);

  const limitRecommendedMomentsPerVOD = (moments: RivrMoment[]) => {
    const limit = 10;
    const recommendedMoments: Record<string, RivrMoment[]> = {};
    const otherMoments: RivrMoment[] = [];

    const sortedMoments = moments.sort((a, b) => b.rank - a.rank);
    sortedMoments.forEach((moment) => {
      if (moment.type !== "Recommended") {
        otherMoments.push(moment);
        return;
      }
      const vod = `${moment.vod} - ${moment.channel} - ${moment.date}`;
      if (!recommendedMoments[vod]) recommendedMoments[vod] = [moment];
      else {
        if (recommendedMoments[vod].length < limit) recommendedMoments[vod].push(moment);
      }
    });

    return otherMoments.concat(Object.values(recommendedMoments).flat());
  };

  const features = [
    // Maps to recommendedMomentSettings in models/moment.tsx
    "Hype",
    "ASR positive",
    "ASR negative",
    "Chat positive",
    "Chat negative",
  ];

  const recalculateRank = (moments: RivrMoment[]) => {
    const denominator = Object.values(scoreAnnotations).reduce((acc, weight) => acc + weight, 0);
    const result = moments.map((m) => ({ ...m }));

    result.forEach((moment) => {
      const rank =
        requiredFeatures.length < 1
          ? (moment.rank =
              features.reduce(
                (rnk, feat) => rnk + (moment.annotations?.[feat] ?? 0) * scoreAnnotations[feat],
                0
              ) / denominator)
          : requiredFeatures.reduce(
              (rnk, feat) =>
                rnk +
                (moment.annotations?.[feat] >=
                (recommendedMomentSettings as any)[feat].featureThreshold
                  ? 1
                  : 0) +
                (moment.annotations?.[feat] ?? 0),
              0
            );
      moment.rank = rank;
    });

    return result;
  };

  const {
    elements,
    totalElements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination<RivrMoment>(
    recalculateRank(limitRecommendedMomentsPerVOD(dedupeRivrMoments(tableData))),
    sortMomentsData,
    filterMomentsData,
    Number(pageSize)
  );

  const isPageUnselected = (): boolean => {
    if (checkedItems.length === 0) return true;
    return elements.every((moment) => !checkedItems.some((item) => item.id === moment.id));
  };

  const isPageSelected = (): boolean => {
    if (elements.length === 0 || checkedItems.length < elements.length) return false;
    return elements.every((moment) => checkedItems.some((item) => item.id === moment.id));
  };

  const resetFilters = () => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.DateFilterStart, ""],
      [CampaignURLParams.DateFilterEnd, ""],
      [CampaignURLParams.MomentTypeFilter, "all"],
      [CampaignURLParams.MomentStatusFilter, ["favorited"]],
      [CampaignURLParams.SelectedLanguages, []],
      [CampaignURLParams.DiscoverFeatures, []],
      [CampaignURLParams.SelectedTags, []],
      [CampaignURLParams.TagFilterMode, "any"],
    ];

    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });

    handleAmplitudeTrack("Moment Filters Reset");
  };

  const userTags = pillOptions
    .filter((tag) => tag.type === "user")
    .sort((a, b) => a.label.localeCompare(b.label));

  const orgTags = pillOptions
    .filter((tag) => tag.type === "org")
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const videoInfo = useMemo(() => {
    if (!selectedMoment) return null;
    const search = searches.find((s) => s.id === selectedMoment.searchId);
    if (!search) return null;
    return search;
  }, [selectedMoment]);

  return selectedCampaign === "" ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        Select a campaign
      </Text>
    </HStack>
  ) : searches.length === 0 ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        No videos in the selected campaign
      </Text>
    </HStack>
  ) : (
    <>
      <AlertDialog
        isOpen={showBulkDownloadConfirmation}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowBulkDownloadConfirmation(false)}
        isCentered
        size={"lg"}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Download Moments</AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {checkedItems.filter((m) => m.type === "User Generated").length}{" "}
                <Badge
                  fontWeight={"normal"}
                  colorScheme={"orange"}
                  textTransform={"capitalize"}
                  letterSpacing={"wider"}
                  fontSize={"sm"}
                  mr={1}
                  mb={1}
                  w={"7.7rem"}
                  textAlign={"center"}
                >
                  User Generated
                </Badge>
                Moment
                {checkedItems.filter((m) => m.type === "User Generated").length === 1
                  ? ""
                  : "s"}{" "}
                selected.
              </Text>
              <VStack align={"start"} gap={4}>
                <Text>
                  {checkedItems.filter((m) => m.type === "Recommended").length}{" "}
                  <Badge
                    fontWeight={"normal"}
                    colorScheme={"purple"}
                    textTransform={"capitalize"}
                    letterSpacing={"wider"}
                    fontSize={"sm"}
                    mr={1}
                    mb={1}
                    w={"7.7rem"}
                    textAlign={"center"}
                  >
                    Recommended
                  </Badge>
                  Moment
                  {checkedItems.filter((m) => m.type === "Recommended").length === 1
                    ? ""
                    : "s"}{" "}
                  selected.
                </Text>

                <Alert status={"warning"} borderRadius={"md"} sx={{ textWrap: "pretty" }}>
                  <AlertIcon />
                  <AlertDescription>
                    At this time, only User Generated Moments can be downloaded.
                  </AlertDescription>
                </Alert>
                <Text>{`Are you sure you want to download ${
                  checkedItems.filter((m) => m.type === "User Generated").length
                } ${
                  checkedItems.filter((m) => m.type === "User Generated").length !== 1
                    ? "Moments"
                    : "Moment"
                }?`}</Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setShowBulkDownloadConfirmation(false)}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleBulkDownload();
                  setShowBulkDownloadConfirmation(false);
                }}
                ml={2}
                isDisabled={checkedItems.filter((m) => m.type === "User Generated").length === 0}
              >
                Download
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={showBulkViewConfirmation}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowBulkViewConfirmation(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>View Moments</AlertDialogHeader>

            <AlertDialogBody>
              <VStack align={"start"} gap={4}>
                <Text>This action will open a browser tab for each selected Moment.</Text>
                <Text mb={4}>{`Are you sure you want to view ${checkedItems.length} ${
                  checkedItems.length !== 1 ? "Moments" : "Moment"
                }?`}</Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setShowBulkViewConfirmation(false)}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleBulkView();
                  setShowBulkViewConfirmation(false);
                }}
                ml={2}
              >
                View
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={showBulkArchiveConfirmation}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowBulkArchiveConfirmation(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Archive Moments</AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {`Are you sure you want to archive ${
                  checkedItems.filter((m) => m.archivedAt === null).length
                } ${
                  checkedItems.filter((m) => m.archivedAt === null).length !== 1
                    ? "Moments"
                    : "Moment"
                }?`}
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setShowBulkArchiveConfirmation(false)}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleBulkArchive();
                  setShowBulkArchiveConfirmation(false);
                }}
                ml={2}
                isDisabled={checkedItems.filter((m) => m.archivedAt === null).length === 0}
              >
                Archive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={showBulkUnarchiveConfirmation}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowBulkUnarchiveConfirmation(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Unarchive Moments</AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {`Are you sure you want to unarchive ${
                  checkedItems.filter((m) => m.archivedAt !== null).length
                } ${
                  checkedItems.filter((m) => m.archivedAt !== null).length !== 1
                    ? "Moments"
                    : "Moment"
                }?`}
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setShowBulkUnarchiveConfirmation(false)}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleBulkUnarchive();
                  setShowBulkUnarchiveConfirmation(false);
                }}
                ml={2}
                isDisabled={checkedItems.filter((m) => m.archivedAt !== null).length === 0}
              >
                Unarchive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack
        h={"100%"}
        w={"100%"}
        alignItems={"flex-start"}
        overflowY={"auto"}
        className={"discover-moments"}
        gap={0}
      >
        <HStack
          justifyContent={"space-between"}
          position={"sticky"}
          top={0}
          p={4}
          bg={"gray.800"}
          zIndex={2}
          w={"100%"}
        >
          <HStack w="100%" spacing={4} justifyContent={"flex-start"}>
            <DiscoverViewSelect />
            <Divider orientation="vertical" h={6} />
            <FeatureFilters features={features} urlParameter={CampaignURLParams.DiscoverFeatures} />
          </HStack>

          <HStack w="100%" justifyContent={"flex-end"}>
            <HStack>
              <Text w={"100%"} noOfLines={1} fontSize={"xs"} color={"gray.400"} textAlign={"right"}>
                {totalElements > 0
                  ? `Showing ${from} - ${to} of ${totalElements} ${
                      totalElements === 1 ? "Moment" : "Moments"
                    }`
                  : "Showing 0 Moments"}
              </Text>
            </HStack>

            <HStack>
              <DebouncedInput
                value={momentFilterText}
                onDebouncedChange={handleFilterText}
                delay={300}
                placeholder={"Filter by Moment, tag, or title"}
                width={"16rem"}
                borderRadius={"md"}
                size={"sm"}
                className={"amp-unmask"}
                leftIcon={<Search />}
              />
              <Box>
                <Menu closeOnSelect={false} placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    colorScheme="gray"
                    icon={<Filter theme="filled" />}
                    size="sm"
                  />
                  <MenuList maxH={"60vh"} overflowY={"auto"} sx={{ scrollbarWidth: "thin" }}>
                    <MenuGroup title="Date">
                      <HStack px={4} pb={2}>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterStart}
                            onChange={(e) => handleDateFilterStart(e.target.value)}
                          />
                          <FormHelperText>From</FormHelperText>
                        </FormControl>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterEnd}
                            onChange={(e) => handleDateFilterEnd(e.target.value)}
                          />
                          <FormHelperText>To</FormHelperText>
                        </FormControl>
                      </HStack>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={momentTypeFilter}
                      title="Type"
                      type="radio"
                      onChange={(value) => handleMomentTypeFilter(value as "All" | MomentType)}
                    >
                      <MenuItemOption value="all">All</MenuItemOption>
                      <MenuItemOption value="recommended">Recommended</MenuItemOption>
                      <MenuItemOption value="user generated">User Generated</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={momentStatusFilter}
                      title="Status"
                      type="checkbox"
                      onChange={(values) => handleMomentStatusFilter(values)}
                    >
                      <MenuItemOption value="favorited">Favorited</MenuItemOption>
                      <MenuItemOption value="archived">Archived</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuGroup title="Tags">
                      <ButtonGroup
                        px={4}
                        pb={2}
                        isAttached
                        size={"xs"}
                        variant={"outline"}
                        w={"full"}
                      >
                        <Button
                          w={"full"}
                          fontWeight={"normal"}
                          variant={tagFilterMode === "all" ? "solid" : "outline"}
                          colorScheme={tagFilterMode === "all" ? "blue" : "gray"}
                          onClick={() => handleTagFilterModeChange("all")}
                        >
                          All Tags
                        </Button>
                        <Button
                          w={"full"}
                          fontWeight={"normal"}
                          variant={tagFilterMode === "any" ? "solid" : "outline"}
                          colorScheme={tagFilterMode === "any" ? "blue" : "gray"}
                          onClick={() => handleTagFilterModeChange("any")}
                        >
                          Any Tags
                        </Button>
                      </ButtonGroup>
                      <VStack w={"100%"} px={4} spacing={0.5} alignItems={"start"}>
                        <Wrap pb={2} maxW={"400px"} spacing={1}>
                          {userTags.map((option) => (
                            <WrapItem key={option.label}>
                              <Tooltip label={option.tooltip} placement={"bottom-end"}>
                                <Button
                                  size={"xs"}
                                  colorScheme={
                                    selectedTags.includes(option.label) ? "blue" : "gray"
                                  }
                                  variant={
                                    selectedTags.includes(option.label) ? "solid" : "outline"
                                  }
                                  fontWeight={"normal"}
                                  onClick={() => handleTagSelection(option.label)}
                                >
                                  {option.label}
                                </Button>
                              </Tooltip>
                            </WrapItem>
                          ))}
                        </Wrap>

                        <Wrap pb={2} pt={1} maxW={"400px"} spacing={1}>
                          {orgTags.map((option) => (
                            <WrapItem key={option.label}>
                              <Tooltip label={option.tooltip} placement={"bottom-end"}>
                                <Button
                                  size={"xs"}
                                  colorScheme={
                                    selectedTags.includes(option.label) ? "blue" : "gray"
                                  }
                                  variant={
                                    selectedTags.includes(option.label) ? "solid" : "outline"
                                  }
                                  fontWeight={"normal"}
                                  onClick={() => handleTagSelection(option.label)}
                                >
                                  {option.label}
                                </Button>
                              </Tooltip>
                            </WrapItem>
                          ))}
                        </Wrap>
                      </VStack>
                    </MenuGroup>

                    {languages.length > 0 && (
                      <>
                        <MenuDivider />
                        <MenuOptionGroup title={"Language"} />
                        <MenuItemOption
                          type="checkbox"
                          key={"all"}
                          value={"all"}
                          isChecked={selectedLanguages.length === 0}
                          onClick={() => handleLanguageSelect([])}
                        >
                          All
                        </MenuItemOption>
                        <MenuOptionGroup
                          value={selectedLanguages}
                          type="checkbox"
                          onChange={(values) => handleLanguageSelect(values)}
                        >
                          {languages.map((language) => {
                            return (
                              <MenuItemOption key={language.code} value={language.code}>
                                {language.name}
                              </MenuItemOption>
                            );
                          })}
                        </MenuOptionGroup>
                      </>
                    )}
                    <MenuDivider />
                    <MenuGroup>
                      <Button
                        size={"sm"}
                        colorScheme={"gray"}
                        variant={"ghost"}
                        onClick={resetFilters}
                        mx={3}
                        fontWeight={"normal"}
                      >
                        Reset filters
                      </Button>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Tooltip label={"Toggle Mini-player"} placement={"bottom-end"}>
                <IconButton
                  size={"sm"}
                  aria-label={"Toggle Mini-player"}
                  icon={<FullScreenPlay theme="filled" />}
                  onClick={toggleMiniPlayer}
                  colorScheme={miniPlayer ? "green" : "gray"}
                />
              </Tooltip>
              {allowBulkActions && (
                <Box>
                  <Menu placement={"bottom-end"}>
                    <MenuButton
                      as={Button}
                      colorScheme={"gray"}
                      size={"sm"}
                      leftIcon={<CopyCheck size={16} />}
                      fontWeight={"normal"}
                      isDisabled={checkedItems.length === 0}
                      flexDir={"row"}
                      minW={"fit-content"}
                      onClick={() => handleAmplitudeTrack("Bulk Action", { Action: "Menu Open" })}
                      title={
                        checkedItems.length === 1
                          ? checkedItems.length + " Moment selected"
                          : checkedItems.length + " Moments selected"
                      }
                    >
                      <HStack gap={2}>
                        <Text>Actions</Text>
                        {checkedItems.length !== 0 && (
                          <Text
                            color={"gray.300"}
                            bg={"whiteAlpha.300"}
                            borderRadius={"full"}
                            px={1.5}
                            py={0.5}
                          >
                            {checkedItems.length}
                          </Text>
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList maxH={"60vh"} overflowY={"auto"} sx={{ scrollbarWidth: "thin" }}>
                      <MenuItem
                        onClick={() => {
                          setShowBulkDownloadConfirmation(true);
                          handleAmplitudeTrack("Bulk Action", {
                            Action: "Download Dialog",
                            Count: checkedItems.length,
                          });
                        }}
                        icon={<DownloadIcon />}
                      >
                        Download
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowBulkViewConfirmation(true);
                          handleAmplitudeTrack("Bulk Action", {
                            Action: "View Dialog",
                            Count: checkedItems.length,
                          });
                        }}
                        icon={<NewEfferent theme="outline" />}
                      >
                        View
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowBulkArchiveConfirmation(true);
                          handleAmplitudeTrack("Bulk Action", {
                            Action: "Archive Dialog",
                            Count: checkedItems.length,
                          });
                        }}
                        isDisabled={checkedItems.filter((m) => m.archivedAt === null).length === 0}
                        icon={<InboxIn theme="outline" />}
                      >
                        Archive
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowBulkUnarchiveConfirmation(true);
                          handleAmplitudeTrack("Bulk Action", {
                            Action: "Unarchive Dialog",
                            Count: checkedItems.length,
                          });
                        }}
                        isDisabled={checkedItems.filter((m) => m.archivedAt !== null).length === 0}
                        icon={<InboxOut theme="outline" />}
                      >
                        Unarchive
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </HStack>
          </HStack>
        </HStack>

        {tableData.length > 0 ? (
          totalElements > 0 ? (
            <>
              <TableContainer
                w={"100%"}
                p={4}
                pt={0}
                overflowY={"auto"}
                mt={"0 !important"}
                className={"moments-table campaign-discover"}
                justifyContent={"space-between"}
                h={"100%"}
                flex={1}
                alignContent={"flex-start"}
              >
                <Table size={"sm"} variant={"simple"}>
                  <Thead>
                    <Tr h={10}>
                      {allowBulkActions && (
                        <Th bg={"gray.800"} w={1} pr={0}>
                          <Checkbox
                            isDisabled={elements.length === 0}
                            isChecked={isPageSelected()}
                            isIndeterminate={!isPageUnselected() && !isPageSelected()}
                            onChange={handleSelectAll}
                          />
                        </Th>
                      )}
                      <Th
                        bg={"gray.800"}
                        onClick={() => handleSort("favorited")}
                        w={1}
                        pr={0}
                        px={2}
                      >
                        <HStack>
                          <Icon as={Star} size={16} theme={"filled"} mb={6} ml={4} />
                          <Box display="inline-block" width={4}>
                            {sortOption === "favorited" &&
                              (sortDirection === "asc" ? (
                                <TriangleUpIcon boxSize={3} />
                              ) : (
                                <TriangleDownIcon boxSize={3} />
                              ))}
                          </Box>
                        </HStack>
                      </Th>
                      <Th bg={"gray.800"} onClick={() => handleSort("features")} w={36} px={2}>
                        Features
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "features" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th bg={"gray.800"} onClick={() => handleSort("channel")} w={32} px={2}>
                        Channel
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "channel" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th
                        bg={"gray.800"}
                        onClick={() => handleSort("title")}
                        w={32}
                        maxW={64}
                        px={2}
                      >
                        Moment
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "title" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th bg={"gray.800"} onClick={() => handleSort("type")} w={28} px={2}>
                        Type
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "type" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>

                      <Th bg={"gray.800"} onClick={() => handleSort("date")} w={32} px={2}>
                        Date
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "date" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th
                        bg={"gray.800"}
                        onClick={() => handleSort("timestamp")}
                        maxW={28}
                        w={28}
                        px={2}
                      >
                        Timestamp
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "timestamp" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th bg={"gray.800"} onClick={() => handleSort("vod")} px={2}>
                        VOD
                        <Box display="inline-block" ml={1} width={4}>
                          {sortOption === "vod" &&
                            (sortDirection === "asc" ? (
                              <TriangleUpIcon boxSize={3} />
                            ) : (
                              <TriangleDownIcon boxSize={3} />
                            ))}
                        </Box>
                      </Th>
                      <Th bg={"gray.800"} w={32} px={2} pointerEvents={"none"}>
                        Tags
                      </Th>
                      <Th bg={"gray.800"} isNumeric w={32} cursor={"default"} px={2}>
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {elements.map((item, index) => (
                      <DiscoverMomentItem
                        key={index}
                        moment={item}
                        fetchSearches={fetchSearches}
                        miniPlayer={miniPlayer}
                        selectedMoment={selectedMoment}
                        setSelectedMoment={setSelectedMoment}
                        features={features}
                        isChecked={checkedItems.map((m) => m.id).includes(item.id)}
                        handleCheckboxSelect={handleMomentCheckboxSelect}
                        handleDownload={handleMomentDownload}
                        isDownloading={isDownloading}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
              <Text fontSize="md" color="gray.300" textAlign="center">
                No moments match the selected filters
              </Text>
            </HStack>
          )
        ) : (
          <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
            <Text fontSize="md" color="gray.300" textAlign="center">
              No moments in the selected campaign
            </Text>
          </HStack>
        )}

        <HStack
          py={4}
          bg={"gray.800"}
          mt={"0 !important"}
          pos={"sticky"}
          bottom={0}
          w={"100%"}
          borderTopWidth={1}
          zIndex={1}
        >
          <HStack w={"100%"} justifyContent={"center"}>
            <HStack w={"fit-content"}>
              <Text fontSize={"sm"} color={"gray.400"}>
                Showing
              </Text>
              <Select
                size={"sm"}
                borderRadius={"md"}
                color={"gray.400"}
                value={pageSize}
                minW={"max-content"}
                variant={"outline"}
                onChange={(e) => handlePageSize(e.target.value)}
              >
                <option key={10} value={10}>
                  10
                </option>
                <option key={20} value={20}>
                  20
                </option>
                <option key={30} value={30}>
                  30
                </option>
                <option key={40} value={40}>
                  40
                </option>
                <option key={50} value={50}>
                  50
                </option>
              </Select>
            </HStack>
            <IconButton
              title={"First page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"First page"}
              icon={<DoubleLeft />}
              onClick={() => setPage(1)}
              disabled={page === 1}
            />
            <IconButton
              title={"Previous page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Previous page"}
              icon={<Left />}
              onClick={previousPage}
              disabled={!hasPreviousPage}
            />
            <HStack px={2}>
              <Text fontSize={"sm"} color={"gray.400"}>
                Page
              </Text>
              <Input
                className={"amp-unmask"}
                textAlign={"right"}
                textColor={"gray.400"}
                value={page}
                size={"sm"}
                maxW={14}
                borderRadius={"md"}
                type={"number"}
                onChange={(e) => {
                  setPage(Number(e.target.value));
                }}
              />
              <Text fontSize={"sm"} color={"gray.400"}>{` of ${totalPages}`}</Text>
            </HStack>
            <IconButton
              title={"Next page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Next page"}
              icon={<Right />}
              onClick={nextPage}
              disabled={!hasNextPage}
            />
            <IconButton
              title={"Last page"}
              size={"sm"}
              variant={"outline"}
              aria-label={"Last page"}
              icon={<DoubleRight />}
              onClick={() => setPage(totalPages)}
              disabled={page === totalPages}
            />
          </HStack>
        </HStack>

        {miniPlayer && selectedMoment && videoInfo && (
          <MiniPlayer
            key={selectedMoment.id}
            timestamp={selectedMoment.timestamp}
            videoInfo={videoInfo}
            resultsLink={`${RivrLocations.Results}/${selectedMoment.searchId}?${updateURLParams(
              searchParams.toString(),
              [
                [ResultsURLParams.SelectedMoment, selectedMoment.id],
                [ResultsURLParams.SelectedTimestamp, ""],
                [ResultsURLParams.SelectedPanel, ""],
              ]
            )}`}
            toggleVisibility={() => setSelectedMoment(null)}
            playerScale={playerScale}
            setPlayerScale={setPlayerScale}
            handleFavorite={() => handleFavorite(selectedMoment)}
            handleArchive={(date) => handleArchive(selectedMoment, date)}
            handleDownload={() => {
              handleMomentDownload(
                [
                  {
                    id: selectedMoment.id,
                    search_id: selectedMoment.searchId,
                    title: selectedMoment.title,
                    tags: selectedMoment.tags,
                    start_time: durationToSeconds(selectedMoment.timestamp),
                    end_time: durationToSeconds(selectedMoment.timestamp) + selectedMoment.length,
                    deleted: false,
                    created_at: new Date(selectedMoment.createdAt),
                    updated_at: new Date(selectedMoment.updatedAt),
                    recommended_moment_id: undefined,
                    annotations: selectedMoment.annotations,
                  },
                ],
                `${selectedMoment.searchId}`
              );
              handleAmplitudeTrack("Download Single Moment", {
                MomentId: selectedMoment.id,
                MomentTitle: selectedMoment.title,
              });
            }}
            moment={selectedMoment}
          />
        )}
      </VStack>
    </>
  );
};

export default CampaignDiscoverMoments;
