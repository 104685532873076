import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  HStack,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";

import PlanCard from "../PlanCard/PlanCard";
import MockSearchbar from "./MockSearchbar";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { BookMarked } from "lucide-react";
import { RivrLocations } from "../../models/navigation";
import creatorImages from "../../assets/creators/creators";

const ConvertSearchHeader = () => {
  return (
    <HStack
      w={"100%"}
      maxW={"1060px"}
      justify={"center"}
      flexDirection={{ base: "column", md: "row" }}
    >
      <VStack
        w={"100%"}
        p={4}
        alignItems={{ base: "center", md: "flex-start" }}
        textAlign={{ base: "center", md: "left" }}
        gap={4}
      >
        <VStack
          w={"100%"}
          alignItems={{ base: "center", md: "flex-start" }}
          textAlign={{ base: "center", md: "left" }}
          gap={0}
        >
          <Text sx={{ textWrap: "balance" }} fontSize={"4xl"} fontWeight={"semibold"}>
            Create. Share. Grow.
          </Text>
          <Text color={"gray.300"} sx={{ textWrap: "balance" }} fontSize={"md"}>
            Transform your streams into memorable Moments that drive growth.
          </Text>
        </VStack>
        <MockSearchbar />
        <SlideFade in={true} offsetY={16} transition={{ enter: { duration: 0.5, delay: 0.5 } }}>
          <VStack align={"start"} gap={1.5} pt={4}>
            <Text color={"gray.300"} fontSize={"md"}>
              Join 100s of creators, just like you.
            </Text>
            <HStack justifyContent={{ base: "center", md: "start" }} w={"100%"}>
              <AvatarGroup spacing={-2.5} borderColor={"whiteAlpha.200"}>
                {creatorImages.map((image, index) => (
                  <Avatar boxSize={9} key={index} src={image} />
                ))}
              </AvatarGroup>
            </HStack>
          </VStack>
        </SlideFade>
        <SlideFade in={true} offsetY={16} transition={{ enter: { duration: 0.5, delay: 0.75 } }}>
          <VStack
            w={"100%"}
            align={"flex-start"}
            gap={2}
            pt={{ base: 0, md: 4 }}
            alignItems={{ base: "center", md: "flex-start" }}
          >
            <Text color={"gray.300"} fontSize={"sm"}>
              Need help or have a question?
            </Text>
            <HStack>
              <Button
                size={"sm"}
                colorScheme={"blue"}
                leftIcon={<BookMarked size={14} />}
                as={"a"}
                target={"_blank"}
                href={RivrLocations.KnowledgeBase}
              >
                Knowledge base
              </Button>
              <Button
                size={"sm"}
                colorScheme={"purple"}
                rightIcon={<ExternalLinkIcon />}
                as={"a"}
                href={"https://discord.gg/WTkWJKzyQK"}
                target={"_blank"}
              >
                Discord
              </Button>
            </HStack>
          </VStack>
        </SlideFade>
      </VStack>
      <Flex p={4} w={"100%"} maxW={"28rem"} justify={"center"}>
        <PlanCard
          planType="EA"
          planAvailable={true}
          showSubscribe={true}
          subscriptionExists={false}
        />
      </Flex>
    </HStack>
  );
};

export default ConvertSearchHeader;
