import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { RivrPlayer } from "./RivrPlayer/RivrPlayer";
import { TwitchRivrPlayer } from "./TwitchRivrPlayer/TwitchRivrPlayer";
import { YouTubeRivrPlayer } from "./YouTubeRivrPlayer/YouTubeRivrPlayer";
import { originTwitch, originYouTube } from "../../models/urls";
import { VideoInfo } from "./PlayerProps";

type Props = {
  videoInfo: VideoInfo;
  seekTime: { seconds: number } | null;
  pausePlayer: { pause: boolean };
  updatePlayTime: (seconds: number) => void;
  onError?: (error: any) => void;
};

export const GenericPlayer = (props: Props) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime, onError } = props;
  const { getBooleanFlag } = useKindeAuth();
  const useCustomPlayer = getBooleanFlag("custom-player", false);

  const Player = useCustomPlayer
    ? RivrPlayer
    : videoInfo.originType === originTwitch
    ? TwitchRivrPlayer
    : videoInfo.originType === originYouTube
    ? YouTubeRivrPlayer
    : null;

  return (
    Player && (
      <Player
        seekTime={seekTime}
        pausePlayer={pausePlayer}
        videoInfo={videoInfo}
        updatePlayTime={updatePlayTime}
        onError={onError}
      />
    )
  );
};
