import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { DownloadsContext } from "./DownloadsContext";
import { Action, defaultAction } from "../models/action";
import { Moment } from "../models/moment";
import { ApolloError, useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { ClipRequestMutation } from "../api/actions";
import { ClipRequestsByIDQuery, ClipRequestsBySearchIDQuery } from "../api/clip-request";
import _ from "lodash";
import { useToast } from "@chakra-ui/react";
import { VerticalClip } from "../components/VerticalFormatModal/VerticalFormatModal";
import { useInterval } from "../hooks/useInterval";
import { InsertUserClipRequestMutation } from "../api/user-clip-request";
import { AccountContext } from "./AccountContext";
import { logApolloErrorsHandler } from "../utils/graphql-error";

type ClipRequest = {
  requestId: number;
  momentId: string;
};

export const DownloadsProvider = ({ children }: PropsWithChildren) => {
  const client = useApolloClient();
  const toast = useToast();

  const { account } = useContext(AccountContext);

  const [getClipResults, { data }] = useLazyQuery(ClipRequestsByIDQuery);

  const [userClipRequestInsertAPI] = useMutation(InsertUserClipRequestMutation, {
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
    },
  });

  const [downloads, setDownloads] = useState<ClipRequest[]>([]);
  const [downloadTotal, setDownloadTotal] = useState(0);
  const [downloadDone, setDownloadDone] = useState(0);
  // Clips for vertical reformatting within the modal
  const [verticalFormatClipRequests, setVerticalFormatClipRequests] = useState<ClipRequest[]>([]);
  // Clips to be downloaded in vertical format
  const [verticalFormatClips, setVerticalFormatClips] = useState<VerticalClip[]>([]);

  const clipRequestTimeoutSeconds = 300;
  const resultFetchInterval = 2000;
  const downloadInterval = 2000;

  const downloadProgress = (downloadDone / downloadTotal) * 100;

  const downloadFile = (url: string) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);

    // Remove the iframe after the download
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  };

  const downloaderRequest = async (
    moments: Moment[],
    searchId: string,
    actions: Action[] = [defaultAction],
    forVerticalFormat = false
  ) => {
    if (moments.length === 0) return;

    const response = await client.query({
      query: ClipRequestsBySearchIDQuery,
      variables: {
        id: Number(searchId),
      },
    });
    const clipRequests: any = response && response.data ? response.data.clip_request : [];

    const found: ClipRequest[] = [];
    const notFound: any = [];
    moments.forEach((moment) => {
      actions.forEach((action) => {
        const requests = clipRequests.filter(
          (request: any) =>
            request.moment?.start_time === moment.start_time &&
            request.moment?.end_time === moment.end_time &&
            request.moment?.title === moment.title &&
            _.isEqual(request.action?.type?.config, action.type.config)
        );
        let requestId = null;
        for (const request of requests) {
          if (request.clip_results.length === 0) {
            if (
              new Date().getTime() - new Date(request.created_at).getTime() <
              clipRequestTimeoutSeconds * 1000
            ) {
              requestId = request.id;
              break;
            }
          } else if (new Date(request.clip_results[0].expires_at) > new Date()) {
            requestId = request.id;
            break;
          }
        }
        if (requestId) {
          found.push({ requestId, momentId: moment.id });
        } else {
          delete (action as any).__typename;
          notFound.push({ moment, action });
        }
      });
    });
    if (notFound.length > 0) {
      const insertResponse = await client.query({
        query: ClipRequestMutation,
        variables: { requests: notFound },
      });
      if (insertResponse && insertResponse.data) {
        insertResponse.data.clipRequest.forEach((element: any) => {
          found.push({ requestId: element.id, momentId: element.moment_id });
        });
      } else {
        if (insertResponse.errors && insertResponse.errors.length > 0) {
          console.log("Insertion Failed", insertResponse.errors[0].message);
        } else {
          console.log("Insertion Failed");
        }
      }
    }

    setDownloads((downloads) => [...downloads, ...found]);

    if (forVerticalFormat)
      setVerticalFormatClipRequests((verticalFormatClipRequests) => [
        ...verticalFormatClipRequests,
        ...found,
      ]);
    else {
      setDownloadTotal((downloadTotal) => downloadTotal + found.length);
      // Add user-request to the clip request table
      found.forEach((element) => {
        userClipRequestInsertAPI({
          variables: {
            account_id: account.id,
            clip_request_id: element.requestId,
            requested_at: new Date(Date.now()),
          },
        });
      });
    }
  };

  const isMomentDownloading = (momentId: string) => {
    return (
      downloads.some((d) => d.momentId === momentId) ||
      verticalFormatClipRequests.some((v) => v.momentId === momentId)
    );
  };

  useInterval(
    () => {
      getClipResults({ variables: { ids: downloads.map((d) => d.requestId) } });
    },
    downloads.length ? resultFetchInterval : null
  );

  const staggeredDownload = (urls: string[]) => {
    let index = 0;

    const downloadNext = () => {
      if (index < urls.length) {
        downloadFile(urls[index]);
        index += 1;
        setDownloadDone((downloadsDone) => downloadsDone + 1);
        setTimeout(downloadNext, downloadInterval);
      }
    };

    downloadNext();
  };

  useEffect(() => {
    if (data) {
      let newVerticalFormatClips = verticalFormatClips;
      const found: number[] = [];
      const forDownload: string[] = [];
      data.clip_request.forEach((element: any) => {
        if (!downloads.some((d) => d.requestId === element.id)) return;
        if (element.clip_results.length > 0) {
          const verticalFormatClip = verticalFormatClipRequests.find(
            (r) => r.requestId === element.id
          );
          if (verticalFormatClip)
            newVerticalFormatClips = newVerticalFormatClips.map((c) =>
              c.momentId === verticalFormatClip.momentId
                ? { ...c, url: element.clip_results[0].url }
                : c
            );
          else forDownload.push(element.clip_results[0].url);
          found.push(element.id);
        } else if (
          new Date().getTime() - new Date(element.created_at).getTime() >
          clipRequestTimeoutSeconds * 1000
        ) {
          const moment = element.moment?.title ? ` "${element.moment.title}"` : "";
          toast({
            title: `Error while downloading ${moment}`,
            description: "Please try again",
            status: "error",
            isClosable: true,
          });
          found.push(element.id);
        }
      });
      const newDownloads = downloads.filter((d) => !found.includes(d.requestId));
      setDownloads(newDownloads);
      if (!newDownloads.length) {
        setDownloadTotal(0);
        setDownloadDone(0);
      }
      setVerticalFormatClipRequests(
        verticalFormatClipRequests.filter((r) => !found.includes(r.requestId))
      );
      setVerticalFormatClips(newVerticalFormatClips);

      if (forDownload.length > 0) {
        staggeredDownload(forDownload);
      }
    }
  }, [data]);

  return (
    <DownloadsContext.Provider
      value={{
        downloadDone,
        downloadTotal,
        downloadProgress,
        downloaderRequest,
        verticalFormatClips,
        setVerticalFormatClips,
        isMomentDownloading,
      }}
    >
      {children}
    </DownloadsContext.Provider>
  );
};
