import { PropsWithChildren, useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AccountContext } from "../context/AccountContext";
import { useLazyQuery, useMutation, useQuery, NetworkStatus, ApolloError } from "@apollo/client";
import {
  AccountByKindeIdQuery,
  CreateAccountMutation,
  UpdateAccountMutation,
} from "../api/account";
import { KindLoading } from "../components/KindLoading/KindLoading";
import { HasuraError } from "../components/HasuraError/HasuraError";
import { CompleteTutorialMutation } from "../api/tutorial-progress";
import {
  OrganizationMembership,
  OrganizationRole,
  orgFromQueryResult,
} from "../models/rivrOrganization";
import { OrganizationsQuery, OrganizationsByIdQuery } from "../api/organization";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";
import { logApolloErrorsHandler } from "../utils/graphql-error";
import { UserGroupByAccountQuery } from "../api/user-group";
import { UserGroupMember } from "../models/groups/userGroupMember";
import { AccountByKindeIdQuery as AccountType } from "../gql/graphql";

export const AccountProvider = ({ children }: PropsWithChildren) => {
  const { user } = useKindeAuth();
  const toast = useToast();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const [account, setAccount] = useState<AccountType["account"][0] | null>(null);
  const [memberships, setMemberships] = useState<OrganizationMembership[]>([]);
  const [groups, setUserGroup] = useState<Map<string, string>>(new Map<string, string>());

  const accountQuery = useQuery(AccountByKindeIdQuery, {
    variables: { kinde_id: user?.id as string },
    notifyOnNetworkStatusChange: true,
  });

  const [groupsQuery] = useLazyQuery(UserGroupByAccountQuery, {
    onCompleted(data: { user_group_member: UserGroupMember[] }) {
      const map = data.user_group_member.reduce(
        (acc, member) => acc.set(member.user_group.org_id, member.user_group_id),
        new Map<string, string>()
      );
      setUserGroup(map);
    },
    onError: logApolloErrorsHandler,
    variables: { account_id: account?.id },
  });

  const [organizationsQuery] = useLazyQuery(OrganizationsByIdQuery, {
    onCompleted(data) {
      setMemberships(
        data.organization
          .map((o) => {
            const membership = account?.memberships.find((m) => m.organization_id === o.id);
            return membership !== undefined
              ? {
                  organization: orgFromQueryResult(o),
                  role: membership.role as OrganizationRole,
                }
              : { organization: orgFromQueryResult(o), role: "owner" as OrganizationRole };
          })
          .sort((a, b) => {
            if (a.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return -1;
            } else if (b.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return 1;
            }
            return a.organization.name.localeCompare(b.organization.name);
          })
      );
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });
  const [adminOrganizationsQuery] = useLazyQuery(OrganizationsQuery, {
    onCompleted(data) {
      setMemberships(
        data.organization
          .map((o) => {
            const membership = account?.memberships.find((m) => m.organization_id === o.id);
            return membership !== undefined
              ? {
                  organization: orgFromQueryResult(o),
                  role: membership.role as OrganizationRole,
                }
              : { organization: orgFromQueryResult(o), role: "owner" as OrganizationRole };
          })
          .sort((a, b) => {
            if (a.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return -1;
            } else if (b.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return 1;
            }
            return a.organization.name.localeCompare(b.organization.name);
          })
      );
    },
    onError: logApolloErrorsHandler,
  });
  const [createAccount, accountMutation] = useMutation(CreateAccountMutation, {
    variables: { kinde_id: user?.id as string, email: user?.email },
  });
  const [finishTutorial] = useMutation(CompleteTutorialMutation);

  const [accountUpdateAPI] = useMutation(UpdateAccountMutation, {
    onCompleted() {
      accountQuery.refetch();
    },
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error, () => {
        toast({
          title: "Error updating account",
          description: "There was an error while updating your account settings.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    },
  });

  const refreshOrg = () => {
    if (isAdmin) adminOrganizationsQuery();
    else if (accountQuery.data && accountQuery.data.account.length > 0)
      organizationsQuery({
        variables: {
          ids: accountQuery.data.account[0].memberships.map((m: any) => m.organization_id),
        },
      });
  };

  useEffect(() => {
    if (accountQuery.data?.account.length === 0) createAccount();
    else if (accountQuery.data && accountQuery.data.account.length > 0) {
      setAccount(accountQuery.data.account[0]);
      groupsQuery({ variables: { account_id: accountQuery.data.account[0].id } });
      refreshOrg();
    }
  }, [accountQuery.data]);

  useEffect(() => {
    if (accountMutation.data?.insert_account_one)
      setAccount(accountMutation.data.insert_account_one);
  }, [accountMutation.data]);

  const completeTutorial = (tutorial: string) => {
    if (account) {
      setAccount({ ...account, tutorial_progress: [...account.tutorial_progress, { tutorial }] });
      finishTutorial({ variables: { account_id: account.id, tutorial } });
    }
  };

  return account ? (
    <AccountContext.Provider
      value={{
        account,
        memberships,
        groups,
        completeTutorial,
        accountUpdateAPI,
        refreshAccount: accountQuery.refetch,
        refreshOrg,
        accountLoading: accountQuery.networkStatus === NetworkStatus.refetch,
      }}
    >
      {children}
    </AccountContext.Provider>
  ) : accountQuery.error || accountMutation.error ? (
    <HasuraError />
  ) : (
    <KindLoading />
  );
};
