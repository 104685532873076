import { useMemo } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  HStack,
  Divider,
  Flex,
  Thead,
  Table,
  Tbody,
  TableContainer,
  Tr,
  Th,
  useBreakpointValue,
  Td,
  Alert,
  AlertIcon,
  AlertDescription,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { OrganizationMembersByIdQuery } from "../../../api/organization";
import { Loading } from "../../Loading/Loading";
import { MoreOne } from "@icon-park/react";
import { logApolloErrorsHandler } from "../../../utils/graphql-error";
import { OrgGroupsQuery } from "../../../api/user-group";

type Props = {
  orgId: string;
};

export const OrgTeam = (props: Props) => {
  const { orgId } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { data: members } = useQuery(OrganizationMembersByIdQuery, {
    onError: logApolloErrorsHandler,
    variables: { org_id: orgId },
    context: {
      role: "org-owner",
    },
  });
  const { data: groups } = useQuery(OrgGroupsQuery, {
    onError: logApolloErrorsHandler,
    variables: { org_id: orgId },
  });

  const memberGroups = useMemo(() => {
    if (!members?.organization_member || !groups?.user_group) return {};
    const mGroups: any = {};
    for (const member of members.organization_member) mGroups[member.account.id] = [];
    for (const group of groups.user_group)
      for (const gMember of group.user_group_members) mGroups[gMember.account_id]?.push(group.name);
    for (const accountId in mGroups) mGroups[accountId].sort();
    return mGroups;
  }, [members, groups]);

  const sortOrgMembers = (a: any, b: any) => {
    // Sorts org members by role, then by name
    const rolePriority: Record<string, number> = {
      owner: 1,
      member: 2,
    };
    if (a.role === b.role) {
      // Sort empty names to the end
      if (!a.account.name) return 1;
      else if (!b.account.name) return -1;
      else return a.account.name.localeCompare(b.account.name);
    } else return rolePriority[a.role] - rolePriority[b.role];
  };

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} gap={12} pt={6}>
        <HStack
          alignItems={"center"}
          w={"100%"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          rowGap={6}
          gap={0}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as={"h1"} fontSize={"3xl"}>
              Teams
            </Heading>
            <Text color={"gray.300"}>
              Invite people to your organization and assign roles and teams.
            </Text>
          </VStack>
          <Alert status={"warning"} borderRadius={"md"} w={"fit-content"}>
            <AlertIcon />
            <AlertDescription>
              This feature is currently in development. To add, remove, or change the roles or teams
              of organization members, please contact your account manager.
            </AlertDescription>
          </Alert>
        </HStack>

        <Divider />

        <Flex w={"100%"} justify={"center"}>
          {!members ? (
            <Loading />
          ) : (
            <TableContainer w={"100%"}>
              <VStack alignItems={"flex-start"} gap={1} pb={6}>
                <Text fontSize={"lg"}>Members</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  Manage team member roles and teams.
                </Text>
              </VStack>

              <Table size={"sm"} variant={"striped"}>
                <Thead>
                  <Tr>
                    <Th cursor={"default"}>Member</Th>
                    {!isMobile && (
                      <>
                        <Th cursor={"default"}>Email</Th>
                        <Th cursor={"default"}>Role</Th>
                        <Th cursor={"default"}>Team</Th>
                      </>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {members.organization_member
                    .sort(sortOrgMembers)
                    .map((member: any, index: number) => (
                      <Tr key={index}>
                        <Td>
                          <VStack align={"start"} gap={1}>
                            <Text>{member.account.name}</Text>
                            {isMobile && (
                              <>
                                <Text fontSize={"xs"} color={"gray.300"}>
                                  Email: {member.account.email}
                                </Text>
                                <HStack gap={1}>
                                  <Text fontSize={"xs"} color={"gray.300"}>
                                    Role:{" "}
                                  </Text>
                                  <Badge
                                    fontSize={"xs"}
                                    letterSpacing={"wider"}
                                    textTransform={"capitalize"}
                                    fontWeight={"normal"}
                                  >
                                    {member.role}
                                  </Badge>
                                </HStack>
                                <Text fontSize={"xs"} color={"gray.300"}>
                                  Team: {memberGroups[member.account.id]?.join(", ")}
                                </Text>
                              </>
                            )}
                          </VStack>
                        </Td>
                        {!isMobile && (
                          <>
                            <Td>{member.account.email}</Td>
                            <Td>
                              <Badge
                                fontWeight={"normal"}
                                letterSpacing={"wider"}
                                textTransform={"capitalize"}
                              >
                                {member.role}
                              </Badge>
                            </Td>
                            <Td>{memberGroups[member.account.id]?.join(", ")}</Td>
                          </>
                        )}
                        <Td isNumeric>
                          <IconButton
                            size={"sm"}
                            variant={"ghost"}
                            aria-label={"Team member options"}
                            icon={<MoreOne size={24} />}
                            isDisabled
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};
