import {
  Box,
  VStack,
  Heading,
  Text,
  HStack,
  Divider,
  Button,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { OrganizationMembership } from "../../../models/rivrOrganization";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../../models/navigation";
import OrgEdit from "./OrgEdit";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { Check, Save, Undo } from "@icon-park/react";
import { ApolloError, useMutation } from "@apollo/client";
import { UpdateOrganizationMutation } from "../../../api/organization";
import { logApolloErrorsHandler } from "../../../utils/graphql-error";

const Organization = () => {
  const { memberships, refreshOrg } = useContext(AccountContext);
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const {
    isOpen: isSaveConfirmOpen,
    onOpen: openSaveConfirm,
    onClose: closeSaveConfirm,
  } = useDisclosure();
  const cancelSaveConfirmRef = useRef(null);

  const [image, setImage] = useState(selectedMembership?.organization.image);
  const [name, setName] = useState(selectedMembership?.organization.name);
  const [website, setWebsite] = useState(selectedMembership?.organization.website);
  const [email, setEmail] = useState(selectedMembership?.organization.email);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [orgUpdateAPI] = useMutation(UpdateOrganizationMutation, {
    onCompleted() {
      setIsLoading(false);
      refreshOrg();
      toast({
        title: "Organization updated",
        description: `${name} was successfully updated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      toast({
        title: "Error updating organization",
        description: "There was an error encountered while updating the organization.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const isDataSaved = useMemo(() => {
    if (image !== selectedMembership?.organization.image) {
      return false;
    }
    if (name !== selectedMembership?.organization.name) {
      return false;
    }
    if (website !== selectedMembership?.organization.website) {
      return false;
    }
    if (email !== selectedMembership?.organization.email) {
      return false;
    }
    return true;
  }, [image, name, website, email, selectedMembership]);

  const handleSave = () => {
    setIsLoading(true);
    const orgUpdates: { [key: string]: any } = {};
    if (image !== selectedMembership?.organization.image) {
      orgUpdates["image"] = image;
    }
    if (name !== selectedMembership?.organization.name) {
      orgUpdates["name"] = name;
    }
    if (website !== selectedMembership?.organization.website) {
      orgUpdates["website"] = website !== "" ? website : null;
    }
    if (email !== selectedMembership?.organization.email) {
      orgUpdates["email"] = email !== "" ? email : null;
    }
    if (Object.keys(orgUpdates).length !== 0) {
      orgUpdateAPI({
        variables: { id: selectedMembership?.organization?.id, updates: orgUpdates },
      });
    }
    setIsLoading(false);
  };

  const handleReset = () => {
    setImage(selectedMembership?.organization.image);
    setName(selectedMembership?.organization.name);
    setWebsite(selectedMembership?.organization.website);
    setEmail(selectedMembership?.organization.email);
  };

  useEffect(() => {
    handleReset();
  }, [selectedMembership]);

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isSaveConfirmOpen}
        leastDestructiveRef={cancelSaveConfirmRef}
        onClose={closeSaveConfirm}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Confirm organization changes</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to make changes to the organization?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button variant={"ghost"} ref={cancelSaveConfirmRef} onClick={closeSaveConfirm}>
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme={"green"}
                onClick={() => {
                  handleSave();
                  closeSaveConfirm();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
        <VStack alignItems={"flex-start"} spacing={12} pt={6}>
          <HStack
            alignItems="center"
            w="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            spacing={0}
          >
            <VStack alignItems={"flex-start"}>
              <Heading as={"h1"} fontSize={"3xl"}>
                Organization
              </Heading>
              <Text color={"gray.300"}>View and manage your organization.</Text>
            </VStack>

            {selectedMembership && selectedMembership.role === "owner" && (
              <HStack>
                {!isDataSaved && !isLoading && (
                  <IconButton
                    colorScheme={"red"}
                    variant={"outline"}
                    icon={<Undo />}
                    onClick={() => handleReset()}
                    aria-label={"Revert changes"}
                  />
                )}
                <Button
                  colorScheme={"green"}
                  variant={isDataSaved ? "outline" : "solid"}
                  minW={"fit-content"}
                  leftIcon={
                    isDataSaved ? (
                      <Check />
                    ) : isLoading ? (
                      <Spinner size={"sm"} />
                    ) : (
                      <Save theme={"outline"} />
                    )
                  }
                  onClick={openSaveConfirm}
                  disabled={isDataSaved || isLoading || name === ""}
                >
                  {isDataSaved ? "Saved" : isLoading ? "Saving" : "Save"}
                </Button>
              </HStack>
            )}
          </HStack>

          <Divider />

          <VStack alignItems="flex-start" w="100%" spacing={6}>
            {selectedMembership && (
              <>
                <OrgEdit
                  selectedMembership={selectedMembership}
                  name={name ?? ""}
                  setName={setName}
                  image={image ?? ""}
                  setImage={setImage}
                  website={website ?? ""}
                  setWebsite={setWebsite}
                  email={email ?? ""}
                  setEmail={setEmail}
                />
              </>
            )}
          </VStack>
        </VStack>
      </Box>
    </>
  );
};
export default Organization;
