import { Tag, Tooltip } from "@chakra-ui/react";
import { recommendedMomentSettings } from "../../../../models/moment";
import { memo } from "react";

interface Props {
  feature: string;
  active: boolean;
  hyperActive: boolean;
}

export const MomentFeatureTag = memo(({ feature, active, hyperActive }: Props) => {
  return (
    <Tooltip label={(recommendedMomentSettings as any)[feature].title}>
      <Tag
        colorScheme={(recommendedMomentSettings as any)[feature].colorScheme}
        variant={"subtle"}
        size={"xl"}
        py={0.5}
        borderRadius={"sm"}
        minW={"24px"}
        justifyContent={"center"}
        opacity={active ? 1 : 0.2}
        className={hyperActive ? "hyper-hype" : ""}
      >
        {(recommendedMomentSettings as any)[feature].icon}
      </Tag>
    </Tooltip>
  );
});
