export type PlanType = "EA" | "Starter" | "Pro" | "Enterprise";

type Price = {
  regular: string;
  previous?: string;
};

type Variant = {
  price: Price;
  url: string;
  variantId: string;
};

type Variants =
  | {
      monthly?: Variant;
      yearly: Variant;
    }
  | {
      monthly: Variant;
      yearly?: Variant;
    }
  | {
      monthly: Variant;
      yearly: Variant;
    };

type Feature = {
  name: string;
  tooltip?: string;
};

type Plan = Variants & {
  badgeColor: string;
  borderColor: string;
  planHeader?: string;
  planName: string;
  planNameSub?: string;
  features: Feature[];
  productId: string;
};

export const planDetails: Record<PlanType, Plan> = {
  EA: {
    badgeColor: "green",
    borderColor: "green.300",
    planName: "Starter",
    planNameSub: "Early Access",
    monthly: {
      price: { regular: "$18" },
      url: process.env.REACT_APP_USER_MONTHLY_SUBSCRIPTION_URL as string,
      variantId: process.env.REACT_APP_EA_MONTHLY_VARIANT_ID as string,
    },
    yearly: {
      price: { regular: "$180" },
      url: process.env.REACT_APP_USER_YEARLY_SUBSCRIPTION_URL as string,
      variantId: process.env.REACT_APP_EA_YEARLY_VARIANT_ID as string,
    },
    features: [
      { name: "Analyze Twitch VODs", tooltip: "Any length, up to 3 simultaneously" },
      {
        name: "Unlimited Automatic Highlights",
        tooltip: "Get instant Moment recommendations based on Hype, Chat activity, and Sentiment",
      },
      { name: "Unlimited Clip Downloads & Storage" },
      { name: "Unlimited Vertical Reformatting" },
      { name: "Chat & Speech Search", tooltip: "Find anything said by you or your community" },
      {
        name: "Creator Opportunities",
        tooltip: "Spotlights, promotions, and opportunties to work with brand partners",
      },
      {
        name: "Live Chat Support",
      },
    ],
    productId: process.env.REACT_APP_EA_PRODUCT_ID as string,
  },
  Starter: {
    badgeColor: "green",
    borderColor: "green.300",
    planName: "Starter",
    monthly: {
      price: { regular: "$30" },
      url: "",
      variantId: "",
    },
    yearly: {
      price: { regular: "$300" },
      url: "",
      variantId: "",
    },
    features: [
      { name: "Analyze Twitch VODs", tooltip: "Any length, up to 3 simultaneously" },
      {
        name: "Unlimited Automatic Highlights",
        tooltip: "Get instant Moment recommendations based on Hype, Chat activity, and Sentiment",
      },
      { name: "Unlimited Clip Downloads & Storage" },
      { name: "Unlimited Vertical Reformatting" },
      { name: "Chat & Speech Search", tooltip: "Find anything said by you or your community" },
      {
        name: "Creator Opportunities",
        tooltip: "Spotlights, promotions, and opportunties to work with brand partners",
      },
      {
        name: "Live Chat Support",
      },
    ],
    productId: "",
  },
  Pro: {
    badgeColor: "yellow",
    borderColor: "yellow.300",
    planHeader: "Coming soon",
    planName: "Pro",
    monthly: {
      price: { regular: "$45" },
      url: "",
      variantId: "",
    },
    yearly: {
      price: { regular: "$450" },
      url: "",
      variantId: "",
    },
    features: [
      { name: "Everything in Starter" },
      { name: "Automatic VOD analyzing" },
      { name: "90-day VOD retention" },
      { name: "Pro Discord role" },
      { name: "Early access to beta features" },
    ],
    productId: "",
  },
  Enterprise: {
    badgeColor: "green",
    borderColor: "green.300",
    planHeader: "Limited time, early access pricing",
    planName: "Rivr Create",
    monthly: {
      price: { regular: "$297" },
      url: process.env.REACT_APP_ORG_SUBSCRIPTION_URL as string,
      variantId: process.env.REACT_APP_ENTERPRISE_MONTHLY_VARIANT_ID as string,
    },
    features: [
      { name: "Unlimited Campaigns" },
      { name: "Unlimited Channels" },
      {
        name: "100 video hours included per month",
        tooltip: "Additional video hours billed monthly.",
      },
      { name: "Moment Discovery", tooltip: "Find great Moments across your campaigns." },
      {
        name: "Mention Discovery",
        tooltip: "Find keyword and brand mentions across your campaigns.",
      },
      {
        name: "2 Organization seats",
        tooltip: "Additional seats available at $20 per seat.",
      },
      { name: "Priority Support" },
      { name: "Dedicated Account Manager" },
      { name: "Early access to new features" },
    ],
    productId: process.env.REACT_APP_ENTERPRISE_PRODUCT_ID as string,
  },
};

type ChosenPlan = {
  planType: PlanType;
  planDetails: Plan;
  planVariant: "monthly" | "yearly";
};

export const getPlanByIds = (productId: string, variantId: string): ChosenPlan | undefined => {
  for (const plan of Object.entries(planDetails)) {
    if (
      plan[1].productId === productId &&
      (plan[1].monthly?.variantId === variantId || plan[1].yearly?.variantId === variantId)
    ) {
      return {
        planType: plan[0] as PlanType,
        planDetails: plan[1],
        planVariant: plan[1].monthly?.variantId === variantId ? "monthly" : "yearly",
      };
    }
  }
};
