import { useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr, Link, TableContainer } from "@chakra-ui/react";
import { AccessKey } from "../../models/keyManager";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { formatDateForDisplay } from "../../utils/time";

interface KeyTableProps {
  data: AccessKey[] | null;
  twitchUsernames: { [key: string]: string | null };
  viewFilter: "all" | "claimed" | "unclaimed";
  keysClaimed: number;
  keysUnclaimed: number;
}

const KeyTable = ({ data, twitchUsernames, viewFilter }: KeyTableProps) => {
  const [sortKey, setSortKey] = useState<"created_at" | "claimed_at" | "expires_at">("created_at");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const filteredData =
    data?.filter((key) => {
      return (
        viewFilter === "all" ||
        (viewFilter === "claimed" && key.claimed_by !== null) ||
        (viewFilter === "unclaimed" && key.claimed_by === null)
      );
    }) || [];

  const sortedData = [...filteredData].sort((a, b) => {
    const aDate = a[sortKey];
    const bDate = b[sortKey];
    if (aDate === null) return sortOrder === "asc" ? -1 : 1;
    if (bDate === null) return sortOrder === "asc" ? 1 : -1;
    const dateA = new Date(aDate);
    const dateB = new Date(bDate);

    return sortOrder === "asc" ? (dateA > dateB ? 1 : -1) : dateA < dateB ? 1 : -1;
  });

  const handleSort = (key: "created_at" | "claimed_at" | "expires_at") => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  return (
    <TableContainer
      w={"100%"}
      mt={"0 !important"}
      h={"100%"}
      flex={1}
      overflowY={"auto"}
      sx={{ scrollbarWidth: "thin" }}
    >
      <Table variant="striped" size="sm" overflow={"scroll"}>
        <Thead position="sticky" top={0} zIndex={1} bg={"gray.800"}>
          <Tr>
            <Th p={2}>Key Name</Th>
            <Th p={2}>Access Key</Th>
            <Th p={2}>Claimed By</Th>
            <Th p={2}>Twitch Username</Th>
            <Th p={2}>Campaign Title</Th>
            <Th p={2}>Campaign ID</Th>
            <Th p={2} isNumeric>
              Duration
            </Th>
            <Th p={2} isNumeric cursor="pointer" onClick={() => handleSort("created_at")}>
              Created At {sortKey === "created_at" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </Th>
            <Th p={2} isNumeric cursor="pointer" onClick={() => handleSort("claimed_at")}>
              Claimed At {sortKey === "claimed_at" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </Th>
            <Th p={2} isNumeric cursor="pointer" onClick={() => handleSort("expires_at")}>
              Expires At {sortKey === "expires_at" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((key) => (
            <Tr key={key.access_key}>
              <Td px={2}>{key.key_name}</Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.access_key}
              </Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.claimed_by}
              </Td>
              <Td px={2}>
                {key.claimed_by && twitchUsernames[key.claimed_by] ? (
                  <Link
                    href={`https://www.twitch.tv/${twitchUsernames[key.claimed_by]}`}
                    isExternal
                    _hover={{ color: "blue.300", textDecor: "underline" }}
                  >
                    {twitchUsernames[key.claimed_by]}
                    <ExternalLinkIcon ml={1.5} mb={0.5} boxSize={3} />
                  </Link>
                ) : (
                  ""
                )}
              </Td>
              <Td px={2} overflow={"hidden"} textOverflow={"ellipsis"} title={key.campaign_title}>
                {key.campaign_title}
              </Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.campaign_id}
              </Td>
              <Td px={2} isNumeric>
                {key.active_duration}
              </Td>
              <Td px={2} isNumeric>
                {key.created_at ? formatDateForDisplay(new Date(key.created_at)) : ""}
              </Td>
              <Td px={2} isNumeric>
                {key.claimed_at ? formatDateForDisplay(new Date(key.claimed_at)) : ""}
              </Td>
              <Td px={2} isNumeric>
                {key.expires_at ? formatDateForDisplay(new Date(key.expires_at)) : ""}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default KeyTable;
