import {
  Box,
  VStack,
  Input,
  Heading,
  InputGroup,
  InputRightElement,
  IconButton,
  Tooltip,
  Button,
  Text,
  Select,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContext";
import { DeleteFive, UserToUserTransmission } from "@icon-park/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

type RivrRole = "admin" | "user";

const AccountMain = () => {
  const { account } = useContext(AccountContext);
  const { user, getPermission } = useKindeAuth();
  // We need to determine admin status outside of the Hasura role to allow for role switching
  const isRivrAdmin = getPermission?.("admin")?.isGranted || false;
  const currentRole = Cookies.get("xHasuraRole") as RivrRole;

  const given_name = user?.given_name ?? "";
  const family_name = user?.family_name ?? "";
  const name =
    account.name !== null
      ? account.name
      : given_name + (given_name && family_name ? " " : "") + family_name;
  const supportId = account.id;
  const [tooltipLabel, setTooltipLabel] = useState("Copy");
  const mailtoLink = `mailto:support@rivr.stream?subject=Requesting%20Account%20Deletion&body=${supportId} (please do not remove this ID from this request email)`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(supportId);
    setTooltipLabel("Copied!");
    setTimeout(() => setTooltipLabel("Copy"), 800);
  };

  const handleRoleChange = (role: RivrRole) => {
    Cookies.set("xHasuraRole", role);
    window.location.reload();
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems="flex-start" spacing={12} pt={6}>
        <HStack
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          spacing={0}
        >
          <VStack alignItems="flex-start">
            <Heading as="h1" fontSize={"3xl"}>
              Account
            </Heading>
            <Text color="gray.300">Access your account information and unique support ID.</Text>
          </VStack>
        </HStack>

        <Divider />

        {isRivrAdmin && (
          <>
            <VStack alignItems="flex-start" w="100%" spacing={6}>
              <HStack
                w="100%"
                justifyContent="space-between"
                alignItems="flex-start"
                gap={6}
                wrap="wrap"
              >
                <VStack alignItems="flex-start" spacing={1}>
                  <Text fontSize="lg">Admin role switcher</Text>
                  <Text color="gray.300" fontSize="sm">
                    Role switcher for admins only.
                  </Text>
                </VStack>

                <Select
                  ml="0 !important"
                  maxW="520px"
                  icon={<UserToUserTransmission theme="filled" />}
                  defaultValue={currentRole}
                  onChange={(e) => {
                    handleRoleChange(e.target.value as RivrRole);
                    handleAmplitudeTrack("Admin Role Switched");
                  }}
                  className={"role-change"}
                >
                  <option value={"user" as RivrRole}>user {currentRole === "user" && "✓"}</option>
                  <option value={"admin" as RivrRole}>
                    admin {currentRole === "admin" && "✓"}
                  </option>
                </Select>
              </HStack>
            </VStack>
            <Divider />
          </>
        )}

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Name</Text>
              <Text color="gray.300" fontSize="sm">
                The name associated to your account.
              </Text>
            </VStack>
            <Input
              className={"amp-unmask"}
              ml="0 !important"
              maxW="520px"
              isDisabled
              id={"account-name"}
              defaultValue={name}
            />
          </HStack>
        </VStack>

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Email</Text>
              <Text color="gray.300" fontSize="sm">
                The email associated to your account.
              </Text>
            </VStack>
            <Input
              className={"amp-unmask"}
              ml="0 !important"
              maxW="520px"
              isDisabled
              id={"account-email"}
              defaultValue={(account.email ? account.email : user?.email) ?? undefined}
              type="email"
            />
          </HStack>
        </VStack>

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Support ID</Text>
              <Text color="gray.300" fontSize="sm">
                If requested, please provide this ID to support agents.
              </Text>
            </VStack>
            <InputGroup ml="0 !important" maxW="520px">
              <Input
                className={"amp-unmask"}
                isDisabled
                id={"account-support-id"}
                defaultValue={supportId}
                opacity={"1 !important"}
              />
              <InputRightElement>
                <Tooltip label={tooltipLabel} closeDelay={700}>
                  <IconButton
                    aria-label="Copy to clipboard"
                    icon={<CopyIcon />}
                    onClick={copyToClipboard}
                    size="sm"
                    variant={"ghost"}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </HStack>
        </VStack>

        <Divider />

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Delete Account</Text>
              <Text color="gray.300" fontSize="sm">
                Requesting the deletion of your account can take up to 48 hours to complete. Once
                completed you will no longer have access to Rivr.
              </Text>
            </VStack>
            <Button
              colorScheme="red"
              ml="0 !important"
              variant={"outline"}
              as="a"
              href={mailtoLink}
              target="_blank"
              leftIcon={<DeleteFive />}
            >
              Delete account
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default AccountMain;
