import { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  AbsoluteCenter,
  IconButton,
  Checkbox,
  HStack,
  Button,
  Input,
  Select,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  VStack,
  Heading,
  Divider,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Progress,
} from "@chakra-ui/react";
import { validate as validateUUID } from "uuid";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { AllSearchesQuery } from "../api/run-manager";
import { Loading } from "../components/Loading/Loading";

import { useApolloClient } from "@apollo/client";
import {
  ClearCampaignIdSearchMutation,
  DeleteSearchMutation,
  SearchByIDQuery,
  UpdateSearchMutation,
} from "../api/search";
import { SearchMutation } from "../api/actions";
import { RivrLocations } from "../models/navigation";
import { Cog, Dog } from "lucide-react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { logApolloErrorsHandler } from "../utils/graphql-error";

interface Search {
  id: number;
  status: string;
  status_description: string;
  search_progress: number;
  workflow_status: string | null;
  url: string;
  video_id: string;
  video_duration: string;
  created_at: string;
  campaign_id: string | null;
  user_id: string;
}

interface AllSearchesData {
  search: Search[];
}

const RunManager: React.FC = () => {
  const client = useApolloClient();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [statusFilter, setStatusFilter] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [quickFilter, setQuickFilter] = useState("All Time");
  const [quickFilterStart, setQuickFilterStart] = useState<string | null>(null);
  const [quickFilterEnd, setQuickFilterEnd] = useState<string | null>(null);
  const [filterType, setFilterType] = useState("All");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const [dialogBody, setDialogBody] = useState<string>("");
  const [dialogAction, setDialogAction] = useState<
    ((params?: Record<string, any>) => Promise<void>) | null
  >(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [limit, setLimit] = useState(100);
  const [isBulkActionInProgress, setIsBulkActionInProgress] = useState(false);
  const [bulkActionProgress, setBulkActionProgress] = useState(0);
  const [bulkActionCount, setBulkActionCount] = useState("");

  const userIdRef = useRef<HTMLInputElement>(null);
  const campaignIdRef = useRef<HTMLInputElement>(null);
  const [userIdInvalid, setUserIdInvalid] = useState(false);
  const [campaignIdInvalid, setCampaignIdInvalid] = useState(false);

  const openDialog = (
    header: string,
    body: string,
    action: (params?: Record<string, any>) => Promise<void>
  ) => {
    setDialogHeader(header);
    setDialogBody(body);
    setDialogAction(() => (params?: Record<string, any>) => action(params));
    onOpen();
  };

  const toast = useToast();

  const copyToClipboard = (id: string, type: "campaign" | "user") => {
    navigator.clipboard.writeText(id);
    toast({
      title: `${type === "campaign" ? "Campaign" : "User"} ID copied`,
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  };

  const variables = getQueryVariables();

  const { loading, error, data, refetch } = useQuery<AllSearchesData>(AllSearchesQuery, {
    variables,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (quickFilter !== "All Time") {
      const now = new Date();
      const past = new Date(now);

      const timeAdjustments: {
        [key in "1H" | "3H" | "12H" | "1D" | "2D" | "3D" | "1W"]: () => void;
      } = {
        "1H": () => past.setHours(now.getHours() - 1),
        "3H": () => past.setHours(now.getHours() - 3),
        "12H": () => past.setHours(now.getHours() - 12),
        "1D": () => past.setDate(now.getDate() - 1),
        "2D": () => past.setDate(now.getDate() - 2),
        "3D": () => past.setDate(now.getDate() - 3),
        "1W": () => past.setDate(now.getDate() - 7),
      };

      if (quickFilter in timeAdjustments) {
        timeAdjustments[quickFilter as "1H" | "3H" | "12H" | "1D" | "2D" | "3D" | "1W"]();
        setQuickFilterStart(past.toISOString());
        setQuickFilterEnd(now.toISOString());
      } else {
        setQuickFilterStart(null);
        setQuickFilterEnd(null);
      }
    } else {
      setQuickFilterStart(null);
      setQuickFilterEnd(null);
    }
  }, [quickFilter]);

  function getQueryVariables() {
    let startTime: string | null = null;
    let endTime: string | null = null;

    if (quickFilter !== "All Time" && quickFilterStart && quickFilterEnd) {
      startTime = quickFilterStart;
      endTime = quickFilterEnd;
    } else if (startDateTime && endDateTime) {
      const start = new Date(startDateTime + "Z");
      const end = new Date(endDateTime + "Z");
      if (start <= end) {
        startTime = start.toISOString();
        endTime = end.toISOString();
      } else {
        startTime = "1970-01-01T00:00:00Z";
        endTime = "9999-12-31T23:59:59Z";
      }
    } else {
      startTime = "1970-01-01T00:00:00Z";
      endTime = "9999-12-31T23:59:59Z";
    }

    return {
      limit,
      startTime,
      endTime,
    };
  }

  const handleSelectAll = () => {
    if (!filteredData) return;

    if (selectedRows.size === filteredData.length) {
      setSelectedRows(new Set());
    } else {
      const newSelection = new Set(filteredData.map((search) => search.id));
      setSelectedRows(newSelection);
    }
  };

  const handleRowSelect = (id: number) => {
    const newSelection = new Set(selectedRows);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedRows(newSelection);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateTime(e.target.value);
    setQuickFilter("All Time");
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateTime(e.target.value);
    setQuickFilter("All Time");
  };

  const applyQuickFilter = (filter: string) => {
    setQuickFilter(filter);
    setStartDateTime("");
    setEndDateTime("");
  };

  const formatCreatedAt = (isoString: string): string => {
    const d = new Date(isoString);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    let hours = d.getHours();
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
    return formattedTime;
  };

  // Actions

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      setIsStatusLoading(false);
    },
  });

  const [insertSearchAPI] = useMutation(SearchMutation, {
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      setIsStatusLoading(false);
    },
  });

  const [clearCampaignIdSearchAPI] = useMutation(ClearCampaignIdSearchMutation, {
    onError: (error: ApolloError) => {
      logApolloErrorsHandler(error);
      setIsStatusLoading(false);
    },
  });

  const reanalyzeSearch = async (search: Search) => {
    setIsStatusLoading(true);
    try {
      // Fetch the full search data before modifying it
      const { data } = await client.query({
        query: SearchByIDQuery,
        variables: { id: search.id },
        fetchPolicy: "no-cache",
      });

      const fullSearch = data.search_by_pk;
      if (!fullSearch) throw new Error("Could not fetch search");

      // Delete the current search
      await deleteSearchAPI({
        variables: { id: search.id, deleted_at: new Date().toISOString() },
      });

      // If campaign_id exists, then reinsert with campaign_id, else without
      await insertSearchAPI({
        variables: {
          searchRequest: {
            url: fullSearch.url,
            campaignId: fullSearch.campaign_id,
            userId: fullSearch.user_id,
          },
        },
      });

      refetch();
      setIsStatusLoading(false);
    } catch (error) {
      console.error(error);
      setIsStatusLoading(false);
    }
  };

  const deleteSearch = async (search: Search) => {
    setIsStatusLoading(true);
    try {
      if (search.campaign_id) {
        await clearCampaignIdSearchAPI({
          variables: { id: search.id },
        });
        console.log(`Cleared campaign ID for search ${search.id}`);
      }

      await deleteSearchAPI({
        variables: { id: search.id, deleted_at: new Date().toISOString() },
      });

      toast({
        title: `Search with ID ${search.id} deleted`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting search", error);
    } finally {
      setIsStatusLoading(false);
      refetch();
      setSelectedRows(new Set());
    }
  };

  const handleDeleteBulkAction = async () => {
    const selectedArray = Array.from(selectedRows);
    for (let i = 0; i < selectedArray.length; i++) {
      const id = selectedArray[i];
      const search = data?.search.find((search) => search.id === id);
      if (search) {
        await deleteSearch(search);
      }
      setBulkActionProgress(((i + 1) / selectedArray.length) * 100);
      setBulkActionCount(`${i + 1}/${selectedArray.length}`);
    }
    setSelectedRows(new Set());
  };

  const handleReanalyzeBulkAction = async () => {
    const selectedArray = Array.from(selectedRows);
    for (let i = 0; i < selectedArray.length; i++) {
      const id = selectedArray[i];
      const search = data?.search.find((search) => search.id === id);
      if (search) {
        await reanalyzeSearch(search);
      }
      setBulkActionProgress(((i + 1) / selectedArray.length) * 100);
      setBulkActionCount(`${i + 1}/${selectedArray.length}`);
    }
    setSelectedRows(new Set());
  };

  const handleDelete = () => {
    openDialog(
      "Delete",
      selectedRows.size === 1
        ? "Are you sure you want to delete this search?"
        : `Are you sure you want to delete these ${selectedRows.size} searches?`,
      handleDeleteBulkAction
    );
  };

  const [reassignSearchAPI] = useMutation(UpdateSearchMutation, {
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("GraphQL error:", err.message));
      }
      if (networkError) {
        console.log("Network error:", networkError);
      }
      setIsStatusLoading(false);
    },
    onCompleted() {
      refetch();
      setIsStatusLoading(false);
    },
  });

  const handleReassignBulkAction = async (params?: Record<string, any>) => {
    const selectedArray = Array.from(selectedRows);
    const searchUpdates: { [key: string]: any } = {};
    if (params && params.user_id) searchUpdates["user_id"] = params.user_id;
    if (params && params.campaign_id) searchUpdates["campaign_id"] = params.campaign_id;
    for (let i = 0; i < selectedArray.length; i++) {
      const id = selectedArray[i];
      await reassignSearchAPI({
        variables: { id: id, updates: searchUpdates },
      });
      setBulkActionProgress(((i + 1) / selectedArray.length) * 100);
      setBulkActionCount(`${i + 1}/${selectedArray.length}`);
    }

    toast({
      title: "Reassign successful",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
    refetch();
    setSelectedRows(new Set());
  };

  useEffect(() => {
    refetch();
  }, [limit, refetch]);

  if (!isAdmin) return <Navigate to={"/"} />;

  if (loading) {
    return (
      <AbsoluteCenter>
        <Loading variant={"large"} />
      </AbsoluteCenter>
    );
  }

  if (error) {
    return (
      <AbsoluteCenter>
        <Box>
          <Text color="red.300">Error: {error.message}</Text>
        </Box>
      </AbsoluteCenter>
    );
  }

  const filteredData = data?.search.filter((search) => {
    return (
      (!statusFilter || search.status === statusFilter) &&
      (filterType === "All" ||
        (filterType === "Campaigns" && search.campaign_id !== null) ||
        (filterType === "Users" && search.campaign_id === null))
    );
  });

  return (
    <>
      <Box p={4} w={"100%"} h={"100vh"} overflow={"hidden"}>
        <VStack w={"100%"} h={"100%"} align={"start"} gap={4}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <VStack align={"start"} spacing={1} pb={1}>
              <Heading as="h1" size="lg">
                Run Manager
              </Heading>
              <Text color={"gray.300"}>For managing runs</Text>
            </VStack>
            <Button
              size={"xs"}
              onClick={() => refetch()}
              variant={"outline"}
              colorScheme={"blue"}
              isLoading={isStatusLoading}
            >
              Refresh Searches
            </Button>
          </HStack>

          <HStack w={"100%"} gap={2} justify={"space-between"}>
            <HStack w={"100%"} align={"center"}>
              <Input
                type={"datetime-local"}
                size={"sm"}
                borderRadius={"md"}
                value={startDateTime}
                onChange={handleStartDateChange}
                maxW={52}
              />
              <Text>-</Text>
              <Input
                type={"datetime-local"}
                size={"sm"}
                borderRadius={"md"}
                value={endDateTime}
                onChange={handleEndDateChange}
                maxW={52}
              />
              <Select
                value={quickFilter}
                onChange={(e) => applyQuickFilter(e.target.value)}
                size={"sm"}
                borderRadius={"md"}
                maxW={"max-content"}
              >
                <option value={"All Time"}>All Time</option>
                <option value={"1H"}>1H</option>
                <option value={"3H"}>3H</option>
                <option value={"12H"}>12H</option>
                <option value={"1D"}>1D</option>
                <option value={"2D"}>2D</option>
                <option value={"3D"}>3D</option>
                <option value={"1W"}>1W</option>
              </Select>
              <Select
                value={statusFilter}
                onChange={handleStatusChange}
                size={"sm"}
                borderRadius={"md"}
                maxW={"max-content"}
              >
                <option value="">All Statuses</option>
                <option value="in-progress">In Progress</option>
                <option value="error">Error</option>
                <option value="stopped">Stopped</option>
              </Select>
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                size={"sm"}
                borderRadius={"md"}
                maxW={"max-content"}
              >
                <option value="All">All Searches</option>
                <option value="Campaigns">In Campaign</option>
                <option value="Users">By Users</option>
              </Select>
              <Select
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
                size={"sm"}
                borderRadius={"md"}
                maxW={"max-content"}
              >
                <option value={10}>10 searches</option>
                <option value={50}>50 searches</option>
                <option value={100}>100 searches</option>
                <option value={200}>200 searches</option>
                <option value={400}>400 searches</option>
                <option value={600}>600 searches</option>
                <option value={1000}>1,000 searches</option>
                <option value={2000}>2,000 searches</option>
              </Select>
            </HStack>
            <HStack w={"100%"} justify={"end"}>
              {selectedRows.size > 0 && (
                <Text fontSize={"sm"} color={"gray.300"}>
                  {selectedRows.size} Selected
                </Text>
              )}
              <Menu>
                <MenuButton as={Button} size={"sm"} isDisabled={selectedRows.size === 0}>
                  Bulk Actions
                </MenuButton>
                <MenuList>
                  <MenuItem
                    isDisabled={selectedRows.size === 0}
                    onClick={() =>
                      openDialog(
                        "Reanalyze",
                        selectedRows.size === 1
                          ? "Are you sure you want to reanalyze this search?"
                          : `Are you sure you want to reanalyze these ${selectedRows.size} searches?`,
                        handleReanalyzeBulkAction
                      )
                    }
                  >
                    Reanalyze
                  </MenuItem>
                  <MenuItem isDisabled={selectedRows.size === 0} onClick={handleDelete}>
                    Delete
                  </MenuItem>
                  <MenuItem
                    isDisabled={selectedRows.size === 0}
                    onClick={() =>
                      openDialog(
                        "Reassign Search",
                        "Enter new values for User ID and/or Campaign ID.",
                        async (params) => {
                          await handleReassignBulkAction(params);
                        }
                      )
                    }
                  >
                    Reassign
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
          <Divider />

          <TableContainer
            w={"100%"}
            overflowY={"auto"}
            sx={{ scrollbarWidth: "thin" }}
            display={"flex"}
          >
            <Table variant={"striped"} size={"sm"} overflow={"scroll"}>
              <Thead position="sticky" top={0} zIndex={1} bg={"gray.800"}>
                <Tr>
                  <Th py={2} w={8}>
                    <Checkbox
                      isChecked={filteredData && selectedRows.size === filteredData.length}
                      onChange={handleSelectAll}
                    />
                  </Th>
                  <Th py={2} w={16}>
                    SearchId
                  </Th>
                  <Th py={2} w={20}>
                    VideoId
                  </Th>
                  <Th py={2} w={48}>
                    Created At
                  </Th>
                  <Th py={2} w={16}>
                    Duration
                  </Th>
                  <Th py={2} w={24}>
                    Status
                  </Th>
                  <Th py={2} w={16}>
                    Progress
                  </Th>
                  <Th py={2}>Status Desc</Th>
                  <Th py={2} w={48}>
                    UserId
                  </Th>
                  <Th py={2} w={48}>
                    CampaignId
                  </Th>
                  <Th py={2} isNumeric w={12}>
                    Links
                  </Th>
                  <Th py={2} isNumeric w={12}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontFamily={"monospace"}>
                {filteredData?.map((search) => (
                  <Tr key={search.id}>
                    <Td>
                      <Checkbox
                        isChecked={selectedRows.has(search.id)}
                        onChange={() => handleRowSelect(search.id)}
                      />
                    </Td>
                    <Td>
                      <Button
                        as={"a"}
                        href={`${RivrLocations.Results}/${search.id}`}
                        size={"xs"}
                        fontWeight={"normal"}
                      >
                        {search.id}
                      </Button>
                    </Td>
                    <Td>
                      <Button as={"a"} href={search.url} size={"xs"} fontWeight={"normal"}>
                        {search.video_id}
                      </Button>
                    </Td>
                    <Td title={search.created_at.substring(0, 19)}>
                      {formatCreatedAt(search.created_at)}
                    </Td>
                    <Td>{search.video_duration}</Td>
                    <Td>{search.status}</Td>
                    <Td>
                      {search.search_progress === null ? "NULL" : `${search.search_progress}%`}
                    </Td>
                    <Td
                      maxW={80}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                      title={search.status_description}
                      fontSize={"xs"}
                    >
                      {search.status_description}
                    </Td>
                    <Td isNumeric>
                      {search.user_id ? (
                        <Button
                          size={"xs"}
                          aria-label={"Copy User ID"}
                          onClick={() => copyToClipboard(search.user_id as string, "user")}
                          fontWeight={"normal"}
                          letterSpacing={0.5}
                        >
                          {search.user_id}
                        </Button>
                      ) : null}
                    </Td>
                    <Td isNumeric>
                      {search.campaign_id ? (
                        <Button
                          size={"xs"}
                          aria-label={"Copy Campaign ID"}
                          onClick={() => copyToClipboard(search.campaign_id as string, "campaign")}
                          fontWeight={"normal"}
                          letterSpacing={0.5}
                        >
                          {search.campaign_id}
                        </Button>
                      ) : null}
                    </Td>

                    <Td isNumeric>
                      <IconButton
                        as={"a"}
                        size={"xs"}
                        icon={<Dog size={16} />}
                        aria-label={"Datadog Link"}
                        href={`https://us5.datadoghq.com/logs?query=env%3A${
                          process.env.NODE_ENV === "production" ? "production" : "development"
                        }%20%40X-Botni-Search-Id%3A${search.id}&from_ts=${new Date(
                          new Date(search.created_at).getTime() - 5 * 60 * 1000
                        ).getTime()}&to_ts=${new Date(
                          new Date(search.created_at).getTime() + 30 * 60 * 1000
                        ).getTime()}&live=false`}
                      />
                    </Td>
                    <Td isNumeric>
                      <Menu>
                        <MenuButton as={IconButton} icon={<Cog size={16} />} size={"xs"} />
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              openDialog(
                                "Reanalyze",
                                "Are you sure you want to reanalyze this search?",
                                async () => {
                                  await reanalyzeSearch(search);
                                }
                              )
                            }
                          >
                            Reanalyze
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              openDialog(
                                "Delete",
                                "Are you sure you want to delete this search?",
                                async () => {
                                  await deleteSearch(search);
                                }
                              )
                            }
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              openDialog(
                                "Reassign Search",
                                "Enter new values for User ID and/or Campaign ID.",
                                async (params) => {
                                  const searchUpdates: { [key: string]: any } = {};
                                  if (params && params.user_id)
                                    searchUpdates["user_id"] = params.user_id;
                                  if (params && params.campaign_id)
                                    searchUpdates["campaign_id"] = params.campaign_id;
                                  await reassignSearchAPI({
                                    variables: {
                                      id: search.id,
                                      updates: searchUpdates,
                                    },
                                  });
                                  toast({
                                    title: "Reassign successful",
                                    status: "success",
                                    duration: 1000,
                                    isClosable: true,
                                  });
                                  refetch();
                                }
                              )
                            }
                          >
                            Reassign
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </Box>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{dialogHeader}</AlertDialogHeader>
            <AlertDialogBody display={"flex"} flexDir="column" gap={4}>
              {dialogHeader === "Reassign Search" ? (
                <VStack spacing={4} w={"100%"} alignItems={"start"}>
                  <Text>{dialogBody}</Text>
                  <VStack w={"100%"}>
                    <Input placeholder="New User ID" ref={userIdRef} isInvalid={userIdInvalid} />
                    <Input
                      placeholder="New Campaign ID"
                      ref={campaignIdRef}
                      isInvalid={campaignIdInvalid}
                    />
                  </VStack>
                </VStack>
              ) : (
                <Text>{dialogBody}</Text>
              )}

              {isBulkActionInProgress && (
                <HStack w={"100%"}>
                  <Progress
                    value={bulkActionProgress}
                    size={"sm"}
                    colorScheme={"green"}
                    w={"full"}
                  />
                  <Text fontSize="sm">{bulkActionCount}</Text>
                </HStack>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <Button
                  ref={cancelRef}
                  variant={"ghost"}
                  onClick={() => {
                    setUserIdInvalid(false);
                    setCampaignIdInvalid(false);
                    onClose();
                  }}
                  isDisabled={isBulkActionInProgress}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme={"green"}
                  onClick={async () => {
                    if (!dialogAction) {
                      onClose();
                      return;
                    }

                    if (dialogHeader === "Reassign Search") {
                      const userIdVal = userIdRef.current?.value.trim();
                      const campaignIdVal = campaignIdRef.current?.value.trim();

                      // Check if no fields are provided
                      if (!userIdVal && !campaignIdVal) {
                        setUserIdInvalid(true);
                        setCampaignIdInvalid(true);
                        toast({
                          title: "No fields provided.",
                          description:
                            "You must provide at least one field (User ID or Campaign ID).",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }

                      // Validate userId if provided
                      if (userIdVal && (!userIdVal.startsWith("kp:") || userIdVal.length !== 35)) {
                        setUserIdInvalid(true);
                        toast({
                          title: "Invalid User ID",
                          description: "Must start with 'kp:' and be exactly 35 characters long.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }

                      // Validate campaignId if provided
                      if (campaignIdVal && !validateUUID(campaignIdVal)) {
                        setCampaignIdInvalid(true);
                        toast({
                          title: "Invalid Campaign ID",
                          description: "Must be a valid UUID.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }
                    }

                    setIsBulkActionInProgress(true);
                    setBulkActionProgress(0);
                    try {
                      if (dialogHeader === "Reassign Search")
                        await dialogAction({
                          user_id: userIdRef.current?.value,
                          campaign_id: campaignIdRef.current?.value,
                        });
                      else await dialogAction();
                      setUserIdInvalid(false);
                      setCampaignIdInvalid(false);
                      onClose();
                    } catch (error: any) {
                      console.error(error);
                      toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    } finally {
                      setIsBulkActionInProgress(false);
                      setBulkActionProgress(0);
                    }
                  }}
                  isLoading={isBulkActionInProgress}
                >
                  Confirm
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RunManager;
