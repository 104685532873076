import { graphql } from "../gql";

// Queries
// Get all searches, limited to 200 in component
export const AllSearchesQuery = graphql(`
  query AllSearches($limit: Int, $startTime: timestamptz, $endTime: timestamptz) {
    search(
      order_by: { id: desc }
      where: { deleted: { _eq: false }, created_at: { _gte: $startTime, _lte: $endTime } }
      limit: $limit
    ) {
      id
      status
      status_description
      search_progress
      workflow_status
      url
      video_duration
      video_id
      created_at
      campaign_id
      user_id
    }
  }
`);
