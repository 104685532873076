import { Text, Box, Center, VStack, Heading } from "@chakra-ui/react";
import { CircleAlert } from "lucide-react";

const MobileWarning = () => {
  return (
    <Center w={"100vw"} h={"100vh"} p={6}>
      <VStack gap={6} justifyContent={"center"}>
        <Box color={"red.300"}>
          <CircleAlert color={"currentColor"} size={"4rem"} />
        </Box>
        <VStack w={"100%"}>
          <Heading as={"h1"} fontSize={"2xl"}>
            Unsupported Device
          </Heading>
          <Text color={"gray.300"} textAlign={"center"} sx={{ textWrap: "balance" }}>
            Rivr is not optimized for mobile devices. Please access the platform on a device with a
            larger screen for the best experience.
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
};

export default MobileWarning;
