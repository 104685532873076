import { Navigate } from "react-router-dom";
import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import FeedbackModalContext from "./context/FeedbackModalContext";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import { AccountProvider } from "./context/AccountProvider";
import NavigationBar from "./components/Navigation/NavigationBar";
import { DownloadsProvider } from "./context/DownloadsProvider";
import GlobalActions from "./components/GlobalActions/GlobalActions";
import { ErrorScreen } from "./ErrorScreen";
import MobileWarning from "./components/MobileWarning/MobileWarning";
import { isMobile, isTablet } from "react-device-detect";

const Protected = ({ children }) => {
  const { isAuthenticated } = useKindeAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Determine if the device is mobile and not a tablet (e.g., exclude iPads)
  const shouldShowMobileWarning = isMobile && !isTablet;

  // Define paths where the navigation bar should be hidden
  const hideNavBarPaths = ["/billing-redirect"];
  const showNavBar = !hideNavBarPaths.includes(window.location.pathname);

  // Handle authentication redirects
  if (!isAuthenticated) {
    localStorage.setItem("loginRedirect", `${window.location.pathname}${window.location.search}`);
    return <Navigate replace to="/" />;
  }
  localStorage.removeItem("loginRedirect");

  return (
    <Box w={"100%"} h={"100vh"}>
      <AccountProvider>
        <DownloadsProvider>
          <FeedbackModalContext.Provider value={{ isOpen, onOpen, onClose }}>
            <ErrorScreen>
              {shouldShowMobileWarning ? (
                <MobileWarning />
              ) : (
                <VStack spacing={0} h={"100%"} w={"100%"} className={"site-wrapper"}>
                  {showNavBar && <NavigationBar />}
                  {children}
                </VStack>
              )}
              <FeedbackForm />
              <GlobalActions />
            </ErrorScreen>
          </FeedbackModalContext.Provider>
        </DownloadsProvider>
      </AccountProvider>
    </Box>
  );
};

export default Protected;
