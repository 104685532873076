import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { City, KeyOne, Logout, MessageEmoji, People, RoadSign, Sport } from "@icon-park/react";
import Cookies from "js-cookie";
import Discord from "../../assets/discord-logo.svg";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useContext, useMemo } from "react";
import {
  AccountURLParams,
  CampaignURLParams,
  RivrLocations,
  updateURLParams,
} from "../../models/navigation";
import { AccountContext } from "../../context/AccountContext";
import { useFeedbackModal } from "../../context/FeedbackModalContext";
import * as amplitude from "@amplitude/analytics-browser";
import { BookMarked } from "lucide-react";

declare const window: Window & typeof globalThis & { $chatwoot: any };

const rivrVersion = process.env.REACT_APP_VERSION;

const NavMenu = () => {
  const { getBooleanFlag, logout } = useKindeAuth();
  const { memberships } = useContext(AccountContext);
  const { onOpen: openFeedbackModal } = useFeedbackModal();
  const navigate = useNavigate();

  const fullLogout = () => {
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
    logout();
  };

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const demoUser = getBooleanFlag("demo-user", false);
  const isOrgMember = memberships.length > 0;
  const isOrgOwner: boolean = useMemo(() => {
    return selectedMembership !== undefined ? selectedMembership.role === "owner" : false;
  }, [selectedMembership]);

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Box>
      <Menu isLazy>
        <MenuButton as={IconButton} icon={<SettingsIcon />} size={"sm"} />
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(
                `${RivrLocations.Account}?${updateURLParams(searchParams.toString(), [
                  [AccountURLParams.SelectedTab, "main"],
                ])}`
              );
              handleAmplitudeTrack("Nav: Account");
            }}
            icon={<People />}
          >
            Account
          </MenuItem>
          <MenuItem
            hidden={!isOrgOwner}
            onClick={() => {
              navigate(
                `${RivrLocations.Account}?${updateURLParams(searchParams.toString(), [
                  [AccountURLParams.SelectedTab, "org"],
                ])}`
              );
              handleAmplitudeTrack("Nav: Organization");
            }}
            icon={<City theme="outline" />}
          >
            Organization
          </MenuItem>
          <MenuDivider />

          {((!demoUser && isOrgMember) || isAdmin) && (
            <MenuItem
              as="a"
              target="_blank"
              href="https://discord.gg/JgP46aS4dT"
              onClick={() => handleAmplitudeTrack("Nav: Client Discord")}
            >
              <Image src={Discord} boxSize={3} mr={3} />
              Client Discord
            </MenuItem>
          )}

          {(!isOrgMember || isAdmin) && (
            <MenuItem
              as="a"
              target="_blank"
              href="https://discord.gg/WTkWJKzyQK"
              onClick={() => handleAmplitudeTrack("Nav: Discord")}
            >
              <Image src={Discord} boxSize={3} mr={3} />
              Discord
            </MenuItem>
          )}
          <MenuItem
            icon={<BookMarked size={12} />}
            onClick={() => {
              handleAmplitudeTrack("Nav: Knowledge Base");
            }}
            as={"a"}
            target={"_blank"}
            href={`${RivrLocations.KnowledgeBase}?${searchParams.toString()}`}
          >
            Knowledge base
          </MenuItem>
          <MenuItem
            icon={<RoadSign />}
            as={"a"}
            href={"https://rivr.stream/roadmap"}
            target={"_blank"}
            onClick={() => handleAmplitudeTrack("Nav: Roadmap")}
          >
            Roadmap
          </MenuItem>
          <MenuItem
            icon={<MessageEmoji />}
            onClick={() => {
              openFeedbackModal();
              handleAmplitudeTrack("Nav: Share Feedback");
            }}
          >
            Share feedback
          </MenuItem>

          <MenuDivider />

          {isAdmin && (
            <>
              <MenuItem
                icon={<KeyOne />}
                onClick={() => {
                  navigate(`${RivrLocations.KeyManager}?${searchParams.toString()}`);
                  handleAmplitudeTrack("Nav: Key Manager");
                }}
              >
                Key manager
              </MenuItem>
            </>
          )}

          {isAdmin && (
            <>
              <MenuItem
                icon={<Sport />}
                onClick={() => {
                  navigate(`${RivrLocations.RunManager}?${searchParams.toString()}`);
                  handleAmplitudeTrack("Nav: Run Manager");
                }}
              >
                Run manager
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuItem
            icon={<Logout />}
            onClick={() => {
              handleAmplitudeTrack("Nav: Sign Out");
              fullLogout();
            }}
          >
            Sign out
          </MenuItem>
          <MenuDivider />
          <HStack w={"full"} justifyContent={"space-evenly"} gap={0}>
            <Link
              to={`${RivrLocations.Privacy}?${searchParams.toString()}`}
              onClick={() => handleAmplitudeTrack("Nav: Privacy Policy")}
            >
              <Text
                _hover={{ color: "purple.300", textDecor: "underline" }}
                textAlign={"center"}
                fontSize={"xs"}
              >
                Privacy Policy
              </Text>
            </Link>
            <Link
              to={`${RivrLocations.Terms}?${searchParams.toString()}`}
              onClick={() => handleAmplitudeTrack("Nav: Terms of Use")}
            >
              <Text
                _hover={{ color: "purple.300", textDecor: "underline" }}
                textAlign={"center"}
                fontSize={"xs"}
              >
                Terms of Use
              </Text>
            </Link>
          </HStack>
          <MenuDivider />
          <Text textAlign={"center"} fontSize={"xs"}>
            Version: {rivrVersion}
          </Text>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default NavMenu;
