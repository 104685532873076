import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { OrganizationsQuery } from "../api/organization";
import { OrgCampaignsQuery } from "../api/campaign";
import { AccessKey, Organization, Campaign } from "../models/keyManager";
import { GetAllAccountsQuery } from "../api/account";
import { GetKeysByCampaignIdsQuery } from "../api/access-key";

export const useFetchOrganizations = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const client = useApolloClient();

  useEffect(() => {
    async function fetchOrganizations() {
      const orgData = await client.query({ query: OrganizationsQuery });
      setOrganizations(orgData.data.organization);
    }
    fetchOrganizations();
  }, [client]);

  return organizations;
};

export const useFetchAccountsAndKeys = (selectedOrganization: string, reloadTable: boolean) => {
  const [twitchUsernames, setTwitchUsernames] = useState<Record<string, string | null>>({});
  const [data, setData] = useState<AccessKey[] | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    if (!selectedOrganization) return;

    const fetchAccountsAndKeys = async () => {
      setLoading(true);
      try {
        // Fetch all accounts
        const accountsData = await client.query({
          query: GetAllAccountsQuery,
        });

        const accountsMap: Record<string, string | null> = {};
        if (accountsData?.data?.account) {
          accountsData.data.account.reduce((acc, account) => {
            acc[account.id] = account.twitch_username ?? null;
            return acc;
          }, accountsMap);
        }
        setTwitchUsernames(accountsMap);

        // Fetch campaigns by organization ID
        const campaignsData = await client.query({
          query: OrgCampaignsQuery,
          variables: { org_id: selectedOrganization },
        });

        const campaignIds = campaignsData.data.campaign.map((campaign: Campaign) => campaign.id);
        setCampaigns(campaignsData.data.campaign);

        if (campaignIds.length === 0) {
          setData([]);
          setLoading(false);
          return;
        }

        // Fetch keys by campaign IDs
        const keysData = await client.query({
          query: GetKeysByCampaignIdsQuery,
          variables: { campaignIds },
        });

        const keys = keysData.data.access_key_with_expiration || [];
        const keysWithUsernames = (keys as AccessKey[]).map((key) => ({
          ...key,
          twitch_username: key.claimed_by ? accountsMap[key.claimed_by] || "" : "",
          campaign_title:
            campaignsData.data.campaign.find((c: Campaign) => c.id === key.campaign_id)?.title ||
            "",
        }));
        setData(keysWithUsernames);
      } catch (error) {
        console.error("Error fetching accounts and keys:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountsAndKeys();
  }, [selectedOrganization, client, reloadTable]);

  return { twitchUsernames, data, loading, campaigns };
};

export const useFetchCampaigns = (organizationId: string) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    if (!organizationId) return;

    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        const campaignsData = await client.query({
          query: OrgCampaignsQuery,
          variables: { org_id: organizationId },
        });
        setCampaigns(campaignsData.data.campaign || []);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, [organizationId, client]);

  return { campaigns, loading };
};
