/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query AccessKeysByKindeId($account_id: uuid!) {\n    access_key_with_expiration(where: { claimed_by: { _eq: $account_id } }) {\n      access_key\n      key_name\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n": types.AccessKeysByKindeIdDocument,
    "\n  query GetKeysByCampaignIds($campaignIds: [uuid!]) {\n    access_key_with_expiration(where: { campaign_id: { _in: $campaignIds } }) {\n      access_key\n      key_name\n      created_at\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n": types.GetKeysByCampaignIdsDocument,
    "\n  mutation InsertAccessKey(\n    $key_name: String!\n    $campaign_id: uuid!\n    $active_duration: interval!\n    $access_key: uuid\n  ) {\n    insert_access_key(\n      objects: {\n        key_name: $key_name\n        campaign_id: $campaign_id\n        active_duration: $active_duration\n        access_key: $access_key\n        claimed_at: null\n        claimed_by: null\n      }\n    ) {\n      returning {\n        key_name\n        campaign_id\n        active_duration\n        access_key\n      }\n    }\n  }\n": types.InsertAccessKeyDocument,
    "\n  query AccountByKindeId($kinde_id: String!) {\n    account(where: { kinde_id: { _eq: $kinde_id } }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n": types.AccountByKindeIdDocument,
    "\n  query GetAllAccounts {\n    account {\n      id\n      twitch_username\n    }\n  }\n": types.GetAllAccountsDocument,
    "\n  mutation CreateAccount($kinde_id: String!, $email: String) {\n    insert_account_one(object: { kinde_id: $kinde_id, email: $email }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n": types.CreateAccountDocument,
    "\n  mutation UpdateAccount($id: uuid!, $updates: account_set_input!) {\n    update_account_by_pk(pk_columns: { id: $id }, _set: $updates) {\n      id\n    }\n  }\n": types.UpdateAccountDocument,
    "\n  query ActionSets($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n    }\n  }\n": types.ActionSetsDocument,
    "\n  query ActionSetsAggregated($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n      actions_aggregate {\n        aggregate {\n          count\n        }\n        nodes {\n          id\n          title\n          type\n          output_destination\n        }\n      }\n    }\n  }\n": types.ActionSetsAggregatedDocument,
    "\n  query ActionSetByID($id: Int!) {\n    action_sets(where: { id: { _eq: $id } }) {\n      id\n      title\n    }\n    actions(where: { action_set_id: { _eq: $id } }) {\n      id\n      type\n      title\n      output_destination\n      action_set_id\n    }\n  }\n": types.ActionSetByIdDocument,
    "\n  mutation UpdateActionSet(\n    $actionSetId: Int!\n    $title: String\n    $deleteActionIDs: [Int!]\n    $createActions: [actions_insert_input!]!\n    $updateActions: [actions_updates!]!\n  ) {\n    update_action_sets(where: { id: { _eq: $actionSetId } }, _set: { title: $title }) {\n      affected_rows\n    }\n    delete_actions(where: { id: { _in: $deleteActionIDs }, action_set_id: { _eq: $actionSetId } }) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    insert_actions(objects: $createActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    update_actions_many(updates: $updateActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n  }\n": types.UpdateActionSetDocument,
    "\n  mutation DeleteActionSetByID($id: Int) {\n    delete_actions(where: { action_set_id: { _eq: $id } }) {\n      affected_rows\n    }\n    delete_action_sets(where: { id: { _eq: $id } }) {\n      affected_rows\n    }\n  }\n": types.DeleteActionSetByIdDocument,
    "\n  mutation InsertActionSetWithActions($actionSets: action_sets_insert_input!) {\n    insert_action_sets_one(object: $actionSets) {\n      id\n      title\n      user_id\n    }\n  }\n": types.InsertActionSetWithActionsDocument,
    "\n  query PaymentPortal($org: String) {\n    getPaymentPortal(org: $org) {\n      customerPortal\n      paymentMethod\n    }\n  }\n": types.PaymentPortalDocument,
    "\n  query GetVideos($platform: Platform!) {\n    getVideos(platform: $platform) {\n      videos {\n        id\n        title\n        channelName\n        url\n        thumbnailUrl\n        duration\n        publishedAt\n        language\n      }\n      nextPageCursor\n    }\n  }\n": types.GetVideosDocument,
    "\n  query GetTrialQuota {\n    getTrialQuota {\n      used\n      total\n    }\n  }\n": types.GetTrialQuotaDocument,
    "\n  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {\n    clipRequest(requestsInput: $requests) {\n      id\n      moment_id\n    }\n  }\n": types.ClipRequestDocument,
    "\n  mutation StartSearch($searchRequest: SearchRequestInput!) {\n    startSearch(searchRequestInput: $searchRequest) {\n      id\n    }\n  }\n": types.StartSearchDocument,
    "\n  mutation DisconnectTwitch {\n    disconnectTwitch {\n      accountId\n    }\n  }\n": types.DisconnectTwitchDocument,
    "\n  mutation InsertCampaignWatcher($campaign_id: uuid!, $twitch_game_id: String!) {\n    insert_campaign_game(objects: { campaign_id: $campaign_id, twitch_game_id: $twitch_game_id }) {\n      returning {\n        campaign_id\n        twitch_game_id\n      }\n    }\n  }\n": types.InsertCampaignWatcherDocument,
    "\n  query OrgCampaigns($org_id: uuid, $account_id: uuid) {\n    campaign(where: { organization_id: { _eq: $org_id }, deleted_at: { _is_null: true } }) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n": types.OrgCampaignsDocument,
    "\n  query CampaignSearches($campaign: uuid) {\n    search(where: { campaign_id: { _eq: $campaign } }) {\n      id\n      url\n      origin_type\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      search_progress\n      status\n      created_at\n      metadata\n      workflow_status\n      archived_at\n      moments(where: { deleted: { _eq: false } }) {\n        id\n        search_id\n        title\n        start_time\n        end_time\n        tags\n        annotations\n        archived_at\n        favorited\n        created_at\n        updated_at\n      }\n      recommended_moments(where: { rejected: { _eq: false } }) {\n        id\n        search_id\n        title\n        moment_time\n        annotations\n        archived_at\n        favorited\n        tags\n        created_at\n      }\n    }\n  }\n": types.CampaignSearchesDocument,
    "\n  mutation InsertCampaign(\n    $user_id: String\n    $title: String\n    $status: String\n    $image: bytea\n    $description: String\n    $start_date: timestamptz\n    $end_date: timestamptz\n    $organization_id: uuid\n  ) {\n    insert_campaign_one(\n      object: {\n        user_id: $user_id\n        title: $title\n        status: $status\n        image: $image\n        description: $description\n        start_date: $start_date\n        end_date: $end_date\n        organization_id: $organization_id\n      }\n    ) {\n      id\n    }\n  }\n": types.InsertCampaignDocument,
    "\n  mutation SetCampaignStatus($id: uuid, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { status: $status }) {\n      returning {\n        id\n        status\n      }\n    }\n  }\n": types.SetCampaignStatusDocument,
    "\n  mutation SetCampaignArchiveStatus($id: uuid, $date: timestamptz, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { archived_at: $date, status: $status }) {\n      returning {\n        id\n        archived_at\n        status\n      }\n    }\n  }\n": types.SetCampaignArchiveStatusDocument,
    "\n  mutation SetCampaignDeleteStatus($id: uuid, $date: timestamptz) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { deleted_at: $date }) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n": types.SetCampaignDeleteStatusDocument,
    "\n  mutation UpdateCampaign($id: uuid, $updates: campaign_set_input) {\n    update_campaign(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n": types.UpdateCampaignDocument,
    "\n  query ClipRequestsBySearchID($id: Int!) {\n    clip_request(where: { search_id: { _eq: $id } }) {\n      id\n      timestamp\n      lead_in\n      lead_out\n      created_at\n      moment\n      action\n      clip_results {\n        expires_at\n      }\n    }\n  }\n": types.ClipRequestsBySearchIdDocument,
    "\n  query ClipRequestsByID($ids: [Int!]!) {\n    clip_request(where: { id: { _in: $ids } }) {\n      id\n      created_at\n      moment\n      clip_results {\n        url\n      }\n    }\n  }\n": types.ClipRequestsByIdDocument,
    "\n  query AuthURL($keyCodeRef: String!, $userId: String!) {\n    getAuthURL(data: { key_code_ref: $keyCodeRef, user_id: $userId }) {\n      url\n      session_id\n    }\n  }\n": types.AuthUrlDocument,
    "\n  query AccessToken($sessionId: String!) {\n    getAccessToken(data: { session_id: $sessionId }) {\n      access_token\n      access_token_expiry\n    }\n  }\n": types.AccessTokenDocument,
    "\n  query RevokeConnectedAppToken($sessionId: String!) {\n    revokeConnectedAppToken(data: { session_id: $sessionId }) {\n      result\n    }\n  }\n": types.RevokeConnectedAppTokenDocument,
    "\n  query SpeakerMentions($query: String!, $campaign_id: uuid!) {\n    speakerMentions(query: $query, campaign_id: $campaign_id) {\n      rank\n      search {\n        id\n        video_title\n        url\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        upload_date: metadata(path: \"$.upload_date\")\n        published_at: metadata(path: \"$.published_at\")\n      }\n      start_time\n      line\n      sentiment\n    }\n  }\n": types.SpeakerMentionsDocument,
    "\n  query MentionsSearches($organization_id: uuid!) {\n    mentions_search(\n      where: { organization_id: { _eq: $organization_id }, deleted_at: { _is_null: true } }\n    ) {\n      title\n      search\n      created_at\n      deleted_at\n      updated_at\n      id\n      organization_id\n    }\n  }\n": types.MentionsSearchesDocument,
    "\n  mutation CreateMentionsSearch(\n    $organization_id: uuid!\n    $title: String!\n    $search: String!\n    $created_at: timestamptz!\n  ) {\n    insert_mentions_search_one(\n      object: {\n        organization_id: $organization_id\n        title: $title\n        search: $search\n        created_at: $created_at\n        updated_at: $created_at\n      }\n    ) {\n      id\n    }\n  }\n": types.CreateMentionsSearchDocument,
    "\n  mutation RenameMentionsSearch($id: uuid!, $title: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { title: $title, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n": types.RenameMentionsSearchDocument,
    "\n  mutation SetMentionsSearchDeleteStatus(\n    $id: uuid!\n    $deleted_at: timestamptz!\n    $updated_at: timestamptz!\n  ) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { deleted_at: $deleted_at, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n": types.SetMentionsSearchDeleteStatusDocument,
    "\n  mutation UpdateMentionsSearch($id: uuid!, $search: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { search: $search, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n": types.UpdateMentionsSearchDocument,
    "\n  mutation UpsertMoment($object: moment_insert_input!) {\n    insert_moment_one(\n      object: $object\n      on_conflict: {\n        constraint: moment_pkey\n        update_columns: [\n          title\n          tags\n          start_time\n          end_time\n          updated_at\n          deleted\n          recommended_moment_id\n          annotations\n        ]\n      }\n    ) {\n      id\n    }\n  }\n": types.UpsertMomentDocument,
    "\n  mutation UpdateMoment($id: uuid!, $object: moment_set_input!) {\n    update_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n": types.UpdateMomentDocument,
    "\n  mutation DeleteMoments($ids: [uuid!]!, $updated_at: timestamptz!) {\n    update_moment(where: { id: { _in: $ids } }, _set: { deleted: true, updated_at: $updated_at }) {\n      affected_rows\n    }\n  }\n": types.DeleteMomentsDocument,
    "\n  query Organizations {\n    organization {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n": types.OrganizationsDocument,
    "\n  query OrganizationsById($ids: [uuid!]) {\n    organization(where: { id: { _in: $ids } }) {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n": types.OrganizationsByIdDocument,
    "\n  query OrganizationMembersById($org_id: uuid!) {\n    organization_member(where: { organization_id: { _eq: $org_id } }) {\n      account {\n        id\n        email\n        name\n      }\n      role\n    }\n  }\n": types.OrganizationMembersByIdDocument,
    "\n  mutation UpdateOrganization($id: uuid, $updates: organization_set_input) {\n    update_organization(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n": types.UpdateOrganizationDocument,
    "\n  mutation ToggleMoment($id: uuid!, $rejected: Boolean!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: { rejected: $rejected }) {\n      id\n    }\n  }\n": types.ToggleMomentDocument,
    "\n  mutation UpdateRecommendedMoment($id: uuid!, $object: recommended_moment_set_input!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n": types.UpdateRecommendedMomentDocument,
    "\n  subscription RecommendedMomentsBySearchID($id: Int!) {\n    recommended_moment_stream(\n      cursor: { initial_value: { created_at: \"2023-01-01\" }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      id\n      tags\n      title\n      moment_time\n      annotations\n      rejected\n      created_at\n      moments {\n        deleted\n      }\n    }\n  }\n": types.RecommendedMomentsBySearchIdDocument,
    "\n  query AllSearches($limit: Int, $startTime: timestamptz, $endTime: timestamptz) {\n    search(\n      order_by: { id: desc }\n      where: { deleted: { _eq: false }, created_at: { _gte: $startTime, _lte: $endTime } }\n      limit: $limit\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_duration\n      video_id\n      created_at\n      campaign_id\n      user_id\n    }\n  }\n": types.AllSearchesDocument,
    "\n  query ActiveSearches($user_id: String) {\n    search(\n      order_by: { id: desc }\n      where: {\n        user_id: { _eq: $user_id }\n        deleted: { _eq: false }\n        _or: [\n          { status: { _in: [\"in-progress\", \"error\"] } }\n          { status_description: { _eq: \"stopped\" } }\n        ]\n      }\n    ) {\n      created_at\n      id\n      origin_type\n      search_progress\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      metadata\n      status\n      status_description\n    }\n  }\n": types.ActiveSearchesDocument,
    "\n  query SearchHistory($user_id: String, $limit: Int) {\n    search(\n      order_by: { id: desc }\n      where: { user_id: { _eq: $user_id }, deleted: { _eq: false }, status: { _eq: \"stopped\" } }\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      created_at\n      archived_at\n      origin_type\n      thumbnail_url\n      metadata\n      moments_aggregate(where: { deleted: { _eq: false } }) {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n": types.SearchHistoryDocument,
    "\n  query SearchByID($id: Int!) {\n    search_by_pk(id: $id) {\n      user_id\n      search_progress\n      status\n      status_description\n      url\n      video_duration\n      video_title\n      video_type\n      video_id\n      origin_type\n      campaign_id\n      metadata\n      thumbnail_url\n      asr_transcript\n      moments {\n        updated_at\n        title\n        tags\n        start_time\n        search_id\n        id\n        end_time\n        created_at\n        deleted\n        recommended_moment_id\n        annotations\n      }\n    }\n  }\n": types.SearchByIdDocument,
    "\n  mutation StopSearch($id: Int) {\n    update_search(\n      where: { id: { _eq: $id } }\n      _set: { status: \"stopped\", status_description: \"stopped\" }\n    ) {\n      returning {\n        id\n        created_at\n        origin_type\n        search_progress\n        status\n        status_description\n        url\n        user_id\n        video_duration\n        video_title\n        video_type\n      }\n    }\n  }\n": types.StopSearchDocument,
    "\n  mutation SetSearchWorkflow($id: Int, $status: String) {\n    update_search(where: { id: { _eq: $id } }, _set: { workflow_status: $status }) {\n      returning {\n        id\n        workflow_status\n      }\n    }\n  }\n": types.SetSearchWorkflowDocument,
    "\n  mutation SetSearchArchiveStatus($id: Int, $date: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { archived_at: $date }) {\n      returning {\n        id\n        archived_at\n      }\n    }\n  }\n": types.SetSearchArchiveStatusDocument,
    "\n  mutation DeleteSearch($id: Int, $deleted_at: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { deleted: true, deleted_at: $deleted_at }) {\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n": types.DeleteSearchDocument,
    "\n  mutation DeleteAllSearchHistory($user_id: String, $deleted_at: timestamptz) {\n    update_search(\n      where: { user_id: { _eq: $user_id }, status: { _in: [\"stopped\", \"error\"] } }\n      _set: { deleted: true, deleted_at: $deleted_at }\n    ) {\n      affected_rows\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n": types.DeleteAllSearchHistoryDocument,
    "\n  mutation ClearCampaignIdSearch($id: Int!) {\n    update_search(where: { id: { _eq: $id } }, _set: { campaign_id: null }) {\n      returning {\n        id\n        campaign_id\n      }\n    }\n  }\n": types.ClearCampaignIdSearchDocument,
    "\n  mutation UpdateSearch($id: Int!, $updates: search_set_input) {\n    update_search(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n": types.UpdateSearchDocument,
    "\n  subscription SignalsBySearchID($id: Int!) {\n    signals_stream(\n      cursor: { initial_value: { id: 1 }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      from_seconds\n      x_values\n      y_values\n      title\n    }\n  }\n": types.SignalsBySearchIdDocument,
    "\n  query LatestOrgSubscriptionEvent($organization_id: uuid!) {\n    subscription_event(\n      where: { organization_id: { _eq: $organization_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n": types.LatestOrgSubscriptionEventDocument,
    "\n  query LatestUserSubscriptionEvent($account_id: uuid!) {\n    subscription_event(\n      where: { account_id: { _eq: $account_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n": types.LatestUserSubscriptionEventDocument,
    "\n  mutation CompleteTutorial($account_id: uuid!, $tutorial: String!) {\n    insert_tutorial_progress_one(object: { account_id: $account_id, tutorial: $tutorial }) {\n      tutorial\n    }\n  }\n": types.CompleteTutorialDocument,
    "\n  query UserClipRequests($account_id: uuid!, $requested_at: timestamptz!) {\n    user_clip_request(\n      where: {\n        account_id: { _eq: $account_id }\n        requested_at: { _gte: $requested_at }\n        clip_request: { search: { deleted: { _eq: false } } }\n      }\n    ) {\n      clip_request {\n        moment\n        action\n        search {\n          channel: metadata(path: \"$.channel\")\n          user_name: metadata(path: \"$.user_name\")\n        }\n        clip_results {\n          id\n          url\n          expires_at\n        }\n      }\n      requested_at\n    }\n  }\n": types.UserClipRequestsDocument,
    "\n  mutation InsertUserClipRequest(\n    $account_id: uuid!\n    $clip_request_id: Int!\n    $requested_at: timestamptz!\n  ) {\n    insert_user_clip_request_one(\n      object: {\n        account_id: $account_id\n        clip_request_id: $clip_request_id\n        requested_at: $requested_at\n      }\n    ) {\n      account_id\n      clip_request_id\n    }\n  }\n": types.InsertUserClipRequestDocument,
    "\n  query OrgGroups($org_id: uuid!) {\n    user_group(where: { org_id: { _eq: $org_id } }) {\n      name\n      user_group_members {\n        account_id\n      }\n    }\n  }\n": types.OrgGroupsDocument,
    "\n  query UserGroupByAccountQuery($account_id: uuid!) {\n    user_group_member(where: { account_id: { _eq: $account_id } }) {\n      user_group_id\n      user_group {\n        org_id\n      }\n    }\n  }\n": types.UserGroupByAccountQueryDocument,
    "\n  query UserGroupCampaignById($org_id: uuid, $account_id: uuid) {\n    # Fetch campaigns the user is allowed to view based on user group.\n    campaign(\n      where: {\n        user_group_campaigns: {\n          user_group: {\n            org_id: { _eq: $org_id }\n            user_group_members: { account_id: { _eq: $account_id } }\n          }\n        }\n      }\n      order_by: { created_at: desc }\n    ) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n": types.UserGroupCampaignByIdDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccessKeysByKindeId($account_id: uuid!) {\n    access_key_with_expiration(where: { claimed_by: { _eq: $account_id } }) {\n      access_key\n      key_name\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n"): (typeof documents)["\n  query AccessKeysByKindeId($account_id: uuid!) {\n    access_key_with_expiration(where: { claimed_by: { _eq: $account_id } }) {\n      access_key\n      key_name\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetKeysByCampaignIds($campaignIds: [uuid!]) {\n    access_key_with_expiration(where: { campaign_id: { _in: $campaignIds } }) {\n      access_key\n      key_name\n      created_at\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n"): (typeof documents)["\n  query GetKeysByCampaignIds($campaignIds: [uuid!]) {\n    access_key_with_expiration(where: { campaign_id: { _in: $campaignIds } }) {\n      access_key\n      key_name\n      created_at\n      claimed_at\n      claimed_by\n      campaign_id\n      active_duration\n      expires_at\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertAccessKey(\n    $key_name: String!\n    $campaign_id: uuid!\n    $active_duration: interval!\n    $access_key: uuid\n  ) {\n    insert_access_key(\n      objects: {\n        key_name: $key_name\n        campaign_id: $campaign_id\n        active_duration: $active_duration\n        access_key: $access_key\n        claimed_at: null\n        claimed_by: null\n      }\n    ) {\n      returning {\n        key_name\n        campaign_id\n        active_duration\n        access_key\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InsertAccessKey(\n    $key_name: String!\n    $campaign_id: uuid!\n    $active_duration: interval!\n    $access_key: uuid\n  ) {\n    insert_access_key(\n      objects: {\n        key_name: $key_name\n        campaign_id: $campaign_id\n        active_duration: $active_duration\n        access_key: $access_key\n        claimed_at: null\n        claimed_by: null\n      }\n    ) {\n      returning {\n        key_name\n        campaign_id\n        active_duration\n        access_key\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountByKindeId($kinde_id: String!) {\n    account(where: { kinde_id: { _eq: $kinde_id } }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n"): (typeof documents)["\n  query AccountByKindeId($kinde_id: String!) {\n    account(where: { kinde_id: { _eq: $kinde_id } }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllAccounts {\n    account {\n      id\n      twitch_username\n    }\n  }\n"): (typeof documents)["\n  query GetAllAccounts {\n    account {\n      id\n      twitch_username\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAccount($kinde_id: String!, $email: String) {\n    insert_account_one(object: { kinde_id: $kinde_id, email: $email }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAccount($kinde_id: String!, $email: String) {\n    insert_account_one(object: { kinde_id: $kinde_id, email: $email }) {\n      id\n      kinde_id\n      email\n      name\n      twitch_username\n      subscription_status\n      subscription_id\n      email_notifications\n      onboarding_response\n      allow_spotlights\n      allow_promotions\n      allow_feedback\n      allow_machine_learning\n      beta_access\n      memberships {\n        organization_id\n        role\n      }\n      tutorial_progress {\n        tutorial\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateAccount($id: uuid!, $updates: account_set_input!) {\n    update_account_by_pk(pk_columns: { id: $id }, _set: $updates) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAccount($id: uuid!, $updates: account_set_input!) {\n    update_account_by_pk(pk_columns: { id: $id }, _set: $updates) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActionSets($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  query ActionSets($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActionSetsAggregated($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n      actions_aggregate {\n        aggregate {\n          count\n        }\n        nodes {\n          id\n          title\n          type\n          output_destination\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActionSetsAggregated($user_id: String) {\n    action_sets(where: { user_id: { _eq: $user_id } }) {\n      id\n      title\n      actions_aggregate {\n        aggregate {\n          count\n        }\n        nodes {\n          id\n          title\n          type\n          output_destination\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActionSetByID($id: Int!) {\n    action_sets(where: { id: { _eq: $id } }) {\n      id\n      title\n    }\n    actions(where: { action_set_id: { _eq: $id } }) {\n      id\n      type\n      title\n      output_destination\n      action_set_id\n    }\n  }\n"): (typeof documents)["\n  query ActionSetByID($id: Int!) {\n    action_sets(where: { id: { _eq: $id } }) {\n      id\n      title\n    }\n    actions(where: { action_set_id: { _eq: $id } }) {\n      id\n      type\n      title\n      output_destination\n      action_set_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateActionSet(\n    $actionSetId: Int!\n    $title: String\n    $deleteActionIDs: [Int!]\n    $createActions: [actions_insert_input!]!\n    $updateActions: [actions_updates!]!\n  ) {\n    update_action_sets(where: { id: { _eq: $actionSetId } }, _set: { title: $title }) {\n      affected_rows\n    }\n    delete_actions(where: { id: { _in: $deleteActionIDs }, action_set_id: { _eq: $actionSetId } }) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    insert_actions(objects: $createActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    update_actions_many(updates: $updateActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateActionSet(\n    $actionSetId: Int!\n    $title: String\n    $deleteActionIDs: [Int!]\n    $createActions: [actions_insert_input!]!\n    $updateActions: [actions_updates!]!\n  ) {\n    update_action_sets(where: { id: { _eq: $actionSetId } }, _set: { title: $title }) {\n      affected_rows\n    }\n    delete_actions(where: { id: { _in: $deleteActionIDs }, action_set_id: { _eq: $actionSetId } }) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    insert_actions(objects: $createActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n    update_actions_many(updates: $updateActions) {\n      affected_rows\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteActionSetByID($id: Int) {\n    delete_actions(where: { action_set_id: { _eq: $id } }) {\n      affected_rows\n    }\n    delete_action_sets(where: { id: { _eq: $id } }) {\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteActionSetByID($id: Int) {\n    delete_actions(where: { action_set_id: { _eq: $id } }) {\n      affected_rows\n    }\n    delete_action_sets(where: { id: { _eq: $id } }) {\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertActionSetWithActions($actionSets: action_sets_insert_input!) {\n    insert_action_sets_one(object: $actionSets) {\n      id\n      title\n      user_id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertActionSetWithActions($actionSets: action_sets_insert_input!) {\n    insert_action_sets_one(object: $actionSets) {\n      id\n      title\n      user_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PaymentPortal($org: String) {\n    getPaymentPortal(org: $org) {\n      customerPortal\n      paymentMethod\n    }\n  }\n"): (typeof documents)["\n  query PaymentPortal($org: String) {\n    getPaymentPortal(org: $org) {\n      customerPortal\n      paymentMethod\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetVideos($platform: Platform!) {\n    getVideos(platform: $platform) {\n      videos {\n        id\n        title\n        channelName\n        url\n        thumbnailUrl\n        duration\n        publishedAt\n        language\n      }\n      nextPageCursor\n    }\n  }\n"): (typeof documents)["\n  query GetVideos($platform: Platform!) {\n    getVideos(platform: $platform) {\n      videos {\n        id\n        title\n        channelName\n        url\n        thumbnailUrl\n        duration\n        publishedAt\n        language\n      }\n      nextPageCursor\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTrialQuota {\n    getTrialQuota {\n      used\n      total\n    }\n  }\n"): (typeof documents)["\n  query GetTrialQuota {\n    getTrialQuota {\n      used\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {\n    clipRequest(requestsInput: $requests) {\n      id\n      moment_id\n    }\n  }\n"): (typeof documents)["\n  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {\n    clipRequest(requestsInput: $requests) {\n      id\n      moment_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StartSearch($searchRequest: SearchRequestInput!) {\n    startSearch(searchRequestInput: $searchRequest) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation StartSearch($searchRequest: SearchRequestInput!) {\n    startSearch(searchRequestInput: $searchRequest) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DisconnectTwitch {\n    disconnectTwitch {\n      accountId\n    }\n  }\n"): (typeof documents)["\n  mutation DisconnectTwitch {\n    disconnectTwitch {\n      accountId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertCampaignWatcher($campaign_id: uuid!, $twitch_game_id: String!) {\n    insert_campaign_game(objects: { campaign_id: $campaign_id, twitch_game_id: $twitch_game_id }) {\n      returning {\n        campaign_id\n        twitch_game_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InsertCampaignWatcher($campaign_id: uuid!, $twitch_game_id: String!) {\n    insert_campaign_game(objects: { campaign_id: $campaign_id, twitch_game_id: $twitch_game_id }) {\n      returning {\n        campaign_id\n        twitch_game_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrgCampaigns($org_id: uuid, $account_id: uuid) {\n    campaign(where: { organization_id: { _eq: $org_id }, deleted_at: { _is_null: true } }) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrgCampaigns($org_id: uuid, $account_id: uuid) {\n    campaign(where: { organization_id: { _eq: $org_id }, deleted_at: { _is_null: true } }) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CampaignSearches($campaign: uuid) {\n    search(where: { campaign_id: { _eq: $campaign } }) {\n      id\n      url\n      origin_type\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      search_progress\n      status\n      created_at\n      metadata\n      workflow_status\n      archived_at\n      moments(where: { deleted: { _eq: false } }) {\n        id\n        search_id\n        title\n        start_time\n        end_time\n        tags\n        annotations\n        archived_at\n        favorited\n        created_at\n        updated_at\n      }\n      recommended_moments(where: { rejected: { _eq: false } }) {\n        id\n        search_id\n        title\n        moment_time\n        annotations\n        archived_at\n        favorited\n        tags\n        created_at\n      }\n    }\n  }\n"): (typeof documents)["\n  query CampaignSearches($campaign: uuid) {\n    search(where: { campaign_id: { _eq: $campaign } }) {\n      id\n      url\n      origin_type\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      search_progress\n      status\n      created_at\n      metadata\n      workflow_status\n      archived_at\n      moments(where: { deleted: { _eq: false } }) {\n        id\n        search_id\n        title\n        start_time\n        end_time\n        tags\n        annotations\n        archived_at\n        favorited\n        created_at\n        updated_at\n      }\n      recommended_moments(where: { rejected: { _eq: false } }) {\n        id\n        search_id\n        title\n        moment_time\n        annotations\n        archived_at\n        favorited\n        tags\n        created_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertCampaign(\n    $user_id: String\n    $title: String\n    $status: String\n    $image: bytea\n    $description: String\n    $start_date: timestamptz\n    $end_date: timestamptz\n    $organization_id: uuid\n  ) {\n    insert_campaign_one(\n      object: {\n        user_id: $user_id\n        title: $title\n        status: $status\n        image: $image\n        description: $description\n        start_date: $start_date\n        end_date: $end_date\n        organization_id: $organization_id\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertCampaign(\n    $user_id: String\n    $title: String\n    $status: String\n    $image: bytea\n    $description: String\n    $start_date: timestamptz\n    $end_date: timestamptz\n    $organization_id: uuid\n  ) {\n    insert_campaign_one(\n      object: {\n        user_id: $user_id\n        title: $title\n        status: $status\n        image: $image\n        description: $description\n        start_date: $start_date\n        end_date: $end_date\n        organization_id: $organization_id\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetCampaignStatus($id: uuid, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { status: $status }) {\n      returning {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetCampaignStatus($id: uuid, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { status: $status }) {\n      returning {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetCampaignArchiveStatus($id: uuid, $date: timestamptz, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { archived_at: $date, status: $status }) {\n      returning {\n        id\n        archived_at\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetCampaignArchiveStatus($id: uuid, $date: timestamptz, $status: String) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { archived_at: $date, status: $status }) {\n      returning {\n        id\n        archived_at\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetCampaignDeleteStatus($id: uuid, $date: timestamptz) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { deleted_at: $date }) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetCampaignDeleteStatus($id: uuid, $date: timestamptz) {\n    update_campaign(where: { id: { _eq: $id } }, _set: { deleted_at: $date }) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCampaign($id: uuid, $updates: campaign_set_input) {\n    update_campaign(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCampaign($id: uuid, $updates: campaign_set_input) {\n    update_campaign(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClipRequestsBySearchID($id: Int!) {\n    clip_request(where: { search_id: { _eq: $id } }) {\n      id\n      timestamp\n      lead_in\n      lead_out\n      created_at\n      moment\n      action\n      clip_results {\n        expires_at\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClipRequestsBySearchID($id: Int!) {\n    clip_request(where: { search_id: { _eq: $id } }) {\n      id\n      timestamp\n      lead_in\n      lead_out\n      created_at\n      moment\n      action\n      clip_results {\n        expires_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClipRequestsByID($ids: [Int!]!) {\n    clip_request(where: { id: { _in: $ids } }) {\n      id\n      created_at\n      moment\n      clip_results {\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClipRequestsByID($ids: [Int!]!) {\n    clip_request(where: { id: { _in: $ids } }) {\n      id\n      created_at\n      moment\n      clip_results {\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AuthURL($keyCodeRef: String!, $userId: String!) {\n    getAuthURL(data: { key_code_ref: $keyCodeRef, user_id: $userId }) {\n      url\n      session_id\n    }\n  }\n"): (typeof documents)["\n  query AuthURL($keyCodeRef: String!, $userId: String!) {\n    getAuthURL(data: { key_code_ref: $keyCodeRef, user_id: $userId }) {\n      url\n      session_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccessToken($sessionId: String!) {\n    getAccessToken(data: { session_id: $sessionId }) {\n      access_token\n      access_token_expiry\n    }\n  }\n"): (typeof documents)["\n  query AccessToken($sessionId: String!) {\n    getAccessToken(data: { session_id: $sessionId }) {\n      access_token\n      access_token_expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RevokeConnectedAppToken($sessionId: String!) {\n    revokeConnectedAppToken(data: { session_id: $sessionId }) {\n      result\n    }\n  }\n"): (typeof documents)["\n  query RevokeConnectedAppToken($sessionId: String!) {\n    revokeConnectedAppToken(data: { session_id: $sessionId }) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpeakerMentions($query: String!, $campaign_id: uuid!) {\n    speakerMentions(query: $query, campaign_id: $campaign_id) {\n      rank\n      search {\n        id\n        video_title\n        url\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        upload_date: metadata(path: \"$.upload_date\")\n        published_at: metadata(path: \"$.published_at\")\n      }\n      start_time\n      line\n      sentiment\n    }\n  }\n"): (typeof documents)["\n  query SpeakerMentions($query: String!, $campaign_id: uuid!) {\n    speakerMentions(query: $query, campaign_id: $campaign_id) {\n      rank\n      search {\n        id\n        video_title\n        url\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        upload_date: metadata(path: \"$.upload_date\")\n        published_at: metadata(path: \"$.published_at\")\n      }\n      start_time\n      line\n      sentiment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MentionsSearches($organization_id: uuid!) {\n    mentions_search(\n      where: { organization_id: { _eq: $organization_id }, deleted_at: { _is_null: true } }\n    ) {\n      title\n      search\n      created_at\n      deleted_at\n      updated_at\n      id\n      organization_id\n    }\n  }\n"): (typeof documents)["\n  query MentionsSearches($organization_id: uuid!) {\n    mentions_search(\n      where: { organization_id: { _eq: $organization_id }, deleted_at: { _is_null: true } }\n    ) {\n      title\n      search\n      created_at\n      deleted_at\n      updated_at\n      id\n      organization_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMentionsSearch(\n    $organization_id: uuid!\n    $title: String!\n    $search: String!\n    $created_at: timestamptz!\n  ) {\n    insert_mentions_search_one(\n      object: {\n        organization_id: $organization_id\n        title: $title\n        search: $search\n        created_at: $created_at\n        updated_at: $created_at\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMentionsSearch(\n    $organization_id: uuid!\n    $title: String!\n    $search: String!\n    $created_at: timestamptz!\n  ) {\n    insert_mentions_search_one(\n      object: {\n        organization_id: $organization_id\n        title: $title\n        search: $search\n        created_at: $created_at\n        updated_at: $created_at\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RenameMentionsSearch($id: uuid!, $title: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { title: $title, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RenameMentionsSearch($id: uuid!, $title: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { title: $title, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetMentionsSearchDeleteStatus(\n    $id: uuid!\n    $deleted_at: timestamptz!\n    $updated_at: timestamptz!\n  ) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { deleted_at: $deleted_at, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetMentionsSearchDeleteStatus(\n    $id: uuid!\n    $deleted_at: timestamptz!\n    $updated_at: timestamptz!\n  ) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { deleted_at: $deleted_at, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        deleted_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMentionsSearch($id: uuid!, $search: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { search: $search, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMentionsSearch($id: uuid!, $search: String!, $updated_at: timestamptz!) {\n    update_mentions_search(\n      where: { id: { _eq: $id } }\n      _set: { search: $search, updated_at: $updated_at }\n    ) {\n      returning {\n        id\n        title\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpsertMoment($object: moment_insert_input!) {\n    insert_moment_one(\n      object: $object\n      on_conflict: {\n        constraint: moment_pkey\n        update_columns: [\n          title\n          tags\n          start_time\n          end_time\n          updated_at\n          deleted\n          recommended_moment_id\n          annotations\n        ]\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertMoment($object: moment_insert_input!) {\n    insert_moment_one(\n      object: $object\n      on_conflict: {\n        constraint: moment_pkey\n        update_columns: [\n          title\n          tags\n          start_time\n          end_time\n          updated_at\n          deleted\n          recommended_moment_id\n          annotations\n        ]\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMoment($id: uuid!, $object: moment_set_input!) {\n    update_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMoment($id: uuid!, $object: moment_set_input!) {\n    update_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMoments($ids: [uuid!]!, $updated_at: timestamptz!) {\n    update_moment(where: { id: { _in: $ids } }, _set: { deleted: true, updated_at: $updated_at }) {\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMoments($ids: [uuid!]!, $updated_at: timestamptz!) {\n    update_moment(where: { id: { _in: $ids } }, _set: { deleted: true, updated_at: $updated_at }) {\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Organizations {\n    organization {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n"): (typeof documents)["\n  query Organizations {\n    organization {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganizationsById($ids: [uuid!]) {\n    organization(where: { id: { _in: $ids } }) {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n"): (typeof documents)["\n  query OrganizationsById($ids: [uuid!]) {\n    organization(where: { id: { _in: $ids } }) {\n      id\n      name\n      image\n      website\n      email\n      onboarded\n      subscription_status\n      subscription_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganizationMembersById($org_id: uuid!) {\n    organization_member(where: { organization_id: { _eq: $org_id } }) {\n      account {\n        id\n        email\n        name\n      }\n      role\n    }\n  }\n"): (typeof documents)["\n  query OrganizationMembersById($org_id: uuid!) {\n    organization_member(where: { organization_id: { _eq: $org_id } }) {\n      account {\n        id\n        email\n        name\n      }\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrganization($id: uuid, $updates: organization_set_input) {\n    update_organization(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrganization($id: uuid, $updates: organization_set_input) {\n    update_organization(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ToggleMoment($id: uuid!, $rejected: Boolean!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: { rejected: $rejected }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ToggleMoment($id: uuid!, $rejected: Boolean!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: { rejected: $rejected }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateRecommendedMoment($id: uuid!, $object: recommended_moment_set_input!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateRecommendedMoment($id: uuid!, $object: recommended_moment_set_input!) {\n    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: $object) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription RecommendedMomentsBySearchID($id: Int!) {\n    recommended_moment_stream(\n      cursor: { initial_value: { created_at: \"2023-01-01\" }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      id\n      tags\n      title\n      moment_time\n      annotations\n      rejected\n      created_at\n      moments {\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription RecommendedMomentsBySearchID($id: Int!) {\n    recommended_moment_stream(\n      cursor: { initial_value: { created_at: \"2023-01-01\" }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      id\n      tags\n      title\n      moment_time\n      annotations\n      rejected\n      created_at\n      moments {\n        deleted\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllSearches($limit: Int, $startTime: timestamptz, $endTime: timestamptz) {\n    search(\n      order_by: { id: desc }\n      where: { deleted: { _eq: false }, created_at: { _gte: $startTime, _lte: $endTime } }\n      limit: $limit\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_duration\n      video_id\n      created_at\n      campaign_id\n      user_id\n    }\n  }\n"): (typeof documents)["\n  query AllSearches($limit: Int, $startTime: timestamptz, $endTime: timestamptz) {\n    search(\n      order_by: { id: desc }\n      where: { deleted: { _eq: false }, created_at: { _gte: $startTime, _lte: $endTime } }\n      limit: $limit\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_duration\n      video_id\n      created_at\n      campaign_id\n      user_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActiveSearches($user_id: String) {\n    search(\n      order_by: { id: desc }\n      where: {\n        user_id: { _eq: $user_id }\n        deleted: { _eq: false }\n        _or: [\n          { status: { _in: [\"in-progress\", \"error\"] } }\n          { status_description: { _eq: \"stopped\" } }\n        ]\n      }\n    ) {\n      created_at\n      id\n      origin_type\n      search_progress\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      metadata\n      status\n      status_description\n    }\n  }\n"): (typeof documents)["\n  query ActiveSearches($user_id: String) {\n    search(\n      order_by: { id: desc }\n      where: {\n        user_id: { _eq: $user_id }\n        deleted: { _eq: false }\n        _or: [\n          { status: { _in: [\"in-progress\", \"error\"] } }\n          { status_description: { _eq: \"stopped\" } }\n        ]\n      }\n    ) {\n      created_at\n      id\n      origin_type\n      search_progress\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      thumbnail_url\n      metadata\n      status\n      status_description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchHistory($user_id: String, $limit: Int) {\n    search(\n      order_by: { id: desc }\n      where: { user_id: { _eq: $user_id }, deleted: { _eq: false }, status: { _eq: \"stopped\" } }\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      created_at\n      archived_at\n      origin_type\n      thumbnail_url\n      metadata\n      moments_aggregate(where: { deleted: { _eq: false } }) {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchHistory($user_id: String, $limit: Int) {\n    search(\n      order_by: { id: desc }\n      where: { user_id: { _eq: $user_id }, deleted: { _eq: false }, status: { _eq: \"stopped\" } }\n    ) {\n      id\n      status\n      status_description\n      search_progress\n      workflow_status\n      url\n      video_title\n      video_id\n      video_type\n      video_duration\n      created_at\n      archived_at\n      origin_type\n      thumbnail_url\n      metadata\n      moments_aggregate(where: { deleted: { _eq: false } }) {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchByID($id: Int!) {\n    search_by_pk(id: $id) {\n      user_id\n      search_progress\n      status\n      status_description\n      url\n      video_duration\n      video_title\n      video_type\n      video_id\n      origin_type\n      campaign_id\n      metadata\n      thumbnail_url\n      asr_transcript\n      moments {\n        updated_at\n        title\n        tags\n        start_time\n        search_id\n        id\n        end_time\n        created_at\n        deleted\n        recommended_moment_id\n        annotations\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchByID($id: Int!) {\n    search_by_pk(id: $id) {\n      user_id\n      search_progress\n      status\n      status_description\n      url\n      video_duration\n      video_title\n      video_type\n      video_id\n      origin_type\n      campaign_id\n      metadata\n      thumbnail_url\n      asr_transcript\n      moments {\n        updated_at\n        title\n        tags\n        start_time\n        search_id\n        id\n        end_time\n        created_at\n        deleted\n        recommended_moment_id\n        annotations\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StopSearch($id: Int) {\n    update_search(\n      where: { id: { _eq: $id } }\n      _set: { status: \"stopped\", status_description: \"stopped\" }\n    ) {\n      returning {\n        id\n        created_at\n        origin_type\n        search_progress\n        status\n        status_description\n        url\n        user_id\n        video_duration\n        video_title\n        video_type\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StopSearch($id: Int) {\n    update_search(\n      where: { id: { _eq: $id } }\n      _set: { status: \"stopped\", status_description: \"stopped\" }\n    ) {\n      returning {\n        id\n        created_at\n        origin_type\n        search_progress\n        status\n        status_description\n        url\n        user_id\n        video_duration\n        video_title\n        video_type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSearchWorkflow($id: Int, $status: String) {\n    update_search(where: { id: { _eq: $id } }, _set: { workflow_status: $status }) {\n      returning {\n        id\n        workflow_status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetSearchWorkflow($id: Int, $status: String) {\n    update_search(where: { id: { _eq: $id } }, _set: { workflow_status: $status }) {\n      returning {\n        id\n        workflow_status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSearchArchiveStatus($id: Int, $date: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { archived_at: $date }) {\n      returning {\n        id\n        archived_at\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetSearchArchiveStatus($id: Int, $date: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { archived_at: $date }) {\n      returning {\n        id\n        archived_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSearch($id: Int, $deleted_at: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { deleted: true, deleted_at: $deleted_at }) {\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSearch($id: Int, $deleted_at: timestamptz) {\n    update_search(where: { id: { _eq: $id } }, _set: { deleted: true, deleted_at: $deleted_at }) {\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAllSearchHistory($user_id: String, $deleted_at: timestamptz) {\n    update_search(\n      where: { user_id: { _eq: $user_id }, status: { _in: [\"stopped\", \"error\"] } }\n      _set: { deleted: true, deleted_at: $deleted_at }\n    ) {\n      affected_rows\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAllSearchHistory($user_id: String, $deleted_at: timestamptz) {\n    update_search(\n      where: { user_id: { _eq: $user_id }, status: { _in: [\"stopped\", \"error\"] } }\n      _set: { deleted: true, deleted_at: $deleted_at }\n    ) {\n      affected_rows\n      returning {\n        id\n        deleted\n        deleted_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClearCampaignIdSearch($id: Int!) {\n    update_search(where: { id: { _eq: $id } }, _set: { campaign_id: null }) {\n      returning {\n        id\n        campaign_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ClearCampaignIdSearch($id: Int!) {\n    update_search(where: { id: { _eq: $id } }, _set: { campaign_id: null }) {\n      returning {\n        id\n        campaign_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSearch($id: Int!, $updates: search_set_input) {\n    update_search(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSearch($id: Int!, $updates: search_set_input) {\n    update_search(where: { id: { _eq: $id } }, _set: $updates) {\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription SignalsBySearchID($id: Int!) {\n    signals_stream(\n      cursor: { initial_value: { id: 1 }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      from_seconds\n      x_values\n      y_values\n      title\n    }\n  }\n"): (typeof documents)["\n  subscription SignalsBySearchID($id: Int!) {\n    signals_stream(\n      cursor: { initial_value: { id: 1 }, ordering: ASC }\n      batch_size: 20000\n      where: { search_id: { _eq: $id } }\n    ) {\n      from_seconds\n      x_values\n      y_values\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LatestOrgSubscriptionEvent($organization_id: uuid!) {\n    subscription_event(\n      where: { organization_id: { _eq: $organization_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n"): (typeof documents)["\n  query LatestOrgSubscriptionEvent($organization_id: uuid!) {\n    subscription_event(\n      where: { organization_id: { _eq: $organization_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LatestUserSubscriptionEvent($account_id: uuid!) {\n    subscription_event(\n      where: { account_id: { _eq: $account_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n"): (typeof documents)["\n  query LatestUserSubscriptionEvent($account_id: uuid!) {\n    subscription_event(\n      where: { account_id: { _eq: $account_id } }\n      order_by: { updated_at: desc }\n      limit: 1\n    ) {\n      status\n      ends_at\n      card_brand\n      card_last_four\n      renews_at\n      product_id\n      variant_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompleteTutorial($account_id: uuid!, $tutorial: String!) {\n    insert_tutorial_progress_one(object: { account_id: $account_id, tutorial: $tutorial }) {\n      tutorial\n    }\n  }\n"): (typeof documents)["\n  mutation CompleteTutorial($account_id: uuid!, $tutorial: String!) {\n    insert_tutorial_progress_one(object: { account_id: $account_id, tutorial: $tutorial }) {\n      tutorial\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserClipRequests($account_id: uuid!, $requested_at: timestamptz!) {\n    user_clip_request(\n      where: {\n        account_id: { _eq: $account_id }\n        requested_at: { _gte: $requested_at }\n        clip_request: { search: { deleted: { _eq: false } } }\n      }\n    ) {\n      clip_request {\n        moment\n        action\n        search {\n          channel: metadata(path: \"$.channel\")\n          user_name: metadata(path: \"$.user_name\")\n        }\n        clip_results {\n          id\n          url\n          expires_at\n        }\n      }\n      requested_at\n    }\n  }\n"): (typeof documents)["\n  query UserClipRequests($account_id: uuid!, $requested_at: timestamptz!) {\n    user_clip_request(\n      where: {\n        account_id: { _eq: $account_id }\n        requested_at: { _gte: $requested_at }\n        clip_request: { search: { deleted: { _eq: false } } }\n      }\n    ) {\n      clip_request {\n        moment\n        action\n        search {\n          channel: metadata(path: \"$.channel\")\n          user_name: metadata(path: \"$.user_name\")\n        }\n        clip_results {\n          id\n          url\n          expires_at\n        }\n      }\n      requested_at\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertUserClipRequest(\n    $account_id: uuid!\n    $clip_request_id: Int!\n    $requested_at: timestamptz!\n  ) {\n    insert_user_clip_request_one(\n      object: {\n        account_id: $account_id\n        clip_request_id: $clip_request_id\n        requested_at: $requested_at\n      }\n    ) {\n      account_id\n      clip_request_id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertUserClipRequest(\n    $account_id: uuid!\n    $clip_request_id: Int!\n    $requested_at: timestamptz!\n  ) {\n    insert_user_clip_request_one(\n      object: {\n        account_id: $account_id\n        clip_request_id: $clip_request_id\n        requested_at: $requested_at\n      }\n    ) {\n      account_id\n      clip_request_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrgGroups($org_id: uuid!) {\n    user_group(where: { org_id: { _eq: $org_id } }) {\n      name\n      user_group_members {\n        account_id\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrgGroups($org_id: uuid!) {\n    user_group(where: { org_id: { _eq: $org_id } }) {\n      name\n      user_group_members {\n        account_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserGroupByAccountQuery($account_id: uuid!) {\n    user_group_member(where: { account_id: { _eq: $account_id } }) {\n      user_group_id\n      user_group {\n        org_id\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserGroupByAccountQuery($account_id: uuid!) {\n    user_group_member(where: { account_id: { _eq: $account_id } }) {\n      user_group_id\n      user_group {\n        org_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserGroupCampaignById($org_id: uuid, $account_id: uuid) {\n    # Fetch campaigns the user is allowed to view based on user group.\n    campaign(\n      where: {\n        user_group_campaigns: {\n          user_group: {\n            org_id: { _eq: $org_id }\n            user_group_members: { account_id: { _eq: $account_id } }\n          }\n        }\n      }\n      order_by: { created_at: desc }\n    ) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserGroupCampaignById($org_id: uuid, $account_id: uuid) {\n    # Fetch campaigns the user is allowed to view based on user group.\n    campaign(\n      where: {\n        user_group_campaigns: {\n          user_group: {\n            org_id: { _eq: $org_id }\n            user_group_members: { account_id: { _eq: $account_id } }\n          }\n        }\n      }\n      order_by: { created_at: desc }\n    ) {\n      id\n      title\n      user_id\n      created_at\n      archived_at\n      status\n      image\n      description\n      start_date\n      end_date\n      organization_id\n      searches {\n        created_at\n        video_duration\n        channel: metadata(path: \"$.channel\")\n        user_name: metadata(path: \"$.user_name\")\n        language: metadata(path: \"$.language\")\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;