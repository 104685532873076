import { graphql } from "../gql";

// Queries
// Get all organizations (use for admin)
export const OrganizationsQuery = graphql(`
  query Organizations {
    organization {
      id
      name
      image
      website
      email
      onboarded
      subscription_status
      subscription_id
    }
  }
`);

export const OrganizationsByIdQuery = graphql(`
  query OrganizationsById($ids: [uuid!]) {
    organization(where: { id: { _in: $ids } }) {
      id
      name
      image
      website
      email
      onboarded
      subscription_status
      subscription_id
    }
  }
`);

export const OrganizationMembersByIdQuery = graphql(`
  query OrganizationMembersById($org_id: uuid!) {
    organization_member(where: { organization_id: { _eq: $org_id } }) {
      account {
        id
        email
        name
      }
      role
    }
  }
`);

// Mutations
// Update org with desired parameters
export const UpdateOrganizationMutation = graphql(`
  mutation UpdateOrganization($id: uuid, $updates: organization_set_input) {
    update_organization(where: { id: { _eq: $id } }, _set: $updates) {
      returning {
        id
      }
    }
  }
`);
