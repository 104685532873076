import { graphql } from "../gql";

// Queries
export const AccessKeysByKindeIdQuery = graphql(`
  query AccessKeysByKindeId($account_id: uuid!) {
    access_key_with_expiration(where: { claimed_by: { _eq: $account_id } }) {
      access_key
      key_name
      claimed_at
      claimed_by
      campaign_id
      active_duration
      expires_at
    }
  }
`);

export const GetKeysByCampaignIdsQuery = graphql(`
  query GetKeysByCampaignIds($campaignIds: [uuid!]) {
    access_key_with_expiration(where: { campaign_id: { _in: $campaignIds } }) {
      access_key
      key_name
      created_at
      claimed_at
      claimed_by
      campaign_id
      active_duration
      expires_at
    }
  }
`);

// Mutations
export const InsertAccessKeyMutation = graphql(`
  mutation InsertAccessKey(
    $key_name: String!
    $campaign_id: uuid!
    $active_duration: interval!
    $access_key: uuid
  ) {
    insert_access_key(
      objects: {
        key_name: $key_name
        campaign_id: $campaign_id
        active_duration: $active_duration
        access_key: $access_key
        claimed_at: null
        claimed_by: null
      }
    ) {
      returning {
        key_name
        campaign_id
        active_duration
        access_key
      }
    }
  }
`);
