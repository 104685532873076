import { Box, VStack, Heading, Text, Divider, HStack, Switch, Link } from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import * as amplitude from "@amplitude/analytics-browser";

interface OpportunityOption {
  id: "allow_spotlights" | "allow_promotions" | "allow_feedback" | "allow_machine_learning";
  title: string;
  description: string;
}

const Notifications = () => {
  const { account, accountUpdateAPI } = useContext(AccountContext);

  const notificationOptions = [
    {
      id: "completionNotification",
      title: "Analysis completion",
      description: "Receive an email when Rivr is done analyzing your video.",
    },
  ];

  const opportunityOptions: OpportunityOption[] = [
    {
      id: "allow_spotlights",
      title: "Spotlights",
      description:
        "Have your content featured on Rivr and our social channels, helping you reach a wider audience.",
    },
    {
      id: "allow_promotions",
      title: "Promotions",
      description:
        "Get access to exclusive opportunities to collaborate with brands using Rivr and grow your presence.",
    },
    {
      id: "allow_feedback",
      title: "Feedback",
      description:
        "Join our community of creators shaping Rivr by sharing your insights through surveys and interviews.",
    },
    {
      id: "allow_machine_learning",
      title: "Machine Learning",
      description:
        "Help us improve Rivr’s algorithms to better surface your best moments and make future discoveries easier.",
    },
  ];

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <Box w={"100%"} h={"100%"} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} spacing={12} pt={6}>
        <HStack
          alignItems={"center"}
          w={"100%"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          spacing={0}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as={"h1"} fontSize={"3xl"}>
              Notifications
            </Heading>
            <Text color={"gray.300"}>
              Manage your preferences for receiving emails and opportunities from Rivr.
            </Text>
          </VStack>
        </HStack>

        <Divider />

        {/* Notification Options */}
        {notificationOptions.map((option) => (
          <VStack key={option.id} alignItems={"flex-start"} w={"100%"} spacing={6}>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap={6}
              wrap={"wrap"}
            >
              <VStack alignItems={"flex-start"} spacing={1}>
                <Text fontSize="lg">{option.title}</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  {option.description}
                </Text>
              </VStack>
              <Switch
                id={`notification-${option.id}`}
                size={"lg"}
                colorScheme={"green"}
                defaultChecked={account.email_notifications}
                onChange={(e) => {
                  accountUpdateAPI({
                    variables: {
                      id: account.id,
                      updates: { email_notifications: e.target.checked },
                    },
                  });
                  handleAmplitudeTrack("Notification Toggled", {
                    Option: option.id,
                    State: e.target.checked,
                  });
                }}
              />
            </HStack>
          </VStack>
        ))}

        <Divider />

        {/* Opportunity Options */}
        {opportunityOptions.map((option) => (
          <VStack key={option.id} alignItems={"flex-start"} w={"100%"} spacing={6}>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap={6}
              wrap={"wrap"}
            >
              <VStack alignItems={"flex-start"} spacing={1}>
                <Text fontSize={"lg"}>{option.title}</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  {option.description}
                </Text>
                {option.id === "allow_machine_learning" && (
                  <Link
                    href={"/privacy"}
                    target={"_blank"}
                    isExternal
                    mt={1}
                    fontSize={"xs"}
                    color={"gray.400"}
                    _hover={{ color: "purple.300", textDecor: "underline" }}
                  >
                    Learn how we respect your privacy while making Rivr better
                  </Link>
                )}
              </VStack>
              <Switch
                id={`opportunity-${option.id}`}
                size={"lg"}
                colorScheme={"green"}
                defaultChecked={account?.[option.id]}
                onChange={(e) => {
                  accountUpdateAPI({
                    variables: {
                      id: account.id,
                      updates: { [option.id]: e.target.checked },
                    },
                  });
                  handleAmplitudeTrack("Opportunity Toggled", {
                    Option: option.id,
                    State: e.target.checked,
                  });
                }}
              />
            </HStack>
          </VStack>
        ))}
      </VStack>
    </Box>
  );
};

export default Notifications;
