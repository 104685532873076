import { graphql } from "../gql";

// Queries
export const LatestOrgSubscriptionEventQuery = graphql(`
  query LatestOrgSubscriptionEvent($organization_id: uuid!) {
    subscription_event(
      where: { organization_id: { _eq: $organization_id } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      status
      ends_at
      card_brand
      card_last_four
      renews_at
      product_id
      variant_id
    }
  }
`);

export const LatestUserSubscriptionEventQuery = graphql(`
  query LatestUserSubscriptionEvent($account_id: uuid!) {
    subscription_event(
      where: { account_id: { _eq: $account_id } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      status
      ends_at
      card_brand
      card_last_four
      renews_at
      product_id
      variant_id
    }
  }
`);
