import { graphql } from "../gql";

// Mutations
export const CompleteTutorialMutation = graphql(`
  mutation CompleteTutorial($account_id: uuid!, $tutorial: String!) {
    insert_tutorial_progress_one(object: { account_id: $account_id, tutorial: $tutorial }) {
      tutorial
    }
  }
`);
