import { secondsToHMSDuration } from "../utils/time";
import { RivrMoment, rivrMomentFromQueryResult } from "./moment";

export type SearchMetadata = {
  userName: string;
  publishedAt: Date;
  language: string;
  viewCount: number;
  // DEPRECATED : Use RivrSearch.thumbnailUrl instead
  thumbnailUrl: string;
};

export type RivrSearch = {
  id: number;
  key: string;
  url: string;
  createdAt: Date;
  originType: string;
  videoTitle?: string;
  videoId: string;
  videoType: string;
  videoDuration: string;
  searchProgress: number;
  status: string;
  statusDescription?: string;
  workflowStatus?: string;
  archivedAt?: Date;
  userMoments: RivrMoment[];
  recommendedMoments: RivrMoment[];
  momentsCount?: number;
  thumbnailUrl?: string;
  posterUrl?: string;
  metadata?: SearchMetadata;
};

function parseYTDate(dateStr: string): string {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return new Date(`${year}-${month}-${day}`).toLocaleDateString("en-US");
}

function transformThumbnailURL(metadata: any, isYoutube?: boolean): string {
  const url = isYoutube ? metadata.thumbnail : metadata.thumbnail_url;
  return url?.replace("%{width}", "480").replace("%{height}", "270");
}

export function getSearchDisplayStatus(search: RivrSearch): string {
  if (search.archivedAt !== undefined) return "archived";
  if (search.workflowStatus !== undefined) return search.workflowStatus;
  if (["in-progress", "error"].includes(search.status)) {
    return search.status === "error" ? search.status : "analyzing";
  }
  return "analyzed";
}

export function activeSearchFromQueryResult(search: any): RivrSearch {
  const isYoutube = search.origin_type === "Youtube";

  return {
    id: search.id,
    key: search.id.toString(),
    url: search.url,
    createdAt: new Date(search.created_at),
    originType: search.origin_type,
    videoTitle: search.video_title,
    videoId: search.video_id,
    videoType: search.video_type,
    videoDuration: search.video_duration,
    searchProgress: search.search_progress,
    status: search.status,
    statusDescription: search.status_description,
    userMoments: [],
    recommendedMoments: [],
    thumbnailUrl: search.thumbnail_url,
    metadata:
      search.metadata === null
        ? undefined
        : {
            userName: isYoutube ? search.metadata.channel : search.metadata.user_name,
            publishedAt: new Date(
              isYoutube ? parseYTDate(search.metadata.upload_date) : search.metadata.published_at
            ),
            language: search.metadata.language || "none",
            thumbnailUrl: transformThumbnailURL(search.metadata, isYoutube),
            viewCount: search.metadata.view_count || 0,
          },
  };
}

export function searchFromQueryResult(search: any): RivrSearch {
  const isYoutube = search.origin_type === "Youtube";

  return {
    id: search.id,
    key: search.id.toString(),
    url: search.url,
    createdAt: new Date(search.created_at),
    originType: search.origin_type,
    videoTitle: search.video_title,
    videoId: search.video_id,
    videoType: search.video_type,
    videoDuration: search.video_duration,
    searchProgress: search.search_progress,
    status: search.status,
    statusDescription: search.status_description,
    workflowStatus: search.workflow_status ? search.workflow_status : undefined,
    archivedAt: search.archived_at ? new Date(search.archived_at) : undefined,
    userMoments: search.moments
      ? search.moments.map((moment: any) => rivrMomentFromQueryResult(moment, search))
      : [],
    recommendedMoments: search.recommended_moments
      ? search.recommended_moments.map((moment: any) => rivrMomentFromQueryResult(moment, search))
      : [],
    momentsCount: search.moments_aggregate ? search.moments_aggregate.aggregate.count : undefined,
    thumbnailUrl: search.thumbnail_url,
    posterUrl: search.poster_url,
    metadata:
      search.metadata === null
        ? undefined
        : {
            userName: isYoutube ? search.metadata.channel : search.metadata.user_name,
            publishedAt: new Date(
              isYoutube ? parseYTDate(search.metadata.upload_date) : search.metadata.published_at
            ),
            thumbnailUrl: transformThumbnailURL(search.metadata, isYoutube),
            language: search.metadata.language || "none",
            viewCount: search.metadata.view_count || 0,
          },
  };
}

export function twitchMetadataFromQueryResult(metadata: any): RivrSearch {
  const id = metadata.id;
  const title = metadata.title;
  const channel = metadata.channelName;
  const url = metadata.url;
  const duration = metadata.duration;
  const publishedAt = metadata.publishedAt;
  const language = metadata.language;

  return {
    id: 0,
    key: `twitch${id}`,
    url: url,
    createdAt: new Date(),
    originType: "Twitch",
    videoTitle: title,
    videoId: id,
    videoType: "VOD",
    videoDuration: secondsToHMSDuration(duration),
    status: "metadata",
    searchProgress: 0,
    userMoments: [],
    recommendedMoments: [],
    thumbnailUrl: metadata.thumbnailUrl,
    metadata: {
      userName: channel,
      publishedAt: new Date(publishedAt),
      thumbnailUrl: transformThumbnailURL(metadata),
      language: language,
      viewCount: 0,
    },
  };
}
