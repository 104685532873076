import { graphql } from "../gql";

// Queries
export const PaymentPortalQuery = graphql(`
  query PaymentPortal($org: String) {
    getPaymentPortal(org: $org) {
      customerPortal
      paymentMethod
    }
  }
`);

export const GetVideosQuery = graphql(`
  query GetVideos($platform: Platform!) {
    getVideos(platform: $platform) {
      videos {
        id
        title
        channelName
        url
        thumbnailUrl
        duration
        publishedAt
        language
      }
      nextPageCursor
    }
  }
`);

export const GetTrialQuotaQuery = graphql(`
  query GetTrialQuota {
    getTrialQuota {
      used
      total
    }
  }
`);

// Mutations
export const ClipRequestMutation = graphql(`
  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {
    clipRequest(requestsInput: $requests) {
      id
      moment_id
    }
  }
`);

export const SearchMutation = graphql(`
  mutation StartSearch($searchRequest: SearchRequestInput!) {
    startSearch(searchRequestInput: $searchRequest) {
      id
    }
  }
`);

export const DisconnectTwitchMutation = graphql(`
  mutation DisconnectTwitch {
    disconnectTwitch {
      accountId
    }
  }
`);
