import { graphql } from "../gql";

// Mutations
export const InsertCampaignWatcherMutation = graphql(`
  mutation InsertCampaignWatcher($campaign_id: uuid!, $twitch_game_id: String!) {
    insert_campaign_game(objects: { campaign_id: $campaign_id, twitch_game_id: $twitch_game_id }) {
      returning {
        campaign_id
        twitch_game_id
      }
    }
  }
`);
