import { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertProps,
  CloseButton,
  HStack,
  Box,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowCircleUp,
} from "@icon-park/react";
import { keyframes } from "@emotion/react";
import * as amplitude from "@amplitude/analytics-browser";

type AlertStatus = "info" | "warning" | "success" | "error";
type AlertSize = "sm" | "md" | "lg";
type ArrowDirection = "up" | "down" | "left" | "right";

interface ContextualTooltipProps extends Omit<AlertProps, "status" | "size"> {
  status: AlertStatus;
  size?: AlertSize;
  title?: string;
  description: string;
  localStorageTitle?: string;
  arrow?: ArrowDirection;
}

const arrowColorMap: Record<AlertStatus, string> = {
  info: "blue.200",
  warning: "yellow.200",
  error: "red.200",
  success: "green.200",
};

const arrowIconMap: Record<ArrowDirection, React.FC<{ size: string; fill: string }>> = {
  up: ArrowCircleUp,
  down: ArrowCircleDown,
  left: ArrowCircleLeft,
  right: ArrowCircleRight,
};

const nudgeUp = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-0.2rem); }
`;

const nudgeDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(0.2rem); }
`;

const nudgeLeft = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-0.2rem); }
`;

const nudgeRight = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(0.2rem); }
`;

const arrowNudgeMap: Record<ArrowDirection, string> = {
  up: nudgeUp,
  down: nudgeDown,
  left: nudgeLeft,
  right: nudgeRight,
};

const ContextualTooltip: React.FC<ContextualTooltipProps> = ({
  status,
  size = "md",
  title,
  description,
  localStorageTitle,
  arrow,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(() => {
    if (!localStorageTitle) return true;
    return !localStorage.getItem(localStorageTitle);
  });

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const handleClose = () => {
    if (localStorageTitle) {
      localStorage.setItem(localStorageTitle, "true");
    }
    setIsVisible(false);
    handleAmplitudeTrack("Tooltip Dismissed", { Component: localStorageTitle });
  };

  if (!isVisible) return null;

  const paddingProps = size === "sm" ? { px: 3, py: 2 } : {};
  const arrowColor = arrowColorMap[status] ?? "blue.200";
  const ArrowIcon = arrow && arrowIconMap[arrow];
  const iconProps = { size: "22", fill: "currentColor" };
  const nudgeAnimation = arrow ? arrowNudgeMap[arrow] : undefined;

  return (
    <Alert
      status={status}
      fontSize={size}
      borderRadius="md"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...paddingProps}
      {...props}
    >
      <HStack flexDir={arrow === "right" ? "row-reverse" : "row"} w={"full"}>
        <HStack>
          {ArrowIcon ? (
            <Box
              color={arrowColor}
              sx={{
                animation: nudgeAnimation
                  ? `${nudgeAnimation} 0.66s ease-in-out infinite alternate`
                  : "none",
              }}
            >
              <ArrowIcon {...iconProps} />
            </Box>
          ) : (
            <AlertIcon mr={0} />
          )}
        </HStack>
        <VStack align="start" gap={0} w="full">
          {title && (
            <AlertTitle mr={2} fontSize={size}>
              {title}
            </AlertTitle>
          )}
          <AlertDescription fontSize={size}>{description}</AlertDescription>
        </VStack>

        {localStorageTitle && (
          <CloseButton onClick={handleClose} aria-label="Close tooltip" size={size} />
        )}
      </HStack>
    </Alert>
  );
};

export default ContextualTooltip;
