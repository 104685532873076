import { Fragment, useContext, useState } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Switch,
  Link,
  Divider,
  Icon,
  ListItem,
  List,
} from "@chakra-ui/react";
import { BrainCog, CircleCheckBig, CircleSlash, Handshake, Sparkles, Sticker } from "lucide-react";
import { AccountContext } from "../../context/AccountContext";
import * as amplitude from "@amplitude/analytics-browser";
import PlanCard from "../PlanCard/PlanCard";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onOnboardingComplete?: () => void;
}

const Onboarding = ({ setIsOpen, step, setStep, onOnboardingComplete }: Props) => {
  const { account, accountUpdateAPI } = useContext(AccountContext);

  const [roles, setRoles] = useState<string[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  // Define the roles as an array of objects
  const roleOptions = [
    { value: "live-streamer", label: "Live Streamer" },
    { value: "content-creator", label: "Content Creator" },
    { value: "video-editor", label: "Video Editor" },
    { value: "esport-athlete", label: "Esport Athlete" },
    { value: "talent-manager", label: "Talent Manager" },
    { value: "industry-pro", label: "Industry Professional" },
    { value: "other", label: "Other" },
  ];

  // Define the platform options
  const platformOptions = [
    { value: "tiktok", label: "TikTok" },
    { value: "twitter", label: "X/Twitter" },
    { value: "youtube", label: "YouTube" },
    { value: "instagram", label: "Instagram" },
    { value: "threads", label: "Threads" },
    { value: "facebook", label: "Facebook" },
    { value: "bluesky", label: "Bluesky" },
    { value: "discord", label: "Discord" },
    { value: "other", label: "Other" },
  ];

  // Define the opt-in options
  const optInOptions = [
    {
      value: "spotlights",
      label: "Spotlights",
      icon: Sparkles,
      sublabel:
        "Have your content featured on Rivr and our social channels, helping you reach a wider audience.",
    },
    {
      value: "promotions",
      label: "Promotions",
      icon: Handshake,
      sublabel:
        "Get access to exclusive opportunities to collaborate with brands using Rivr and grow your presence.",
    },
    {
      value: "feedback",
      label: "Feedback",
      icon: Sticker,
      sublabel:
        "Join our community of creators shaping Rivr by sharing your insights through surveys and interviews.",
    },
    {
      value: "machineLearning",
      label: "Machine Learning",
      icon: BrainCog,
      sublabel:
        "Help us improve Rivr's algorithms to better surface your best moments and make future discoveries easier.",
    },
  ];

  // Initialize optInStates
  const initialOptInStates = optInOptions.reduce((acc: any, option) => {
    acc[option.value] = true;
    return acc;
  }, {});

  const [optInStates, setOptInStates] = useState(initialOptInStates);

  const handleRolesChange = (value: string) => {
    if (roles.includes(value)) {
      setRoles(roles.filter((role) => role !== value));
    } else {
      setRoles([...roles, value]);
    }
  };

  const handlePlatformsChange = (value: string) => {
    if (platforms.includes(value)) {
      setPlatforms(platforms.filter((platform) => platform !== value));
    } else {
      setPlatforms([...platforms, value]);
    }
  };

  const handleOptInChange = (id: string) => {
    setOptInStates({
      ...optInStates,
      [id]: !optInStates[id],
    });
  };

  const handleOnboardingComplete = async () => {
    setLoading(true);
    try {
      await accountUpdateAPI({
        variables: {
          id: account.id,
          updates: {
            onboarding_response: {
              roles: roles,
              platforms: platforms,
            },
            allow_spotlights: optInStates.spotlights,
            allow_promotions: optInStates.promotions,
            allow_feedback: optInStates.feedback,
            allow_machine_learning: optInStates.machineLearning,
          },
        },
      });
      handleAmplitudeTrack("Onboarding Completed");
      setStep(4);
    } finally {
      setLoading(false);
      if (onOnboardingComplete !== undefined) onOnboardingComplete();
    }
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <VStack w={"100%"} h={"100%"} alignItems={"center"}>
      {/* Step 1 */}
      {step === 1 && (
        <VStack w={"100%"} gap={4}>
          <VStack w={"100%"} align={"start"} gap={0}>
            <Text fontSize={"xl"}>While your video analyzes, we'd like to know more about you</Text>
            <Text color={"gray.300"}>Select all of the roles that apply to you</Text>
          </VStack>
          <VStack w={"100%"} gap={2}>
            {roleOptions.map((role) => (
              <Box
                key={role.value}
                py={2}
                w={"100%"}
                textAlign={"center"}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={roles.includes(role.value) ? "teal.300" : "inherit"}
                bg={roles.includes(role.value) ? "teal.900" : "transparent"}
                _hover={{ cursor: "pointer", bg: "teal.900" }}
                transition={"all 0.2s ease-in-out"}
                onClick={() => handleRolesChange(role.value)}
              >
                <Text>{role.label}</Text>
              </Box>
            ))}
          </VStack>
          <Box w={"100%"} textAlign={"right"}>
            <Button
              mt={6}
              colorScheme={"green"}
              isDisabled={roles.length === 0}
              onClick={() => setStep(2)}
            >
              Continue
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 2 */}
      {step === 2 && (
        <VStack w={"100%"} gap={4}>
          <VStack w={"100%"} align={"start"} gap={0}>
            <Text fontSize={"xl"}>What platforms do you currently post clips/highlights on?</Text>
            <Text color={"gray.300"}>Select all that apply</Text>
          </VStack>
          <VStack w={"100%"} gap={2}>
            {platformOptions.map((platform) => (
              <Box
                key={platform.value}
                py={2}
                w={"100%"}
                textAlign={"center"}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={platforms.includes(platform.value) ? "teal.300" : "inherit"}
                bg={platforms.includes(platform.value) ? "teal.900" : "transparent"}
                _hover={{ cursor: "pointer", bg: "teal.900" }}
                transition={"all 0.2s ease-in-out"}
                onClick={() => handlePlatformsChange(platform.value)}
              >
                <Text>{platform.label}</Text>
              </Box>
            ))}
          </VStack>
          <HStack w={"100%"} justifyContent={"space-between"} mt={6}>
            <Button variant={"ghost"} onClick={() => setStep(1)}>
              Back
            </Button>
            <Button
              colorScheme={"green"}
              isDisabled={platforms.length === 0}
              onClick={() => setStep(3)}
            >
              Continue
            </Button>
          </HStack>
        </VStack>
      )}

      {/* Step 3 */}
      {step === 3 && (
        <VStack w={"100%"} gap={4}>
          <VStack w={"100%"} align={"start"} gap={0}>
            <Text fontSize={"xl"}>Opportunities</Text>
            <Text color={"gray.300"} sx={{ textWrap: "pretty" }}>
              Choose your preferences below. You can update them anytime in your account settings.
            </Text>
          </VStack>
          <VStack w={"100%"} gap={4}>
            {optInOptions.map((option) => (
              <Fragment key={option.value}>
                <HStack w={"100%"} justifyContent={"space-between"}>
                  <VStack align="start" gap={0.5} mr={4}>
                    <HStack>
                      <Box as={option.icon} boxSize={5} color={"teal.300"} />
                      <Text>{option.label}</Text>
                    </HStack>
                    <Text fontSize={"sm"} color={"gray.300"} sx={{ textWrap: "pretty" }}>
                      {option.sublabel}
                    </Text>
                    {option.value === "machineLearning" && (
                      <Link
                        href={"/privacy"}
                        target={"_blank"}
                        isExternal
                        mt={1}
                        fontSize={"xs"}
                        color={"gray.400"}
                      >
                        Learn how we respect your privacy while making Rivr better
                      </Link>
                    )}
                  </VStack>
                  <Switch
                    colorScheme={"green"}
                    size={"lg"}
                    isChecked={optInStates[option.value]}
                    onChange={(e) => {
                      handleOptInChange(option.value);
                      handleAmplitudeTrack("Opt-In Option Changed", {
                        Option: option.value,
                        State: e.target.checked,
                      });
                    }}
                  />
                </HStack>
                <Divider _last={{ display: "none" }} />
              </Fragment>
            ))}
          </VStack>
          <HStack w={"100%"} justifyContent={"space-between"} mt={6}>
            <Button variant={"ghost"} onClick={() => setStep(2)}>
              Back
            </Button>
            <Button colorScheme={"green"} isLoading={loading} onClick={handleOnboardingComplete}>
              Continue
            </Button>
          </HStack>
        </VStack>
      )}

      {/* Step 4 */}
      {step === 4 && (
        <VStack w={"100%"} gap={4}>
          <VStack w={"100%"} align={"start"} gap={0}>
            <Text fontSize={"xl"}>Choose a plan</Text>
            <Text color={"gray.300"} sx={{ textWrap: "pretty" }}>
              Unlock Rivr's full potential, or continue with the free trial.
            </Text>
          </VStack>
          <HStack w={"100%"} gap={4} alignItems={"stretch"}>
            <VStack align={"stretch"} flex={1}>
              <Box
                w={"100%"}
                bg={"whiteAlpha.200"}
                borderRadius={"lg"}
                p={6}
                borderWidth={2}
                mt={12}
                flex={1}
              >
                <VStack
                  alignItems={"flex-start"}
                  flex={1}
                  justifyContent={"space-between"}
                  h={"100%"}
                >
                  <VStack w={"100%"} align={"start"}>
                    <HStack justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
                      <VStack gap={-1} pb={3} align={"flex-start"}>
                        <Text fontSize={32} fontWeight={"semibold"}>
                          Free Trial
                        </Text>
                        <Text fontSize={"xs"} color={"gray.300"}>
                          Early Access
                        </Text>
                      </VStack>
                    </HStack>
                    <List spacing={2} pb={4}>
                      <ListItem>
                        <HStack alignItems={"center"} justifyContent={"flex-start"}>
                          <Icon as={CircleCheckBig} color={"green.300"} boxSize={4} />
                          <Text>Analyze 3 Free Twitch VODs</Text>
                        </HStack>
                      </ListItem>
                      <ListItem>
                        <HStack alignItems={"center"} justifyContent={"flex-start"}>
                          <Icon as={CircleCheckBig} color={"green.300"} boxSize={4} />
                          <Text>Starter Plan Features</Text>
                        </HStack>
                      </ListItem>
                      <ListItem>
                        <HStack alignItems={"center"} justifyContent={"flex-start"}>
                          <Icon as={CircleSlash} color={"red.300"} boxSize={4} />
                          <Text>Creator Opportunities</Text>
                        </HStack>
                      </ListItem>
                      <ListItem>
                        <HStack alignItems={"center"} justifyContent={"flex-start"}>
                          <Icon as={CircleSlash} color={"red.300"} boxSize={4} />
                          <Text>Live Chat Support</Text>
                        </HStack>
                      </ListItem>
                    </List>
                  </VStack>
                  <Button
                    colorScheme={"gray"}
                    variant={"outline"}
                    onClick={() => {
                      handleAmplitudeTrack("Free Trial Chosen", {
                        Location: "Onboarding",
                      });
                      setIsOpen(false);
                    }}
                    size={"md"}
                    w={"full"}
                    minH={12}
                  >
                    Continue with Free Trial
                  </Button>
                </VStack>
              </Box>
            </VStack>
            <VStack flex={1} align="stretch">
              <PlanCard
                planType="EA"
                planAvailable={true}
                showSubscribe={true}
                subscriptionExists={false}
              />
            </VStack>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default Onboarding;
