import { Box, Button, Center, Heading, Text, VStack } from "@chakra-ui/react";
import { ArrowLeft } from "@icon-park/react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useEffect, useState, useRef } from "react";
import { Loading } from "../components/Loading/Loading";
import { CircleCheckBig, CircleAlert } from "lucide-react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AccountByKindeIdQuery } from "../api/account";
import * as amplitude from "@amplitude/analytics-browser";

export const BillingRedirect = () => {
  const { user } = useKindeAuth();
  const didTrackPageView = useRef(false);

  const accountQuery = useQuery(AccountByKindeIdQuery, {
    variables: { kinde_id: user?.id as string },
    pollInterval: 2000,
  });

  const hasSub =
    accountQuery.data?.account[0].subscription_status === "active" &&
    accountQuery.data?.account[0].subscription_id !== null;

  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimerExpired(true);
      handleAmplitudeTrack("Billing Redirect", { event: "Check Timed Out" });
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hasSub) {
      const timer = setTimeout(() => {
        setRedirectTimer(true);
        handleAmplitudeTrack("Billing Redirect", { event: "Subscription Verified" });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [hasSub]);

  useEffect(() => {
    if (!didTrackPageView.current) {
      handleAmplitudeTrack("Billing Redirect", { event: "Page Viewed" });
      didTrackPageView.current = true;
    }
  }, []);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  if (!hasSub && !isTimerExpired)
    return (
      <Center w={"100vw"} h={"100vh"} p={6}>
        <VStack gap={0} justifyContent={"center"}>
          <Loading />
          <VStack w={"100%"}>
            <Heading as={"h1"} fontSize={"2xl"}>
              Loading subscription details
            </Heading>
            <Text color={"gray.300"}>Please wait while we redirect you</Text>
          </VStack>
        </VStack>
      </Center>
    );
  else if (hasSub)
    return (
      <Center w={"100vw"} h={"100vh"} p={6}>
        <VStack gap={6} justifyContent={"center"}>
          <Box color={"green.300"}>
            <CircleCheckBig color={"currentColor"} size={"4rem"} />
          </Box>
          <VStack>
            <Heading as={"h1"} fontSize={"2xl"}>
              Purchase success
            </Heading>
            <Text color={"gray.300"}>Redirecting you to Rivr!</Text>
          </VStack>
          <Button colorScheme={"gray"} leftIcon={<ArrowLeft />} as={"a"} href={"/"}>
            Back to Rivr
          </Button>
          {redirectTimer && <Navigate to="/" />}
        </VStack>
      </Center>
    );
  else
    return (
      <Center w={"100vw"} h={"100vh"} p={6}>
        <VStack gap={6} justifyContent={"center"}>
          <Box color={"red.300"}>
            <CircleAlert color={"currentColor"} size={"4rem"} />
          </Box>
          <VStack w={"100%"}>
            <Heading as={"h1"} fontSize={"2xl"}>
              Error with subscription update
            </Heading>
            <VStack gap={0} w={"100%"}>
              <Text color={"gray.300"}>
                An error occured while trying to update your subscription.
              </Text>
              <Text color={"gray.300"}>
                Try refreshing the page, or contact support if the issue persists.
              </Text>
            </VStack>
          </VStack>
          <Button colorScheme={"gray"} leftIcon={<ArrowLeft />} as={"a"} href={"/"}>
            Back to Rivr
          </Button>
        </VStack>
      </Center>
    );
};
