import { useContext, useState } from "react";
import { Alert, AlertDescription, AlertIcon, Box, Flex } from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AccountContext } from "../context/AccountContext";
import { demoUserIds } from "../utils/demoUserIds";
import EmailCapture from "../components/EmailCapture/EmailCapture";
import { AccessKeysByKindeIdQuery } from "../api/access-key";
import { Loading } from "../components/Loading/Loading";
import ConvertSearchHeader from "../components/Conversion/ConvertSearchHeader";
import { RivrLocations } from "../models/navigation";
import { ActiveSearches } from "../components/ActiveSearches/ActiveSearches";
import { SearchHistory } from "../components/SearchHistory/SearchHistory";
import { SearchHistoryQuery } from "../api/search";
import { RivrSearch, searchFromQueryResult } from "../models/rivrSearch";
import OnboardingModal from "../components/Onboarding/OnboardingModal";
import { GetTrialQuotaQuery } from "../api/actions";
import { logApolloErrorsHandler } from "../utils/graphql-error";

const Search = () => {
  const { user } = useKindeAuth();
  const { account } = useContext(AccountContext);
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isOrgMember = account.memberships.length > 0;
  const completedOnboarding = account.onboarding_response !== null;

  const isBetaUser = account.beta_access as boolean;

  const demoUser = demoUserIds.includes(user?.id ?? "") as boolean;

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [activeKeys, setActiveKeys] = useState<any[]>([]);
  const activeSearchLimit = demoUserIds.includes(user?.id ?? "") ? 10 : 3;
  const [historyList, setHistoryList] = useState<RivrSearch[]>([]);
  const [remainingTrialQuota, setRemainingTrialQuota] = useState<number>(0);

  const hasSearchAccess = isAdmin || isBetaUser || account.subscription_status === "active";

  const showCampaignCTA = activeKeys.length !== 0;

  const { loading: keysLoading } = useQuery(AccessKeysByKindeIdQuery, {
    onCompleted(data) {
      if (data.access_key_with_expiration) {
        setActiveKeys(
          data.access_key_with_expiration.filter((key: any) => {
            return new Date(key.expires_at) >= new Date();
          })
        );
      }
    },
    onError: logApolloErrorsHandler,
    variables: { account_id: account.id },
  });

  const { refetch: getSearchHistory, loading: searchHistoryLoading } = useQuery(
    SearchHistoryQuery,
    {
      onCompleted(data) {
        const historyList = data.search.map((s: any) => searchFromQueryResult(s));
        setHistoryList(historyList.filter((s: RivrSearch) => s.statusDescription !== "stopped"));
      },
      onError({ graphQLErrors, networkError }) {
        console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
      },
      variables: { user_id: account.kinde_id },
    }
  );

  // Get user trial quota
  const { data: userTrialQuota } = useQuery(GetTrialQuotaQuery, {
    onCompleted(data) {
      if (data.getTrialQuota)
        setRemainingTrialQuota(data.getTrialQuota.total - data.getTrialQuota.used);
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
    },
  });

  const userTrialLimit = userTrialQuota?.getTrialQuota?.total;

  if (!user || !user.id) return null;

  return isAdmin || !isOrgMember ? (
    <>
      <OnboardingModal isOpen={isOnboardingOpen} setIsOpen={setIsOnboardingOpen} />
      <Box h={"100%"} position={"relative"} w={"100%"} className={"search-page"}>
        {demoUser && <EmailCapture />}

        <Box w={"100%"} bg={"blackAlpha.300"} borderBottomWidth={1}>
          {!keysLoading && showCampaignCTA ? (
            <Flex justifyContent={"center"} w={"100%"}>
              <Alert status={"success"} borderRadius={"none"} w={"full"} justifyContent={"center"}>
                <AlertIcon />
                <AlertDescription sx={{ textWrap: "pretty" }}>
                  You're participating in an ongoing campaign. Videos will automatically appear
                  shortly after streaming.
                </AlertDescription>
              </Alert>
            </Flex>
          ) : null}

          {!hasSearchAccess &&
            completedOnboarding &&
            userTrialLimit &&
            remainingTrialQuota === 0 && (
              <Flex py={4} justify={"center"} w={"100%"}>
                {<ConvertSearchHeader />}
              </Flex>
            )}
        </Box>

        <Box w={"100%"} maxW={"1600px"} mx={"auto"} pt={6}>
          {!searchHistoryLoading ? (
            <>
              <ActiveSearches
                hasSearchAccess={hasSearchAccess}
                activeSearchLimit={activeSearchLimit}
                refreshHistory={getSearchHistory}
                historyUrls={historyList
                  .filter((s) => s.statusDescription !== "stopped")
                  .map((s) => s.url)}
                completedOnboarding={completedOnboarding}
                setIsOnboardingOpen={setIsOnboardingOpen}
                remainingTrialQuota={remainingTrialQuota}
                setRemainingTrialQuota={setRemainingTrialQuota}
                trialQuotaLimit={userTrialLimit}
              />

              {historyList.length > 0 && (
                <SearchHistory searchList={historyList} getSearches={getSearchHistory} />
              )}
            </>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    </>
  ) : (
    <Navigate replace to={RivrLocations.Campaigns} />
  );
};
export default Search;
