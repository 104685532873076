import { graphql } from "../gql";

// Queries
// Get Clip Requests with Results
export const ClipRequestsBySearchIDQuery = graphql(`
  query ClipRequestsBySearchID($id: Int!) {
    clip_request(where: { search_id: { _eq: $id } }) {
      id
      timestamp
      lead_in
      lead_out
      created_at
      moment
      action
      clip_results {
        expires_at
      }
    }
  }
`);

// Get Clip Requests with Results
export const ClipRequestsByIDQuery = graphql(`
  query ClipRequestsByID($ids: [Int!]!) {
    clip_request(where: { id: { _in: $ids } }) {
      id
      created_at
      moment
      clip_results {
        url
      }
    }
  }
`);
