import { useContext, useCallback, useState, useEffect } from "react";
import { Box, IconButton, VStack } from "@chakra-ui/react";
import TourButton from "../Tour/TourButton";
import LiveChat from "../LiveChat/LiveChat";
import "../LiveChat/LiveChat.css";
import { AccountContext } from "../../context/AccountContext";
import { Message } from "@icon-park/react";

declare global {
  interface Window {
    $chatwoot?: {
      toggle: () => void;
      setUser: (id: string, attrs: { [key: string]: any }) => void;
      setCustomAttributes: (attrs: { [key: string]: any }) => void;
      setConversationCustomAttributes: (attrs: { [key: string]: any }) => void;
      run: (config: { websiteToken: string; baseUrl: string }) => void;
    };
  }
}

const GlobalActions = () => {
  const { account, memberships } = useContext(AccountContext);

  const isBetaUser = account.beta_access;

  const hasLiveChat =
    account.subscription_status === "active" || memberships.length > 0 || isBetaUser;

  const [isChatwootReady, setIsChatwootReady] = useState(false);

  useEffect(() => {
    const checkChatwootReady = () => {
      if (window.$chatwoot && typeof window.$chatwoot.toggle === "function") {
        setIsChatwootReady(true);
      }
    };
    checkChatwootReady();
    const interval = setInterval(checkChatwootReady, 500);
    return () => clearInterval(interval);
  }, []);

  const toggleChatwoot = useCallback(() => {
    if (window.$chatwoot && typeof window.$chatwoot.toggle === "function") {
      window.$chatwoot.toggle();
    }
  }, []);

  return (
    <Box
      position={"fixed"}
      bottom={3}
      right={4}
      zIndex={1000}
      className={"global-actions-wrapper"}
      visibility={{ base: "hidden", sm: "hidden", md: "visible" }}
    >
      <VStack align={"end"}>
        <TourButton />
        {hasLiveChat && (
          <IconButton
            aria-label="Live Chat"
            icon={<Message theme={"two-tone"} size={18} fill={["white", "rivr.red"]} />}
            bg={"rivr.red"}
            color={"white"}
            onClick={toggleChatwoot}
            colorScheme="red"
            size="md"
            borderRadius="full"
            isDisabled={!isChatwootReady}
          />
        )}
        <LiveChat />
      </VStack>
    </Box>
  );
};

export default GlobalActions;
