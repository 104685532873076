import {
  IconButton,
  Center,
  Box,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Flex,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Spacer,
  SimpleGrid,
  Image,
  MenuGroup,
  FormControl,
  FormHelperText,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Button,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Badge,
  ModalHeader,
  ModalFooter,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { Filter, SortThree } from "@icon-park/react";
import PlatformButton from "../Account/PlatformButton";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AccountContext } from "../../context/AccountContext";
import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { usePagination } from "../../hooks/usePagination";
import {
  activeSearchFromQueryResult,
  twitchMetadataFromQueryResult,
  RivrSearch,
} from "../../models/rivrSearch";
import Cookies from "js-cookie";
import { durationToSeconds, offsetDate } from "../../utils/time";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ActiveSearchesQuery } from "../../api/search";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import RivrLogo from "../../assets/rivr-logo-mark.svg";
import { Searchbar } from "../SearchBar/Searchbar";
import { ActiveSearchItem } from "./ActiveSearchItem";
import { GetVideosQuery } from "../../api/actions";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import BillingCta from "../BillingCta/BillingCta";
import * as amplitude from "@amplitude/analytics-browser";
import { Flame, HandMetal } from "lucide-react";
import { Platform } from "../../gql/graphql";

type ActiveSearchesPanel = {
  name: string;
  image: string;
  disabled: boolean;
  hidden?: boolean;
};

interface Props {
  hasSearchAccess: boolean;
  activeSearchLimit: number;
  refreshHistory: () => void;
  historyUrls: string[];
  completedOnboarding: boolean;
  setIsOnboardingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  remainingTrialQuota: number;
  setRemainingTrialQuota: React.Dispatch<React.SetStateAction<number>>;
  trialQuotaLimit?: number;
}

export const ActiveSearches = ({
  hasSearchAccess,
  activeSearchLimit,
  refreshHistory,
  historyUrls,
  completedOnboarding,
  setIsOnboardingOpen,
  remainingTrialQuota,
  setRemainingTrialQuota,
  trialQuotaLimit,
}: Props) => {
  const { account } = useContext(AccountContext);

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const { getBooleanFlag } = useKindeAuth();
  const allowURLSearch = getBooleanFlag("allow-url-search", false);

  const {
    isOpen: isSearchBarModalOpen,
    onClose: onSearchBarModalClose,
    onOpen: onSearchBarModalOpen,
  } = useDisclosure();

  const {
    isOpen: isFinalTrialCTAOpen,
    onClose: onFinalTrialCTAClose,
    onOpen: onFinalTrialCTAOpen,
  } = useDisclosure();
  const {
    isOpen: isTrialLimitCTAOpen,
    onClose: onTrialLimitCTAClose,
    onOpen: onTrialLimitCTAOpen,
  } = useDisclosure();

  const [metadataList, setMetadataList] = useState<RivrSearch[]>([]);
  const [searchList, setSearchList] = useState<RivrSearch[]>([]);
  const [activeSearchCount, setActiveSearchCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");
  const [sortMethod, setSortMethod] = useState("status");
  const [sortDirection, setSortDirection] = useState("desc");
  const twitchUser = account.twitch_username ?? null;
  const youtubeUser = null;

  const handleDateFilterStart = (date: string) => {
    setDateFilterStart(date);
    handleAmplitudeTrack("Adjust RTA Date Filter", {
      FilterType: "Start",
      Date: date,
    });
  };

  const handleDateFilterEnd = (date: string) => {
    setDateFilterEnd(date);
    handleAmplitudeTrack("Adjust RTA Date Filter", {
      FilterType: "End",
      Date: date,
    });
  };

  const resetSearchFilters = () => {
    setDateFilterStart("");
    setDateFilterEnd("");
    handleAmplitudeTrack("Reset RTA Filters");
  };

  const handleSortMethod = (option: string | string[]) => {
    typeof option === "string" ? setSortMethod(option) : setSortMethod(option[0]);
    handleAmplitudeTrack("Change RTA Sort Method", {
      SortMethod: option,
    });
  };

  const handleSortDirection = (direction: string | string[]) => {
    typeof direction === "string" ? setSortDirection(direction) : setSortDirection(direction[0]);
    handleAmplitudeTrack("Change RTA Sort Direction", {
      SortDirection: direction,
    });
  };

  // Get Twitch metadata
  const [getTwitchVideos] = useLazyQuery(GetVideosQuery, {
    onCompleted(data) {
      if (data.getVideos)
        setMetadataList(data.getVideos.videos.map((s: any) => twitchMetadataFromQueryResult(s)));
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
    },
    variables: { platform: Platform.Twitch },
  });

  useEffect(() => {
    if (twitchUser) {
      getTwitchVideos();
    } else {
      setMetadataList([]);
    }
  }, [twitchUser]);

  const { refetch: getActiveSearches } = useQuery(ActiveSearchesQuery, {
    onCompleted(data) {
      const activeSearches = data.search.filter((s: any) => s.status === "in-progress");
      setActiveSearchCount(activeSearches.length);
      setSearchList(data.search.map((s: any) => activeSearchFromQueryResult(s)));
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
    },
    variables: { user_id: account.kinde_id },
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  useEffect(() => {
    refreshHistory();
  }, [activeSearchCount]);

  // Combine metadata and active/stopped searches for usePagination
  // Filter metadata for already-run searches
  const elementsList: RivrSearch[] = useMemo(() => {
    const searchUrls = [...searchList.map((s) => s.url), ...historyUrls];
    return [...searchList, ...metadataList.filter((m) => searchUrls.includes(m.url) === false)];
  }, [searchList, metadataList, historyUrls]);

  const tabs: ActiveSearchesPanel[] = [
    {
      name: "Twitch",
      image: TwitchLogo,
      disabled: twitchUser === null && !elementsList.some((s) => s.originType === "Twitch"),
    },
    { name: "YouTube", image: YouTubeLogo, disabled: youtubeUser === null, hidden: !isAdmin },
    { name: "Rivr", image: RivrLogo, disabled: false, hidden: !isAdmin && !allowURLSearch },
  ].filter((tab) => !tab.hidden);

  // Filter searches for the selected tab
  const selectedTabElements: RivrSearch[] = useMemo(() => {
    return elementsList.filter((item) => {
      if (
        selectedTab === tabs.findIndex((tab) => tab.name === "Twitch") &&
        item.originType !== "Twitch"
      )
        return false;
      if (
        selectedTab === tabs.findIndex((tab) => tab.name === "YouTube") &&
        item.originType !== "Youtube"
      )
        return false;
      if (
        selectedTab === tabs.findIndex((tab) => tab.name === "Rivr") &&
        ["Twitch", "Youtube"].includes(item.originType)
      )
        return false;
      return true;
    });
  }, [selectedTab, elementsList]);

  const sortSearchList = useMemo(() => {
    return (a: RivrSearch, b: RivrSearch) => {
      if (sortMethod === "status") {
        const statusOrder: { [key: string]: number } = {
          metadata: 1,
          stopped: 2,
          error: 3,
          "in-progress": 4,
        };
        const orderA = statusOrder[a.workflowStatus === undefined ? a.status : a.workflowStatus];
        const orderB = statusOrder[b.workflowStatus === undefined ? b.status : b.workflowStatus];

        // Sort descending by date published if status is the same
        if ((orderA || 5) === (orderB || 5)) {
          // Put videos missing metadata (i.e. publishing date) at the end
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? (orderA || 5) - (orderB || 5)
            : (orderB || 5) - (orderA || 5);
        }
      }
      if (sortMethod === "published") {
        // Sort any videos missing metadata (i.e. publishing date) to the end of the list
        const valueB = b.metadata?.publishedAt.valueOf() || 0;
        const valueA = a.metadata?.publishedAt.valueOf() || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      if (sortMethod === "processed") {
        const valueB = b.createdAt.valueOf() || 0;
        const valueA = a.createdAt.valueOf() || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      if (sortMethod === "title") {
        if (
          a.videoTitle === b.videoTitle ||
          a.videoTitle === undefined ||
          b.videoTitle === undefined
        ) {
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? a.videoTitle.toLowerCase().localeCompare(b.videoTitle.toLowerCase())
            : b.videoTitle.toLowerCase().localeCompare(a.videoTitle.toLowerCase());
        }
      }
      if (sortMethod === "duration") {
        if (durationToSeconds(a.videoDuration) === durationToSeconds(b.videoDuration)) {
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? durationToSeconds(a.videoDuration) - durationToSeconds(b.videoDuration)
            : durationToSeconds(b.videoDuration) - durationToSeconds(a.videoDuration);
        }
      }
      return 0;
    };
  }, [sortMethod, sortDirection]);

  const filterSearchList = useMemo(() => {
    return (item: RivrSearch) => {
      const filterStartDateUTC = offsetDate(dateFilterStart);
      if (
        dateFilterStart &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() >= filterStartDateUTC.valueOf() === false
      )
        return false;
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (
        dateFilterEnd &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() < filterEndDateUTC.valueOf() === false
      )
        return false;
      return true;
    };
  }, [dateFilterStart, dateFilterEnd]);

  const {
    elements,
    totalElements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination(selectedTabElements, sortSearchList, filterSearchList, 15);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const getTabPanels = () => {
    return (
      <TabPanels my={-4}>
        {tabs.map((tab: ActiveSearchesPanel) => (
          <React.Fragment key={tab.name.toLowerCase()}>
            <TabPanel px={0}>
              {tab.disabled ? (
                <Center pb={4} my={16}>
                  <VStack w={"100%"} gap={4}>
                    <Text color={"gray.300"}>
                      {`Connect ${tab.name} account to start analyzing videos`}
                    </Text>
                    {tab.name === "Twitch" ? (
                      <PlatformButton
                        platform={"twitch"}
                        username={twitchUser}
                        redirectUrl="/search"
                        size={"md"}
                        handleAuth={false}
                      />
                    ) : null}
                  </VStack>
                </Center>
              ) : elements.length > 0 ? (
                <SimpleGrid
                  columns={{ md: 2, lg: 3, xl: 4, "3xl": 5, "4xl": 5 }}
                  spacing={4}
                  my={4}
                >
                  {elements.map((item) => (
                    <ActiveSearchItem
                      key={item.key}
                      search={item}
                      refreshSearches={getActiveSearches}
                      hasSearchAccess={hasSearchAccess}
                      userId={account.kinde_id}
                      onTrialLimitCTAOpen={onTrialLimitCTAOpen}
                      isActiveSearchLimitReached={activeSearchCount >= activeSearchLimit}
                      remainingTrialQuota={remainingTrialQuota}
                      setRemainingTrialQuota={setRemainingTrialQuota}
                      onFinalTrialCTAOpen={onFinalTrialCTAOpen}
                      completedOnboarding={completedOnboarding}
                      setIsOnboardingOpen={setIsOnboardingOpen}
                    />
                  ))}
                </SimpleGrid>
              ) : (
                <Center pb={4}>
                  <VStack w={"100%"} my={16}>
                    <Text color={"gray.300"}>
                      {selectedTabElements.length > 0
                        ? "No videos found for the selected filters"
                        : tab.name === "Rivr"
                        ? "No active or stopped videos"
                        : `No new or available ${tab.name} videos`}
                    </Text>
                    {tab.name === "Twitch" && (
                      <Alert
                        mt={2}
                        px={3}
                        py={2}
                        size={"xs"}
                        fontSize={"xs"}
                        status={"info"}
                        borderRadius={"md"}
                        w={"fit-content"}
                      >
                        <AlertIcon boxSize={4} />
                        <AlertDescription>
                          Rivr only analyzes Twitch past broadcasts. Highlights are not supported.
                          Videos must be published and not sub-only.
                        </AlertDescription>
                      </Alert>
                    )}
                  </VStack>
                </Center>
              )}
            </TabPanel>
          </React.Fragment>
        ))}
      </TabPanels>
    );
  };

  return (
    <>
      <BillingCta
        isOpen={isFinalTrialCTAOpen}
        onClose={onFinalTrialCTAClose}
        optionalIcon={HandMetal}
        optionalHeading={"Ready to rock?"}
        optionalText={
          "You just analyzed your last free video. Unlock unlimited analysis and take your content to the next level."
        }
      />
      <BillingCta
        isOpen={isTrialLimitCTAOpen}
        onClose={onTrialLimitCTAClose}
        optionalIcon={Flame}
        optionalHeading={"Let's keep the momentum going!"}
        optionalText={
          "You've reached your free video analysis limit. Subscribe today to access unlimited analysis and take your content to the next level."
        }
      />
      <Modal
        isOpen={isSearchBarModalOpen}
        onClose={onSearchBarModalClose}
        closeOnOverlayClick={true}
        size={"xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pt={6}>
            <Searchbar
              activeSearchCount={activeSearchCount}
              activeSearchLimit={activeSearchLimit}
              searchCreated={() => {
                getActiveSearches();
                if (!completedOnboarding && (hasSearchAccess || remainingTrialQuota > 0)) {
                  setIsOnboardingOpen(true);
                  handleAmplitudeTrack("Onboarding Initialized");
                }
                if (!hasSearchAccess) {
                  if (remainingTrialQuota === 1) {
                    onFinalTrialCTAOpen();
                    handleAmplitudeTrack("Final Trial Video Billing CTA Opened");
                  }
                  setRemainingTrialQuota(remainingTrialQuota - 1);
                }
              }}
              closeSearchModal={onSearchBarModalClose}
            />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
      <Center px={6} py={12}>
        <Box w={"100%"}>
          <HStack w={"100%"} justifyContent={"space-between"} align={"end"} py={2}>
            <VStack spacing={0} alignItems={"left"}>
              {twitchUser !== null ? (
                <>
                  <Text fontSize={"xl"}>Ready to Analyze</Text>
                  <Text color={"gray.300"} fontSize={"sm"}>
                    Let Rivr get to work analyzing your videos to highlight standout content.
                  </Text>
                </>
              ) : (
                <>
                  <Text fontSize={"xl"}>Get Connected</Text>
                  <Text color={"gray.300"} fontSize={"sm"}>
                    Connect a platform and let Rivr get to work analyzing your videos.
                  </Text>
                </>
              )}
            </VStack>
            <HStack>
              <PlatformButton
                platform={"twitch"}
                username={twitchUser}
                redirectUrl="/search"
                size={"md"}
              />

              {isAdmin && (
                <PlatformButton
                  platform={"youtube"}
                  username={youtubeUser}
                  redirectUrl="/search"
                  size={"md"}
                />
              )}

              {allowURLSearch || hasSearchAccess ? (
                <Button
                  leftIcon={<AddIcon boxSize={3} />}
                  colorScheme={"green"}
                  onClick={() => {
                    onSearchBarModalOpen();
                    handleAmplitudeTrack("Add by URL Opened");
                  }}
                  size={"md"}
                >
                  Add URL
                </Button>
              ) : (
                <Tooltip label={"Subscribe to add videos by URL"}>
                  <Button
                    disabled
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme={"green"}
                    size={"md"}
                  >
                    Add URL
                  </Button>
                </Tooltip>
              )}
            </HStack>
          </HStack>

          <Tabs defaultIndex={0} index={selectedTab} onChange={setSelectedTab} pt={4}>
            <Flex>
              <TabList w={"100%"} whiteSpace={"nowrap"}>
                {tabs.map((tab: any) => (
                  <React.Fragment key={tab.name.toLowerCase()}>
                    <Tab
                      flex={"0 0 auto"}
                      _hover={{ bg: "whiteAlpha.200" }}
                      _selected={{
                        bg: "whiteAlpha.200",
                        color: "blue.300",
                        borderBottomColor: "blue.300",
                      }}
                      borderTopRadius={"md"}
                    >
                      <Image src={tab.image} h={4} w={"100%"} mr={2} />
                      {tab.name}
                    </Tab>
                  </React.Fragment>
                ))}
                <Spacer />
                <HStack mb={2}>
                  {hasSearchAccess ? (
                    <HStack>
                      <Flex display={activeSearchLimit === 10 ? "none" : "flex"}>
                        <Tooltip label={"Videos that can be analyzed at once"}>
                          <Badge
                            fontSize={"md"}
                            borderRadius={"full"}
                            py={0.5}
                            px={3}
                            colorScheme={
                              activeSearchCount >= activeSearchLimit
                                ? "red"
                                : activeSearchCount === activeSearchLimit - 1
                                ? "yellow"
                                : "gray"
                            }
                            onClick={() => {
                              setSortMethod("status");
                              setSortDirection("desc");
                              setPage(1);
                            }}
                            userSelect={"none"}
                          >
                            <Text fontWeight={"semibold"}>
                              {activeSearchCount} / {activeSearchLimit}
                            </Text>
                          </Badge>
                        </Tooltip>
                      </Flex>
                    </HStack>
                  ) : trialQuotaLimit ? (
                    <HStack>
                      <Flex>
                        <Text color={"gray.300"} fontSize={"sm"} alignSelf={"center"} mr={2}>
                          Free videos remaining:{" "}
                        </Text>
                        <Tooltip label={"Free videos remaining"}>
                          <Badge
                            fontSize={"md"}
                            borderRadius={"full"}
                            py={0.5}
                            px={3}
                            colorScheme={
                              remainingTrialQuota === 0
                                ? "red"
                                : remainingTrialQuota === 1
                                ? "yellow"
                                : "gray"
                            }
                            onClick={() => {
                              setSortMethod("status");
                              setSortDirection("desc");
                              setPage(1);
                            }}
                            userSelect={"none"}
                          >
                            <Text fontWeight={"semibold"}>
                              {remainingTrialQuota} / {trialQuotaLimit}
                            </Text>
                          </Badge>
                        </Tooltip>
                      </Flex>
                    </HStack>
                  ) : null}
                  <Box>
                    <Menu closeOnSelect={false} placement={"bottom-end"}>
                      <MenuButton
                        as={IconButton}
                        colorScheme={"gray"}
                        icon={<Filter theme={"filled"} />}
                        size={"sm"}
                      />
                      <MenuList
                        zIndex={2}
                        maxH={"60vh"}
                        overflowY={"auto"}
                        sx={{ scrollbarWidth: "thin" }}
                      >
                        <MenuGroup title={"Date published"}>
                          <HStack px={4} pb={2}>
                            <FormControl>
                              <Input
                                className={"amp-unmask"}
                                type={"date"}
                                size={"sm"}
                                borderRadius={"md"}
                                value={dateFilterStart}
                                onChange={(e) => handleDateFilterStart(e.target.value)}
                              />
                              <FormHelperText>From</FormHelperText>
                            </FormControl>
                            <FormControl>
                              <Input
                                className={"amp-unmask"}
                                type={"date"}
                                size={"sm"}
                                borderRadius={"md"}
                                value={dateFilterEnd}
                                onChange={(e) => handleDateFilterEnd(e.target.value)}
                              />
                              <FormHelperText>To</FormHelperText>
                            </FormControl>
                          </HStack>
                        </MenuGroup>
                        <MenuDivider />
                        <MenuGroup>
                          <Button
                            size={"sm"}
                            colorScheme={"gray"}
                            variant={"ghost"}
                            onClick={resetSearchFilters}
                            mx={3}
                            fontWeight={"normal"}
                          >
                            Reset filters
                          </Button>
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                    <Menu closeOnSelect={false}>
                      <MenuButton as={IconButton} icon={<SortThree />} size={"sm"} />
                      <MenuList minWidth={"260px"} zIndex={2}>
                        <MenuOptionGroup
                          value={sortDirection}
                          title={"Order"}
                          type={"radio"}
                          onChange={handleSortDirection}
                        >
                          <MenuItemOption value={"asc"}>Ascending</MenuItemOption>
                          <MenuItemOption value={"desc"}>Descending</MenuItemOption>
                        </MenuOptionGroup>
                        <MenuDivider />
                        <MenuOptionGroup
                          value={sortMethod}
                          title={"Sort by"}
                          type={"radio"}
                          onChange={handleSortMethod}
                        >
                          <MenuItemOption value={"status"}>Status</MenuItemOption>
                          <MenuItemOption value={"published"}>Date published</MenuItemOption>
                          {isAdmin && (
                            <MenuItemOption value={"processed"}>Date analyzed</MenuItemOption>
                          )}
                          <MenuItemOption value={"title"}>Title</MenuItemOption>
                          <MenuItemOption value={"duration"}>Duration</MenuItemOption>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </Box>
                </HStack>
              </TabList>
            </Flex>
            {getTabPanels()}
          </Tabs>
          {elements.length > 0 && !tabs[selectedTab].disabled && (
            <HStack justifyContent={"space-between"} spacing={2}>
              <Box>
                <Text fontSize={"sm"} color={"gray.400"}>
                  {totalElements > 0
                    ? `Showing ${from} - ${to} of ${totalElements} ${
                        totalElements === 1 ? "video" : "videos"
                      }`
                    : "Showing 0 videos"}
                </Text>
              </Box>

              <HStack>
                <Text
                  fontSize={"sm"}
                  color={"gray.400"}
                  pr={2}
                >{`Page ${page} of ${totalPages}`}</Text>
                <IconButton
                  size={"sm"}
                  variant={"outline"}
                  aria-label={"Previous history page"}
                  icon={<ChevronLeftIcon />}
                  onClick={previousPage}
                  disabled={!hasPreviousPage}
                />
                <IconButton
                  size={"sm"}
                  variant={"outline"}
                  aria-label={"Next history page"}
                  icon={<ChevronRightIcon />}
                  onClick={nextPage}
                  disabled={!hasNextPage}
                />
              </HStack>
            </HStack>
          )}
        </Box>
      </Center>
    </>
  );
};
