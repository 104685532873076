import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";

type TokenInfo = {
  token?: string;
};

export function useAuthToken(): TokenInfo {
  const { getToken } = useKindeAuth();
  const [token, setToken] = useState<string | undefined>();
  const fetchToken = async () => {
    if (getToken) {
      setToken(await getToken());
    } else {
      console.error("getToken is undefined");
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);
  return { token };
}
