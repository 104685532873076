import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { Close } from "@icon-park/react";
import React, { useState, useEffect } from "react";

interface Props {
  value: string;
  onDebouncedChange: (value: string) => void;
  delay?: number; // in milliseconds
  placeholder?: string;
  width?: string;
  borderRadius?: string;
  size?: string;
  className?: string;
  leftIcon?: React.ReactNode;
}

const DebouncedInput = ({
  value,
  onDebouncedChange,
  delay = 300,
  placeholder = "",
  width = "10rem",
  borderRadius = "md",
  size = "md",
  className = "",
  leftIcon,
}: Props) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      onDebouncedChange(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <InputGroup size={size}>
      {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
      <Input
        className={className}
        type={"text"}
        width={width}
        borderRadius={borderRadius}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {inputValue && (
        <InputRightElement>
          <IconButton
            aria-label={"Clear text search"}
            icon={<Close />}
            size={"xs"}
            variant={"ghost"}
            borderRadius={"full"}
            onClick={() => {
              setInputValue("");
              onDebouncedChange("");
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default DebouncedInput;
