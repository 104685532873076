import { ApolloError } from "@apollo/client";

export const logApolloErrorsHandler = ({ graphQLErrors, networkError }: ApolloError, delegate?: () => void) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log("Error:", err.extensions.code);
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (delegate) {
    delegate();
  }
};