import { useState } from "react";
import Onboarding from "./Onboarding";
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OnboardingModal = ({ isOpen, setIsOpen }: Props) => {
  const [step, setStep] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={step === 4 ? "4xl" : "2xl"}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      autoFocus
      blockScrollOnMount
    >
      <ModalOverlay />
      <ModalContent borderRadius={"md"}>
        <ModalHeader bg={"gray.900"} borderTopRadius={"md"} py={6}>
          <HStack gap={4} justify={"center"} wrap={"nowrap"} px={2}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Box
                key={index}
                w={"100%"}
                h={2}
                borderRadius="full"
                bg={step >= index + 1 ? "green.300" : "whiteAlpha.300"}
              />
            ))}
          </HStack>
        </ModalHeader>
        <ModalBody bg={"gray.800"} p={8} borderBottomRadius={"md"}>
          <Onboarding setIsOpen={setIsOpen} step={step} setStep={setStep} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OnboardingModal;
