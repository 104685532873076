import { useState, ChangeEvent, KeyboardEvent, useContext } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Text,
  FormControl,
  HStack,
  useToast,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import { SearchMutation } from "../../api/actions";
import { checkUrl } from "../../models/urls";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import { demoUserIds } from "../../utils/demoUserIds";
import { AccountContext } from "../../context/AccountContext";
import BillingCta from "../BillingCta/BillingCta";

type SearchbarProps = {
  activeSearchCount: number;
  activeSearchLimit: number;
  searchCreated: () => void;
  closeSearchModal: () => void;
};

export const Searchbar = ({
  activeSearchCount,
  activeSearchLimit,
  searchCreated,
  closeSearchModal,
}: SearchbarProps) => {
  const { account } = useContext(AccountContext);
  const toast = useToast();
  const { isOpen: isBillingOpen, onClose: onBillingClose, onOpen: onBillingOpen } = useDisclosure();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isSearchDisabled = false;
  const [inputInvalid, setInputInvalid] = useState(false);
  const [inputURL, setInputURL] = useState("");
  const { user, getBooleanFlag } = useKindeAuth();
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const liveTwitchUrlFlag = isAdmin || (getBooleanFlag("live-twitch-url", false) as boolean);
  const youtubeUrlFlag = isAdmin || (getBooleanFlag("youtube_url", false) as boolean);
  const isBetaUser = account.beta_access as boolean;

  const hasSubscription = account.subscription_status === "active";

  const flags = new Map([
    ["live-twitch-url", liveTwitchUrlFlag],
    ["youtube-url", youtubeUrlFlag],
  ]);
  const [searchInsertAPI] = useMutation(SearchMutation, {
    onCompleted() {
      closeSearchModal();
      setInputURL("");
      setIsButtonLoading(false);
      searchCreated();
    },
    onError({ graphQLErrors, networkError }) {
      insertErrorCallback(graphQLErrors, networkError);
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputURL(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmitLocal();
    }
  };

  const onSubmitLocal = () => {
    if (!isAdmin && !hasSubscription && !isBetaUser) {
      onBillingOpen();
      handleAmplitudeTrack("Billing CTA Opened");
      return;
    }

    if (!isAdmin && activeSearchCount >= activeSearchLimit) {
      toast({
        title: "Limit reached",
        description: "You have reached your active analyzing limit. Wait for analysis to complete.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      handleAmplitudeTrack("Analyze Limit Reached");
      return;
    }

    setIsButtonLoading(true);
    const parsed = checkUrl(inputURL, flags);
    if (typeof parsed === "string") {
      toast({
        title: "Error.",
        description: parsed,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsButtonLoading(false);
      setInputInvalid(true);
      handleAmplitudeTrack("Analyze Start Error", {
        URL: inputURL,
      });
      return;
    }

    setInputInvalid(false);
    searchInsertAPI({ variables: { searchRequest: { url: parsed.cleanUrl } } });
    handleAmplitudeTrack("Analyze Start Successful", {
      URL: parsed.cleanUrl,
    });
  };

  const insertErrorCallback = (graphQLErrors: any, networkError: any) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        console.log("Error:", err.extensions.code);
        const message =
          err.extensions.internal.response.status === 500
            ? "Something went wrong. Please try again later."
            : `${err.extensions.internal.response.body.detail}.`;
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    setIsButtonLoading(false);
  };

  if (!user || !user.id) return null;

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <BillingCta isOpen={isBillingOpen} onClose={onBillingClose} />
      <VStack w={"100%"} align={"center"} spacing={4}>
        <Box w="100%" opacity={!isAdmin && isSearchDisabled ? 0.66 : 1}>
          <HStack w={"100%"}>
            <FormControl isInvalid={inputInvalid}>
              <Input
                className={"inp-search-url amp-unmask"}
                variant={"filled"}
                placeholder={"Paste a URL"}
                value={inputURL}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                isDisabled={!isAdmin && isSearchDisabled}
              />
            </FormControl>
            <Button
              className={"btn-start-search"}
              onClick={onSubmitLocal}
              isLoading={isButtonLoading}
              isDisabled={(!isAdmin && isSearchDisabled) || inputURL.trim() === ""}
              colorScheme={"green"}
              variant={"solid"}
              size={"md"}
            >
              Go
            </Button>
          </HStack>
        </Box>
        <Box width="100%" display={demoUserIds.includes(user.id) ? "none" : "block"}>
          <HStack alignItems={"center"} justify={"center"} w={"100%"}>
            <Text color="gray.300" fontSize="sm">
              Supported platforms:
            </Text>
            <HStack backgroundColor={"whiteAlpha.200"} py={2} px={3} borderRadius={"full"}>
              <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="16px" />
              {liveTwitchUrlFlag ? (
                <Text fontSize={"xs"}>LIVE & VOD</Text>
              ) : (
                <Text fontSize={"xs"}>VOD</Text>
              )}
            </HStack>
            {youtubeUrlFlag && (
              <HStack backgroundColor={"whiteAlpha.200"} py={2} px={3} borderRadius={"full"}>
                <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="16px" />
                <Text fontSize={"xs"}>LIVE & VOD</Text>
              </HStack>
            )}
          </HStack>
        </Box>
      </VStack>
    </>
  );
};
