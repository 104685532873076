import { useEffect, useRef } from "react";
import {
  Box,
  Image,
  Button,
  Link,
  Text,
  VStack,
  HStack,
  Flex,
  Heading,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import RivrLogo from "../assets/rivr-logo.svg";
import { RivrLocations } from "../models/navigation";
import creatorImages from "../assets/creators/creators";
import TileBg from "../assets/sign-in-tile.png";
import RivrInterface from "../assets/sign-in-img.png";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import TwitchLogo from "../assets/twitch-logo.svg";

const Login = () => {
  const { login, register, isAuthenticated } = useKindeAuth();

  const amplitudeInitialized = useRef(false);

  useEffect(() => {
    if (!amplitudeInitialized.current) {
      const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_ID || "";
      const userId = undefined; // No user ID on Login page

      amplitude.init(amplitudeApiKey, userId, {
        defaultTracking: true,
      });

      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1,
      });
      amplitude.add(sessionReplayTracking);

      amplitudeInitialized.current = true;
    }
  }, []);

  if (isAuthenticated) {
    let redirect: string | RivrLocations = RivrLocations.Search;
    const loginRedirect = localStorage.getItem("loginRedirect");
    if (loginRedirect) redirect = loginRedirect;
    return <Navigate replace to={redirect} />;
  }

  const handleAmplitudeTrack = (eventName: string, properties?: Record<string, any>) => {
    amplitude.track(eventName, properties);
  };

  return (
    <Flex
      minH={"100vh"}
      w={"100vw"}
      direction={{ base: "column", md: "row" }}
      position={"relative"}
    >
      <Flex
        flex={1}
        w={{ base: "100%", md: "50%" }}
        bg={"blackAlpha.200"}
        color={"whiteAlpha.900"}
        px={8}
        gap={6}
        py={8}
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        display={{ base: "none", md: "flex" }}
      >
        <Box>
          <Link
            href={"https://rivr.stream"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Homepage", {
                page: "Sign Up Lander",
              })
            }
          >
            <Image src={RivrLogo} maxW={24} alt={"Rivr Logo"} />
          </Link>
        </Box>

        <VStack w={"100%"} align={"center"}>
          <VStack align={"start"} spacing={6} maxW={"40rem"}>
            <VStack align={"start"} gap={4}>
              <Heading fontSize={"3xl"} fontWeight={"bold"} sx={{ textWrap: "balance" }}>
                Rivrlutionizing content creation for creators and brands
              </Heading>
              <Text color={"gray.300"} fontSize={"md"} sx={{ textWrap: "pretty" }}>
                Experience Rivr for free and start uncovering your best Moments.
              </Text>
            </VStack>
            <Box>
              <Image
                src={RivrInterface}
                backgroundSize={"contain"}
                alt={"Example gifts"}
                borderRadius={"md"}
                w={"100%"}
              />
              <Link
                fontSize={"2xs"}
                color={"gray.300"}
                href={"https://twitch.tv/lobosjr"}
                target={"_blank"}
              >
                <HStack gap={0} pt={1}>
                  <Image src={TwitchLogo} boxSize={3} />
                  <Text ml={1.5}>LobosJr</Text>
                </HStack>
              </Link>
            </Box>
            <VStack align={"start"} gap={1.5}>
              <Text color={"gray.300"} fontSize={"md"} sx={{ textWrap: "pretty" }}>
                Join 100s of creators, just like you.
              </Text>
              <HStack justifyContent={"start"} w={"100%"}>
                <AvatarGroup spacing={-2.5} borderColor={"whiteAlpha.200"}>
                  {creatorImages.map((image, index) => (
                    <Avatar boxSize={9} key={index} src={image} />
                  ))}
                </AvatarGroup>
              </HStack>
            </VStack>
          </VStack>
        </VStack>

        <HStack
          fontSize={"xs"}
          color={"gray.500"}
          spacing={2}
          display={{ base: "none", md: "flex" }}
        >
          <Link
            href={"https://rivr.stream/privacy-terms-of-service"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Terms of Use", {
                page: "Sign Up Lander",
              })
            }
            _hover={{ color: "whiteAlpha.900" }}
          >
            Terms of Use
          </Link>
          <Text>●</Text>
          <Link
            href={"https://rivr.stream/privacy-terms-of-service"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Privacy Policy", {
                page: "Sign Up Lander",
              })
            }
            _hover={{ color: "whiteAlpha.900" }}
          >
            Privacy Policy
          </Link>
        </HStack>
      </Flex>

      <Flex
        flex={1}
        w={{ base: "100%", md: "50%" }}
        bgImage={TileBg}
        bgRepeat={"repeat"}
        px={8}
        py={16}
        justifyContent={"center"}
        align={"center"}
        direction={"column"}
        position={"relative"}
        bgColor={"whiteAlpha.50"}
      >
        <Box
          display={{ base: "block", md: "none" }}
          position={"absolute"}
          top={8}
          left={"50%"}
          transform={"translateX(-50%)"}
        >
          <Link
            href={"https://rivr.stream"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Homepage", {
                page: "Sign Up Lander",
              })
            }
          >
            <Image src={RivrLogo} maxW={24} alt={"Rivr Logo"} />
          </Link>
        </Box>

        <VStack gap={6} w={"100%"} textAlign={"center"} maxW={"28rem"}>
          <VStack gap={1}>
            <Text
              fontSize={"2xl"}
              fontWeight={"bold"}
              color={"gray.50"}
              sx={{ textWrap: "balance" }}
            >
              Sign up to Rivr
            </Text>
            <Text fontSize={"lg"} color={"gray.300"} sx={{ textWrap: "balance" }}>
              Analyze your 3 free Twitch VODs today!
            </Text>
          </VStack>
          <VStack w={"100%"} gap={2}>
            <Button
              colorScheme={"red"}
              backgroundColor={"rivr.red"}
              color={"whiteAlpha.900"}
              variant={"solid"}
              size={"lg"}
              w={"full"}
              h={16}
              fontSize={"xl"}
              onClick={() => {
                handleAmplitudeTrack("Sign Up", {
                  page: "Sign Up Lander",
                });
                register();
              }}
              minW={"fit-content"}
            >
              Sign Up
            </Button>

            <Text fontSize={"xs"} color={"gray.400"} sx={{ textWrap: "balance" }}>
              No credit card required
            </Text>
          </VStack>
          <Text color={"gray.300"} sx={{ textWrap: "balance" }}>
            Already have an account?{" "}
            <Link
              color={"green.300"}
              onClick={() => {
                handleAmplitudeTrack("Sign In", {
                  page: "Sign Up Lander",
                });
                login();
              }}
            >
              Sign in
            </Link>
          </Text>
        </VStack>

        <HStack
          fontSize={"xs"}
          color={"gray.500"}
          spacing={2}
          position={"absolute"}
          bottom={4}
          w={"full"}
          justifyContent={"center"}
          display={{ base: "flex", md: "none" }}
        >
          <Link
            href={"https://rivr.stream/privacy-terms-of-service"}
            target={"_blank"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Terms of Use", {
                page: "Sign Up Lander",
              })
            }
            _hover={{ color: "whiteAlpha.900" }}
          >
            Terms of Use
          </Link>
          <Text>●</Text>
          <Link
            href={"https://rivr.stream/privacy-terms-of-service"}
            target={"_blank"}
            onClick={() =>
              handleAmplitudeTrack("Navigate to Privacy Policy", {
                page: "Sign Up Lander",
              })
            }
            _hover={{ color: "whiteAlpha.900" }}
          >
            Privacy Policy
          </Link>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Login;
